/**
 * This function will return the proper formatting for a number of days
 * ie '5 days' or '1 day', if days is null or undefined it will return '- days'
 *
 * @param days - number
 * @returns - { days: string, unit: string }
 */
type FormatDays = (days: number) => {
  days: string
  unit: string
}

export const formatDays: FormatDays = (days) => {
  // if days is null or undefined use a dash
  const daysLeft = days ?? '-'

  return {
    days: daysLeft.toString(),
    unit: (daysLeft === 1) ? 'day' : 'days',
  }
}

export const getIsActivityUpdatesEnabled = () => (
  process.env.NEXT_PUBLIC_ACTIVITY_UPDATES_ENABLED === 'true'
)
