import React, { FC } from 'react'
import { MockPuffsData } from './data'
import { useMockPuffs } from './hooks/use-mock-puffs'
import { MockPuffsCard } from './components/MockPuffsCard'
import { MockPuffsFormValues } from './types'

export const MockPuffs: FC = () => {
  const handleFormSubmit = useMockPuffs()
  const { hardwareId, mockPods, ...rest } = MockPuffsData

  const onSubmit = (values: MockPuffsFormValues) => {
    handleFormSubmit({ hardwareId, mockPods, ...values })
  }

  return (
    <MockPuffsCard
      onSubmit={onSubmit}
      {...rest}
    />
  )
}
