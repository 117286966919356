import React, {
  FC,
  useEffect,
  PropsWithChildren,
  ComponentPropsWithoutRef,
} from 'react'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'
import { classNames } from 'utils/css'

export interface PageProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'div'>> {
    className?: string
    screenEvent?: Event
  }

// todo incorporate frequently used Page styles and remove grid
export const Page: FC<PageProps> = ({
  children,
  className,
  screenEvent,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!screenEvent) return

    reportEvent(screenEvent)
  }, [screenEvent])

  return (
    <div className={classNames('Page', className)} {...rest}>
      {children}
    </div>
  )
}
