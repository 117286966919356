import React, { FC, ComponentPropsWithoutRef } from 'react'
import { destructureLocale } from 'utils/locales'
import { AcceptedCountryCodes } from 'types/regions'
import {
  NicotineWarningCA,
  NicotineWarningGB,
  NicotineWarningKW,
  NicotineWarningUS,
} from 'components/NicotineWarnings'

export interface NicotineBannerProps extends ComponentPropsWithoutRef<'div'> {
  isHidden?: boolean
  locale: string
  region: string
}

export const NicotineBanner: FC<NicotineBannerProps> = ({
  isHidden = false,
  locale,
  region,
}) => {
  const { languageCode } = destructureLocale(locale)

  if (isHidden) {
    return null
  } else if (region === AcceptedCountryCodes.CA) {
    return <NicotineWarningCA language={languageCode} />
  } else if (region === AcceptedCountryCodes.US) {
    return <NicotineWarningUS />
  } else if (region === AcceptedCountryCodes.KW) {
    return <NicotineWarningKW />
  } else {
    return <NicotineWarningGB />
  }
}
