export enum LogLevel {
  Assert = 'assert',
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Verbose = 'verbose',
  Warn = 'warn',
}

export enum LogLevelKey {
  Assert = 'A',
  Debug = 'D',
  Error = 'E',
  Info = 'I',
  Verbose = 'V',
  Warn = 'W',
}

export type StoredSdkLogs = {
  levelIndex: Partial<Record<LogLevelKey, number[]>>
  logs: { log: string, timestamp: string }[]
}

export type SdkLogsContextType = {
  logCount: number | null
  logListenerLevel: LogLevel | null
  openSdkLogsModal: () => void | null
  setLogListenerLevel: (logListenerLevel: LogLevel) => void | null
}
