import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
  useRef,
} from 'react'
import { saveAs } from 'file-saver'
import { Reoverlay } from 'reoverlay'
import {
  useDebounce,
  useSessionStorage,
  useSetSessionStorage,
  useSessionStorageValue,
} from 'hooks'
import { useConxSdk } from 'modules/ConxSdkProvider'
import Chai from 'types/chai'
import { SdkLogsDisplay, SdkLogsDisplayRef } from './components/SdkLogsDisplay'
import { getIsPreProdEnvironment, getIsEtherEnvironment } from 'utils/app'
import { StoredSdkLogs, SdkLogsContextType, LogLevel } from './types'
import { generateFileName, updateSdkLogs } from './utils'
import {
  LOG_COUNT_TIMEOUT_MS,
  SDK_LOGS_KEY,
  SDK_LOGS_LISTENER_LEVEL_KEY,
  initialLogCount,
  initialSdkLogs,
  initialSdkLogsListenerLevel,
} from './config'

export const SdkLogsContext = createContext<SdkLogsContextType | null>({
  logCount: null,
  logListenerLevel: null,
  openSdkLogsModal: null,
  setLogListenerLevel: null,
})

export const SdkLogsProvider: FC<PropsWithChildren> = ({ children }) => {
  // return children if not isPreProdEnvironment
  if (!getIsPreProdEnvironment()) return <>{children}</>

  // hooks
  const { userClientId, userAgent } = useConxSdk()

  // refs
  const sdkLogsDisplayRef = useRef<SdkLogsDisplayRef>(null)

  // log count state, acts as a trigger
  const [logCount, setLogCount] = useState<number>(initialLogCount)
  const debouncedLogCount = useDebounce(logCount, LOG_COUNT_TIMEOUT_MS)

  // log level state
  const [logListenerLevel, setLogListenerLevel] = useSessionStorage<LogLevel>(
    SDK_LOGS_LISTENER_LEVEL_KEY,
    initialSdkLogsListenerLevel,
  )

  // setter for sdk logs
  const setSdkLogs = useSetSessionStorage(SDK_LOGS_KEY)

  // function to export logs
  const handleExport = () => {
    const { logs } = useSessionStorageValue<StoredSdkLogs>(
      SDK_LOGS_KEY,
      initialSdkLogs,
    )
    // eslint-disable-next-line sort-keys
    const fileContent = JSON.stringify({ userClientId, logs })
    const blob = new Blob([fileContent], { type: 'application/json' })
    const fileName = generateFileName()

    if (!userAgent.isDesktop && Boolean(navigator.share)) {
      navigator.share({
        files: [new File([blob], fileName)],
        text: 'Courtesy of conx-web!',
        title: fileName,
      }).then(() => {
        window.alert('Logs shared successfully')
      }).catch((error) => {
        window.alert(`Error sharing logs: ${error}`)
      })
    } else {
      saveAs(blob, fileName, { autoBom: true })
    }
  }

  // function to make logs findable by Appium as base64 code on the DOM
  const handleEtherExport = () => {
    const { logs } = useSessionStorageValue<StoredSdkLogs>(
      SDK_LOGS_KEY,
      initialSdkLogs,
    )

    // eslint-disable-next-line sort-keys
    const fullLogs = JSON.stringify({ userClientId, logs })
    const base64Content = btoa(encodeURIComponent(fullLogs))
    sdkLogsDisplayRef.current.setLogsForEther(`Base64-${base64Content}`)
  }

  // function to open the SdkLogsModal
  const openSdkLogsModal = () => Reoverlay.showModal('Modal', {
    children: <SdkLogsDisplay
      userClientId={userClientId}
      ref={sdkLogsDisplayRef}
    />,
    contentFlexDirection: 'row',
    primaryButton: getIsEtherEnvironment() ? {
      content: 'Export Ether Logs',
      onClick: handleEtherExport,
    } : {
      content: 'Export Logs & UserId',
      onClick: handleExport,
    },
    width: 'large',
  })

  /**
   * add sdk logger using session storage
   */
  useEffect(() => {
    // reset on first run and logListenerLevel change
    setSdkLogs(initialSdkLogs)
    setLogCount(initialLogCount)

    // attach log viewer function with callback
    Chai.attachLogViewer(logListenerLevel, (log: string) => {
      const sdkLogs = useSessionStorageValue<StoredSdkLogs>(
        SDK_LOGS_KEY,
        initialSdkLogs,
      )

      setSdkLogs(updateSdkLogs(log, sdkLogs))
      setLogCount(logCount => logCount + 1)
    })

    return () => {
      Chai.detachLogViewer()
    }
  }, [logListenerLevel])

  const values: SdkLogsContextType = {
    logCount: debouncedLogCount,
    logListenerLevel,
    openSdkLogsModal,
    setLogListenerLevel,
  }

  return (
    <SdkLogsContext.Provider value={values}>
      {children}
    </SdkLogsContext.Provider>
  )
}
