import React, { FC, useState, useMemo } from 'react'
import { body, formatBody, title } from './localization'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import { DailyPuffTreemap } from '../../charts/DailyPuffTreemap'
import { WebAppInsights, Event } from 'analytics-events'

// config
const MAX_TREEMAP_HEIGHT = 360

// types
export type AveragePuffDayProps = Omit<InsightsCardProps, 'title'>

export const AveragePuffDay: FC<AveragePuffDayProps> = ({
  loading,
  ...rest
}) => {
  // state
  const [isValid, setIsValid] = useState<boolean>(null)
  const [minPuffDay, setMinPuffDay] = useState<string>(null)
  const [maxPuffDay, setMaxPuffDay] = useState<string>(null)

  // variables
  const isConstant = useMemo<boolean>(() => {
    if (minPuffDay === null || maxPuffDay === null) return null
    return minPuffDay === maxPuffDay
  }, [minPuffDay, maxPuffDay])

  // events
  const averagePuffDayEvent = useMemo<Event>(() => {
    if (isValid === null || isConstant === null) return null
    return WebAppInsights.AveragePuffDaysEvent({
      chartState: isConstant
        ? WebAppInsights.AveragePuffDaysTreeMapState.SameHighestLowest
        : WebAppInsights.AveragePuffDaysTreeMapState.Valid,
      isValid: isValid,
    })
  }, [isValid, isConstant])

  return (
    <InsightsCard
      cardEvent={averagePuffDayEvent}
      isValid={isValid && !isConstant}
      title={title}
      loading={loading || isValid === null || isConstant === null}
      {...rest}
    >
      {minPuffDay && maxPuffDay && (
        <Text
          message={body}
          tagName='p'
          typography='bodyBig'
          values={formatBody(minPuffDay, maxPuffDay)}
        />
      )}
      <DailyPuffTreemap
        margin={{ top: 'baseLoose' }}
        maxHeight={MAX_TREEMAP_HEIGHT}
        setIsValid={setIsValid}
        setMaxPuffDay={setMaxPuffDay}
        setMinPuffDay={setMinPuffDay}
      />
    </InsightsCard>
  )
}
