import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'

export interface HourlyPuffBarchartProps extends BoxProps {
  height: number
  setIsValid(isValid: boolean): void
  setMaxPuffHour(min: number): void
  setMinPuffHour(max: number): void
}

export const HourlyPuffBarchart: FC<HourlyPuffBarchartProps> = ({
  height,
  setIsValid,
  setMaxPuffHour,
  setMinPuffHour,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachPuffAverageByHourAdapter(
      currentCanvas,
      (min: number, max: number, isValid: boolean) => {
        setMinPuffHour(min)
        setMaxPuffHour(max)
        setIsValid(isValid)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
