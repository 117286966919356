import React, { FC, HTMLAttributes, PropsWithChildren } from 'react'
import { classNames } from 'utils/css'
import styles from './Grid.module.scss'

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  fullWidth?: boolean
}

export const Grid: FC<PropsWithChildren<GridProps>> = ({
  children,
  fullWidth = false,
  ...rest
}) => (
  <div className={styles.gridWrap} {...rest}>
    <div className={classNames(styles.Grid, fullWidth && styles.fullWidth)}>
      {children}
    </div>
  </div>
)
