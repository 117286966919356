import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './ButtonStack.module.scss'
import { classNames } from 'utils/css'
import {
  UnstyledLink,
  UnstyledButton,
  UnstyledLinkProps,
  UnstyledButtonProps,
} from 'components/UnstyledPrimitives'

export interface ButtonStackOption {
  href?: string
  label: string
  onClick?(): void
}

export type LinkOption = UnstyledLinkProps & ButtonStackOption
export type ButtonOption = UnstyledButtonProps & ButtonStackOption

export interface ButtonStackProps extends ComponentPropsWithoutRef<'ul'> {
  onClickOption?(): void
  options: (ButtonOption | LinkOption)[]
}

export const ButtonStack: FC<ButtonStackProps> = ({
  className,
  options,
  onClickOption,
  ...rest
}) => {
  const getActionHandler = (action: () => void) => (
    () => {
      onClickOption()
      action && action()
    }
  )

  return (
    <ul className={classNames(styles.ButtonStack, className)} {...rest}>
      {options.map(({ href = null, label, onClick, ...rest }, i) => (
        <li key={'stack-option-' + i}>
          {href ? (
            <UnstyledLink
              aria-label={label}
              className={styles.stackOption}
              href={href}
              onClick={getActionHandler(onClick)}
              {...rest as LinkOption}
            >
              <div className={styles.optionLabel}>
                {label}
              </div>
            </UnstyledLink>
          ) : (
            <UnstyledButton
              aria-label={label}
              className={styles.stackOption}
              onClick={getActionHandler(onClick)}
              {...rest as ButtonOption}
            >
              <div className={styles.optionLabel}>
                {label}
              </div>
            </UnstyledButton>
          )}
        </li>
      ))}
    </ul>
  )
}
