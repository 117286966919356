/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** WebApp Navigation Events */
export namespace WebAppNavigation {

  /** WebApp navigation options */
  export enum PageName {
    Home = "Home",
    Settings = "Settings",
    UsageInsights = "UsageInsights",
  }

  /** User navigates to selected page */
  type NavigationEventProps = {
    linkClicked: PageName
  }

  export const NavigationEvent = ({
    linkClicked,
  }: NavigationEventProps): Event => {
    const properties: Record<string, any> = {
      link_clicked: linkClicked,
    }

    return new Event({
      properties: properties,
      eventName: 'navigation',
      type: EventType.Stat,
    })
  }
}
