import React, { FC, useEffect } from 'react'
import styles from './ActivityUpdatePage.module.scss'
import { GridItem, Page, PageProps } from 'modules/Layout/components'
import { LinkButton, LinkButtonProps } from 'components/Button'
import Routes from 'types/routes'
import { Spinner } from 'components/Spinner'
import { InvalidInsight } from '../InvalidInsight'
import { classNames } from 'utils/css'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'

export interface ActivityUpdatePageProps extends PageProps {
  backButton?: LinkButtonProps
  isValid?: boolean
  loading?: boolean
  pageStateEvent?: Event
}

export const ActivityUpdatePage: FC<ActivityUpdatePageProps> = ({
  backButton,
  children,
  isValid = true,
  loading,
  pageStateEvent,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!pageStateEvent) return

    reportEvent(pageStateEvent)
  }, [pageStateEvent])

  return (
    <GridItem verticalAlign='top'>
      <Page className={styles.ActivityUpdatePage} {...rest}>
        <LinkButton
          alignIcon='left'
          content='back'
          href={Routes.UsageInsights}
          icon='icon/16x16/arrow_left'
          local
          outline
          size='small'
          {...backButton}
        />
        <article className={styles.content}>
          <div
            aria-hidden={!isValid || loading}
            className={classNames(
              loading && styles.contentLoading,
              !loading && isValid && styles.fadeIn,
            )}>
            {children}
          </div>
          {!isValid && !loading && (
            <InvalidInsight className={styles.invalid} />
          )}
          {loading && (
            <Spinner className={styles.spinner} color='dark' />
          )}
        </article>
      </Page>
    </GridItem>
  )
}
