import React from 'react'
import Script from 'next/script'

export const AppScripts: React.FC = () => (
  <>
    {/* AWS WAF ACL Integration */}
    {process.env.NEXT_PUBLIC_WAF_INTEGRATION_URL && (
      <Script
        data-testid='waf-integration'
        src={process.env.NEXT_PUBLIC_WAF_INTEGRATION_URL}
        type='text/javascript'
        strategy='afterInteractive'
      />
    )}
    {/* End AWS WAF ACL Integration */}
  </>
)
