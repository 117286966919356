import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import { classNames } from 'utils/css'
import styles from './UnstyledButton.module.scss'
import { useTrackClick } from 'modules/AnalyticsProvider/hooks'
import { Event } from 'analytics-events'

export interface UnstyledButtonProps extends ComponentPropsWithoutRef<'button'> {
  clickEvent?: Event
}

export const UnstyledButton = forwardRef<
  HTMLButtonElement,
  UnstyledButtonProps
>(({ className, children, clickEvent, onClick, ...rest }, ref?) => {
  const { onClickWithTracking }
    = useTrackClick<HTMLButtonElement>(onClick, clickEvent)

  return (
    <button
      {...rest}
      className={classNames(styles.UnstyledButton, className)}
      ref={ref}
      onClick={onClickWithTracking}
    >
      {children}
    </button>
  )
})

UnstyledButton.displayName = 'UnstyledButton'
