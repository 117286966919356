import React, { FC, PropsWithChildren } from 'react'
import styles from './Box.module.scss'
import { classNames, variationName } from 'utils/css'
import { BoxProps, SpacingOptions, Direction } from './types'

export const Box: FC<PropsWithChildren<BoxProps>> = ({
  align,
  children,
  className,
  margin,
  padding,
  tagName: TagName = 'div',
  type = 'block',
  ...rest
}) => {
  const parseClasses = (style: SpacingOptions, key: string) => {
    const classes = []
    if (!style) return classes

    if (typeof(style) === 'string') {
      classes.push(styles[variationName(key, style)])
    } else {
      let direction: keyof Direction
      for (direction in style) {
        classes.push(
          styles[variationName(variationName(key, direction), style[direction])],
        )
      }
    }

    return classes
  }

  return (
    <TagName className={classNames(
      styles.Box,
      type && styles[variationName('type', type)],
      align && styles[variationName('align', align)],
      ...parseClasses(margin, 'margin'),
      ...parseClasses(padding, 'padding'),
      className,
    )} {...rest}>
      {children}
    </TagName>
  )
}
