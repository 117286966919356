import { Profile } from 'next-auth'

export type UserKeychainPayload = {
  private_key: UserPrivacyKeyPayload
  /** The cleartext public key of the encrypted privacy keypair */
  public_key: string
}

/**
 * AES-GCM encrypted privacy key payload. The SDK will know what
 * to do with this.
 */
export type UserPrivacyKeyPayload = {
  /** Juulio's public key, for verifying the payload. */
  server_ec_bn_s: string
  /** Verification tag */
  tag: string
  /** Ciphertext payload */
  value: string
}

/**
 * The user info returned by Mobilecloud.
 */
export type UserInfo = Profile & {
  age_verified: boolean
  av_status: 'verified' | 'unverified'
  of_global_purchase_age: boolean
  settings: {
    juul_2_cloud_sync_enabled?: boolean
    juul_2_complex_flavors_early_access?: boolean
    juul_2_early_access?: boolean
  }
  user_id: string
}

/**
 * Ecommerce Order Data
 */

export enum OrderStatus {
  AwaitingPayment = 'awaiting_payment',
  Canceled = 'canceled',
  Delivered = 'delivered',
  DeliveryException = 'delivery_exception',
  LabelCreated = 'label_created',
  NoShipment = 'no_shipment',
  Processed = 'processed',
  Processing = 'processing',
  Shipped = 'shipped'
}

export type Order = {
  completedAt: string
  lineItems: Array<{
    display_text: string
    image: string
    product_name: string
    quantity: number
  }>
  number: string
  state: OrderStatus
}

export type OrdersResponse = {
  orders: Record<string, any>[]
}

/**
 * Ecommerce Subscription Data
 */
export type Subscription = {
  errors?: string[]
  nextOrderDate: string
  quote: {
    total: number
    total_formatted: string
  }
  status: 'active' | 'canceled'
  uuid: string
}

export type SubscriptionResponse = {
  subscription: Record<string, any>
}
