import { StoredConsoleLogs, ConsoleAction } from './types'

// CONFIG

// custom console logs session storage key
export const CONSOLE_LOGS_KEY = 'console-logs'

// initial value for stored custom console logs
export const initialConsoleLogs = { levelIndex: {}, logs: [] } as StoredConsoleLogs

// custom console logs debug state session storage key
export const CONSOLE_LOGS_DEBUG = 'custom-console-debug'

// initial boolean state for custom console debug mode
export const initialDebugState = false

// log levels in order from narrow focused to most inclusive
export const orderedLogLevels = [
  ConsoleAction.Error,
  ConsoleAction.Warn,
  ConsoleAction.Log,
  ConsoleAction.Info,
  ConsoleAction.Debug,
]
