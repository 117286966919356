import React, { useCallback } from 'react'
import { Reoverlay } from 'reoverlay'
import { useConxSdk } from 'modules/ConxSdkProvider'
import Chai from 'types/chai'
import { RemoteConfigEditor } from '../components'

interface OpenRemoteConfigManagerModal {
  openRemoteConfigManagerModal: () => void
}

export const useRemoteConfigManager = (): OpenRemoteConfigManagerModal => {
  const { sdkContext } = useConxSdk()

  const openRemoteConfigManagerModal = useCallback(() => {
    const manager = new Chai.remoteconfig.RemoteConfigManager(sdkContext)

    Reoverlay.showModal('Modal', {
      children: <RemoteConfigEditor manager={manager} />,
      primaryButton: {
        content: 'Force Firebase Refresh',
        onClick: async() => { await manager.refresh() },
      },
      width: 'large',
    })
  }, [sdkContext])

  return { openRemoteConfigManagerModal }
}
