import React, { FC, Fragment, ReactNode } from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { TypographyProps, Entries } from './types'

/**
 * %{variable} reg exp will find what is inside %{}, equivalent to /%{([^}]+)}/g
 * */
export const TEXT_VARIABLE_REG_EXP = new RegExp('%{([^}]+)}', 'g')

/**
 * text: 'this is a %{animal}'
 * values: { animal: dog }
 *
 * returns ['this is a', 'dog']
 *
 * @param text string
 * @param values object of values to replace variable keys
 * @returns - text parsed with variables
 */
export const parseText = (text: string, values: Entries): ReactNode[] => (
  text?.split(TEXT_VARIABLE_REG_EXP).filter(Boolean).map(item => (
    values[item] !== undefined ? values[item] : item
  ))
)

export const Typography: FC<TypographyProps> = ({
  className,
  content,
  tagName: TagName = Fragment,
  values = {},
  ...rest
}) => {
  const props = TagName !== Fragment ? { ...rest, className } : null

  return (
    <TagName {...props}>
      {isEmpty(values) ? content : parseText(content, values)}
    </TagName>
  )
}
