import React, { FC, useEffect, useState } from 'react'
import { Button, ButtonProps } from 'components/Button'
import { useToggle } from 'hooks/use-toggle'

export interface CopyButtonProps extends ButtonProps {
  copyLabel?: string
  textToCopy: string
}

export const CopyButton: FC<CopyButtonProps> = ({
  copyLabel = null,
  textToCopy,
  ...rest
}) => {
  const [ready, setReady] = useState(false)
  const [hasCopied, toggleCopied] = useToggle(false)
  const buttonText = hasCopied
    ? (copyLabel ? `${copyLabel} Copied!` : 'Copied!')
    : (copyLabel ? `Copy ${copyLabel}` : 'Copy')

  useEffect(() => {
    if (!ready &&
      window?.isSecureContext &&
      navigator?.clipboard?.writeText
    ) {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return null
  }

  return (
    <Button
      accessibilityLabel={buttonText}
      disabled={hasCopied}
      outline
      content={buttonText}
      size='small'
      onClick={() => {
        navigator.clipboard.writeText(textToCopy)
        toggleCopied()
      }}
      {...rest}
    />
  )
}
