import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useFormik } from 'formik'
import { date, object, string, number, ref } from 'yup'
import { format } from 'date-fns'
import isEmpty from 'lodash/fp/isEmpty'
import styles from './MockPuffsForm.module.scss'
import { Button } from 'components/Button'
import { InputField } from 'components/InputField'
import { CSVModeFieldset, PodFieldset } from '../Fieldset'
import { MockPuffsFormProps, MockPuffsFormValues, TabValues } from '../../types'

const Step: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.step}>{children}</div>
)

const Instructions: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.instructions}>{children}</div>
)

export const MockPuffsForm: FC<MockPuffsFormProps> = ({
  csvModes,
  endTimestamp,
  instructions,
  puffCount,
  onSubmit,
  pattern,
  pods,
  startTimestamp,
  submit,
  tab,
}) => {
  const dateToday = format(new Date(), 'yyyy-MM-dd\'T\'HH:mm')
  const TAB_VALUE = 'tab'
  const formId = 'mockPuffsForm'

  const formik = useFormik({
    initialValues: {
      csvMode: csvModes.options[0],
      endTimestamp: dateToday,
      pattern: '',
      pod: pods.options[0],
      puffCount: 1,
      startTimestamp: dateToday,
      tab: tab,
    },
    onSubmit: async(values: MockPuffsFormValues, { setSubmitting }) => {
      await onSubmit(values)
      setSubmitting(false)
    },
    validationSchema: object({
      csvMode: string()
        .when(TAB_VALUE, {
          is: (tab) => tab === TabValues.CSV,
          then: string()
            .required(csvModes.error.required)
            .oneOf(csvModes.options, csvModes.error.invalid),
        }),
      endTimestamp: date()
        .when(TAB_VALUE, {
          is: (tab) => (
            (tab === TabValues.PatternOfPuffs)
            || (tab === TabValues.RandomPuffs)
          ),
          then: date().required(endTimestamp.error.required),
        })
        .min(ref('startTimestamp'), endTimestamp.error.min)
        .max(dateToday, endTimestamp.error.max),
      pattern: string()
        .when(TAB_VALUE, {
          is: (tab) => tab === TabValues.PatternOfPuffs,
          then: string().required(pattern.error.required),
        })
        .matches(/^\d+(,\d+)*$/, pattern.error.invalid),
      pod: string()
        .when(TAB_VALUE, {
          is: (tab) => tab !== TabValues.RemovePuffs && tab !== TabValues.CSV,
          then: string()
            .required(pods.error.required)
            .oneOf(pods.options, pods.error.invalid),
        }),
      puffCount: number()
        .when(TAB_VALUE, {
          is: (tab) => tab === TabValues.AddPuffs,
          then: number()
            .required(puffCount.error.required)
            .min(1, puffCount.error.min),
        }),
      startTimestamp: date()
        .when(TAB_VALUE, {
          is: (tab) => tab !== TabValues.RemovePuffs && tab !== TabValues.CSV,
          then: date().required(startTimestamp.error.required),
        })
        .max(dateToday, startTimestamp.error.max),
    }),
  })

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldValue,
    touched,
    values,
  } = formik

  useEffect(() => {
    resetForm()
    setFieldValue(TAB_VALUE, tab)
  }, [tab])

  return (
    <form id={formId} onSubmit={handleSubmit}>
      {(tab !== TabValues.RemovePuffs && tab !== TabValues.CSV && (
        <Step>
          <Instructions>{instructions.pods.default}</Instructions>
          <PodFieldset
            accessibilityLabel={pods.accessibilityLabel}
            error={touched.pod && Boolean(errors.pod)}
            errorText={touched.pod && errors.pod}
            form={formId}
            id='input-pod'
            labels={pods.labels}
            name='pod'
            onBlur={handleBlur}
            onChange={handleChange}
            options={pods.options}
            selected={values.pod}
          />
        </Step>
      ))}

      {(tab === TabValues.AddPuffs && (
        <Step>
          <Instructions>{instructions.puffCount.default}</Instructions>
          <InputField
            accessibilityLabel={puffCount.accessibilityLabel}
            error={touched.puffCount && Boolean(errors.puffCount)}
            errorText={touched.puffCount && errors.puffCount}
            form={formId}
            id='input-puffCount'
            label={puffCount.label}
            min={1}
            name='puffCount'
            onBlur={handleBlur}
            onChange={handleChange}
            type='number'
            value={values.puffCount}
          />
        </Step>
      ))}

      {tab === TabValues.PatternOfPuffs && (
        <Step>
          <Instructions>{instructions.pattern.default}</Instructions>
          <InputField
            accessibilityLabel={pattern.accessibilityLabel}
            error={touched.pattern && Boolean(errors.pattern)}
            errorText={touched.pattern && errors.pattern}
            form={formId}
            id='input-pattern'
            label={pattern.label}
            list='pattern-presets'
            name='pattern'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={pattern.placeholder}
            type='text'
            value={values.pattern}
          />
          <datalist id='pattern-presets'>
            {pattern.datalist.map((option, i) => (
              <option key={i}>{option}</option>
            ))}
          </datalist>
        </Step>
      )}

      {(tab !== TabValues.RemovePuffs && tab !== TabValues.CSV && (
        <Step>
          <Instructions>
            {instructions.timestamps[tab] ?? instructions.timestamps.default}
          </Instructions>
          <InputField
            accessibilityLabel={startTimestamp.accessibilityLabel}
            className={styles.timestamp}
            error={touched.startTimestamp && Boolean(errors.startTimestamp)}
            errorText={touched.startTimestamp && errors.startTimestamp}
            form={formId}
            id='input-startTimestamp'
            label={startTimestamp.label}
            max={dateToday}
            name='startTimestamp'
            onBlur={handleBlur}
            onChange={handleChange}
            type='datetime-local'
            value={values.startTimestamp}
          />
          <InputField
            accessibilityLabel={endTimestamp.accessibilityLabel}
            disabled={values.tab === TabValues.AddPuffs && values.puffCount == 1}
            error={touched.endTimestamp && Boolean(errors.endTimestamp)}
            errorText={touched.endTimestamp && errors.endTimestamp}
            form={formId}
            id='input-endTimestamp'
            label={endTimestamp.label}
            name='endTimestamp'
            max={dateToday}
            onBlur={handleBlur}
            onChange={handleChange}
            type='datetime-local'
            value={values.endTimestamp}
          />
        </Step>
      ))}

      {(tab === TabValues.CSV && (
        <Step>
          <Instructions>{instructions.csvMode.default}</Instructions>
          <CSVModeFieldset
            accessibilityLabel={csvModes.accessibilityLabel}
            error={touched.pod && Boolean(errors.pod)}
            errorText={touched.pod && errors.pod}
            form={formId}
            id='input-csvMode'
            labels={csvModes.labels}
            name='csvMode'
            onBlur={handleBlur}
            onChange={handleChange}
            options={csvModes.options}
            selected={values.csvMode}
          />
        </Step>
      ))}

      <Button
        accessibilityLabel={submit[tab]}
        className={styles.submit}
        content={submit[tab]}
        disabled={!isEmpty(errors)}
        form={formId}
        fullWidth
        loading={isSubmitting}
        primary='black'
        size='base'
        type='submit'
      />
    </form>
  )
}
