import { useState, useEffect } from 'react'

export type ScrollData = {
  prevX: number
  prevY: number
  x: number
  y: number
}

/**
 * hook provides scroll location and past location
 *
 *
 * @param delay - delay b/w throttled events
 * @returns - scrolling data
 */
export const useScroll = (isEnabled = false) => {
  const [data, setData] = useState<ScrollData>({
    prevX: 0,
    prevY: 0,
    x: 0,
    y: 0,
  })

  const handleScroll = () => {
    setData((prev) => ({
      prevX: prev.x,
      prevY: prev.y,
      x: window.scrollX,
      y: window.scrollY,
    }))
  }

  // set up event listeners
  useEffect(() => {
    if (!isEnabled) {
      window.removeEventListener('scroll', handleScroll)
      return
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isEnabled])

  return data
}
