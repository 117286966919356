import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './NicotineWarningDefault.module.scss'
import { classNames } from 'utils/css'

export interface NicotineWarningDefaultProps extends ComponentPropsWithoutRef<'div'> {
  content: string
  dataTestId: string
}

export const NicotineWarningDefault: FC<NicotineWarningDefaultProps> = ({
  className,
  content,
  dataTestId,
}) => (
  <div
    className={classNames(styles.NicotineWarningDefault, className)}
    data-testid={dataTestId}
  >
    <div className={styles.content}>{content}</div>
  </div>
)

// Great Britain Warning

export const nicotineWarningGBData: NicotineWarningDefaultProps = {
  content: 'This product contains nicotine\nwhich is a highly addictive substance',
  dataTestId: 'nicotineWarning-enGB',
}

export const NicotineWarningGB: FC<ComponentPropsWithoutRef<'div'>> = (props) => (
  <NicotineWarningDefault {...nicotineWarningGBData} {...props} />
)

// Kuwait Warning

export const nicotineWarningKWData: NicotineWarningDefaultProps = {
  content: 'WARNING: This product is not risk free, contains nicotine which is addictive, and may not be sold to or used by minors.',
  dataTestId: 'nicotineWarning-enKW',
}

export const NicotineWarningKW: FC<ComponentPropsWithoutRef<'div'>> = (props) => (
  <NicotineWarningDefault {...nicotineWarningKWData} {...props} />
)
