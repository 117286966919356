import Routes from '../../types/routes'
import { WebAppNavigation } from 'analytics-events'

export type NavVersion = 'desktop' | 'mobileTablet'

export type NavViewName =
  | 'Home'
  | 'Settings'
  | 'UsageInsights'

export type NavIndicators = {
  [ViewName in NavViewName]?: boolean
}

export enum NavIcons {
  JuulActive = 'icon/24x24/home_filled',
  JuulIdle = 'icon/24x24/home_outlined',
  SettingsActive = 'icon/24x24/setting_filled',
  SettingsIdle = 'icon/24x24/setting_outlined',
  UsageInsightsActive = 'icon/24x24/usage_insights_filled',
  UsageInsightsIdle = 'icon/24x24/usage_insights_outlined',
}

export type NavButtonProps = {
  // icon for the isActive button state
  activeIcon: NavIcons
  // button text content
  content: string
  // icon for the default button state
  idleIcon: NavIcons
  // analytics tracking label
  linkClicked: WebAppNavigation.PageName
  // local route
  route: Routes
}

export type NavViews = Record<NavViewName, NavButtonProps>
