import React, { FC, useState } from 'react'
import { useLocalStorage } from 'hooks'
import { CloseableNotice } from '../../components'
import { BoxProps } from 'components/Box'
import { getAppSemverVersion } from 'utils/app'
import { puffAlgoNoticeData } from './localization'
import { WebAppScreens } from 'analytics-events'

// config
export const PUFF_ALGO_NOTICE = 'puff-algo-update-notice'
export const versionConfig: string[] = JSON.parse(process.env.NEXT_PUBLIC_VERSIONS_WITH_PUFF_ALGO_NOTICE || '[]')

export const PuffAlgoUpdateNotice: FC<BoxProps> = (boxProps) => {
  // local storage
  const [
    puffAlgoNoticeHistory,
    setPuffAlgoNoticeHistory,
  ] = useLocalStorage<Record<string, boolean>>(PUFF_ALGO_NOTICE, {})

  // local state
  const currentAppVersion = getAppSemverVersion()
  const [isOpen, setIsOpen] = useState<boolean>(
    versionConfig.includes(currentAppVersion)
    && !puffAlgoNoticeHistory[currentAppVersion],
  )

  // handlers
  const handleOnClose = () => {
    setIsOpen(false)
    setPuffAlgoNoticeHistory({
      ...puffAlgoNoticeHistory,
      [currentAppVersion]: true,
    })
  }

  return isOpen && (
    <CloseableNotice
      closeButton={{
        clickEvent: WebAppScreens.UsageInsights.ClickClose_PuffAlgoNotice(),
        onClick: handleOnClose,
      }}
      id='puff-algo-update-notice'
      open={isOpen}
      {...puffAlgoNoticeData}
      {...boxProps}
    />
  )
}
