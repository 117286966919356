import { useEffect, useState } from 'react'

/**
 * Use this hook to return the corresponding value based on the current
 * breakpoint.
 *
 * @param queries - a list of media query breakpoints
 * @param values - a list of values corresponding to each breakpoint
 * @param defaultValue - default value returned if no breakpoint matches
 *
 * @returns - the corresponding matched query value
 */
export const useMediaQuery = <T>(
  queries: string[],
  values: T[],
  defaultValue: T,
) => {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q))
  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(
      (mediaQueryList) => mediaQueryList.matches,
    )
    // Return related value or defaultValue if none
    return values?.[index] || defaultValue
  }

  // State and setter for matched value
  const [value, setValue] = useState<T>(getValue)

  useEffect(() => {
    // Event listener callback
    const handler = () => setValue(getValue)
    // Set values on param change
    handler()
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mediaQueryList) =>
      mediaQueryList.addEventListener('change', handler))
    // Remove listeners on cleanup
    return () =>
      mediaQueryLists.forEach((mediaQueryList) =>
        mediaQueryList.removeEventListener('change', handler))
  }, [queries, values, defaultValue])

  return value
}
