import { WebAppScreens } from 'analytics-events'
import { ButtonProps } from 'components/Button'
import { NotificationProps } from 'components/Notification'

export enum AuthErrorCode {
  DefaultError = 'defaultError',
  NetworkError = 'networkError',
  SessionExpired = 'sessionExpired',
}

type ErrorButtonProps = Pick<NotificationProps, 'buttonText' | 'clickEvent'>

type WelcomeData = {
  error: { [key in AuthErrorCode]: string }
  errorButton: { [key in AuthErrorCode]?: ErrorButtonProps }
  instructions: string
  loginButton: ButtonProps
  title: string
}

const loginBtn: ButtonProps = {
  clickEvent: WebAppScreens.Welcome.ClickSignUpLogIn(),
  content: 'Sign up / Log in now',
}

const networkErrorBtn: ErrorButtonProps = {
  buttonText: 'Try again',
  clickEvent: WebAppScreens.Welcome.ClickTryAgain(),
}

export const welcomeData: WelcomeData = {
  error: {
    defaultError: 'An error occurred during login. Please try again.',
    networkError: 'Please check your internet connection and try again.',
    sessionExpired: 'For your security, your session has been ended. Please log back in.',
  },
  errorButton: { networkError: networkErrorBtn },
  instructions: 'We will take you to your web browser to sign up or log in and then bring you back here.',
  loginButton: loginBtn,
  title: 'Welcome to the JUUL App',
}
