import React, { FC, ReactNode, useEffect } from 'react'
import styles from './ActivityUpdatePreview.module.scss'
import { Text, ComplexMessage } from 'components/Text'
import { Spinner } from 'components/Spinner'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { Icon } from '@juullabs/react-components'
import { classNames } from 'utils/css'
import { InvalidInsight } from '../InvalidInsight'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'

export interface ActivityUpdatePreviewProps extends UnstyledLinkProps {
  accessibilityLabel: string
  body: ComplexMessage
  chart: ReactNode
  heading: ComplexMessage
  isValid?: boolean
  loading?: boolean
  previewEvent?: Event
  shimmer?: boolean
}

export const ActivityUpdatePreview: FC<ActivityUpdatePreviewProps> = ({
  accessibilityLabel,
  body,
  chart,
  className,
  heading,
  isValid = true,
  loading,
  previewEvent,
  shimmer,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!previewEvent) return

    reportEvent(previewEvent)
  }, [previewEvent])

  return (
    <UnstyledLink
      aria-label={accessibilityLabel}
      className={classNames(
        styles.ActivityUpdatePreview,
        loading && styles.loading,
        shimmer && !loading && isValid && styles.shimmer,
        className,
      )}
      disabled={loading || !isValid}
      local
      {...rest}
    >
      <article
        aria-hidden={loading || !isValid}
        className={classNames(
          styles.content,
          loading && styles.contentLoading,
        )}
      >
        {chart}
        <div className={styles.description}>
          <Text
            color='graphica|base|100'
            margin={{ bottom: 'extremeTight' }}
            tagName='h3'
            typography='subtitleSmall'
            {...heading}
          />
          <Text
            tagName='p'
            typography='bodyBase'
            {...body}
          />
        </div>
        <Icon
          color={'steel|base|30'}
          name='icon/24x24/chevron_base_right'
        />
      </article>
      {!isValid && !loading && (
        <InvalidInsight format='horizontal' />
      )}
      {loading && (
        <Spinner className={styles.spinner} color='dark' />
      )}
    </UnstyledLink>
  )
}
