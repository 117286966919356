import React, { FC } from 'react'
import { Icon } from '@juullabs/react-components'
import { Text, TextFormats } from 'components/Text'
import styles from './ThankYou.module.scss'
import { Box, BoxProps } from 'components/Box'

export type ThankYouData = {
  description: TextFormats
  heading: TextFormats
}

export const thankYouData: ThankYouData = {
  description: { content: 'Your feedback helps our team improve the JUUL App experience.' },
  heading: { content: 'Thank you.' },
}

export const ThankYou: FC<BoxProps> = ({ ...rest }) => (
  <Box className={styles.ThankYou} {...rest}>
    <Icon
      className={styles.icon}
      name='icon/24x24/take_away_message-outlined'
    />
    <Text
      typography='h4Mobile'
      message={thankYouData.heading}
      tagName='p'
    />
    <Text
      className={styles.description}
      typography='bodyBase'
      message={thankYouData.description}
      tagName='p'
    />
  </Box>
)
