import React, { FC } from 'react'
import styles from './JuulMissionPage.module.scss'
import { classNames } from 'utils/css'
import { Icon } from '@juullabs/react-components'
import { GridItem } from '../GridItem'
import { Page, PageProps } from '../Page'

// data
export const juulMission = 'The mission of Juul Labs is to transition the world’s billion adult smokers away from combustible cigarettes, eliminate their use, and combat underage usage of our products.'

export const JuulMissionPage: FC<PageProps> = ({
  children,
  className,
  ...rest
}) => (
  <GridItem>
    <Page className={styles.JuulMissionPage} {...rest}>
      <div className={styles.leftColumn}>
        <div className={styles.leftCard}>
          <Icon
            className={styles.logo}
            name='logo/juul_standard-white_base_100'
          />
          <p className={styles.mission}>
            {juulMission}
          </p>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={classNames(styles.rightCard, className)}>
          {children}
        </div>
      </div>
    </Page>
  </GridItem>
)
