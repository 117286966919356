import { WebAppScreens, Event } from 'analytics-events'

// config

/** key for usage insights preferences in local storage */
export const UI_PREFERENCES = 'usage-insights-preferences'

/** keys for activity update trends in local storage */
export const LONG_TERM_TREND_SEEN = 'has-seen-long-term-trend'
export const SHORT_TERM_TREND_SEEN = 'has-seen-short-term-trend'

// types

/** local storage usage insight keys */
export enum UIKeys {
  ActivityUpdates = 'activity-updates',
  AveragePuffDay = 'average-puff-day',
  AveragePuffHour = 'average-puff-hour',
  BatteryConsumption = 'battery-consumption',
  PodConsumption = 'pod-consumption',
  PodHistory = 'pod-history',
  PuffHistory = 'puff-history',
  PuffsSoFar = 'puffs-so-far',
}

/** local storage type for a users usage insights preferences */
export type UIPreferences = Record<UIKeys, boolean>

/** state for the status of a Chai.UsageEstimate */
export enum EstimateStatus {
  Calculated = 'calculated',
  Error = 'error',
  Loading = 'loading',
  Unknown = 'unknown'
}

/** trend state for Activity Updates */
export enum TrendDirection {
  Higher = 'Higher',
  Lower = 'Lower',
  NoTrend = 'NoTrend',
}

/** trend props for long term Activity Updates */
export type LongTermTrend = {
  direction: TrendDirection
  weeks: number
}

// data

/** messaging for different Activity Updates trends */
export const trendWording = {
  [TrendDirection.Higher]: 'more than',
  [TrendDirection.Lower]: 'less than',
  [TrendDirection.NoTrend]: 'about the same amount as',
}

/** initial state for users usage insights preferences stored in local storage  */
export const initialUIPreferencesCache: UIPreferences = {
  [UIKeys.ActivityUpdates]: false,
  [UIKeys.AveragePuffDay]: false,
  [UIKeys.AveragePuffHour]: false,
  [UIKeys.BatteryConsumption]: false,
  [UIKeys.PodConsumption]: false,
  [UIKeys.PodHistory]: false,
  [UIKeys.PuffHistory]: false,
  [UIKeys.PuffsSoFar]: false,
}

/** object of all the usage insights toggle event getter functions, keyed to UIKeys */
export const toggleUIEvents: Record<UIKeys, (arg: { enabled: boolean }) => Event> = {
  [UIKeys.ActivityUpdates]: WebAppScreens.UsageInsights.ToggleActivityUpdates,
  [UIKeys.AveragePuffDay]: WebAppScreens.UsageInsights.ToggleAveragePuffDays,
  [UIKeys.AveragePuffHour]: WebAppScreens.UsageInsights.ToggleAveragePuffHours,
  [UIKeys.BatteryConsumption]: WebAppScreens.UsageInsights.ToggleBatteryConsumption,
  [UIKeys.PodConsumption]: WebAppScreens.UsageInsights.TogglePodConsumption,
  [UIKeys.PodHistory]: WebAppScreens.UsageInsights.TogglePodHistory,
  [UIKeys.PuffHistory]: WebAppScreens.UsageInsights.TogglePuffHistory,
  [UIKeys.PuffsSoFar]: WebAppScreens.UsageInsights.TogglePuffsSoFar,
}
