import { format } from 'date-fns'
import { StoredSdkLogs, LogLevel, LogLevelKey } from './types'
import { orderedLogLevels, logLevelTabs } from './config'

// PROVIDER UTILS

// generate map of levels included within different level categories
const logLevelMap = orderedLogLevels.reduce((map, level, index) => {
  map[level] = orderedLogLevels.slice(0, index+1)
  return map
}, {})

export const updateSdkLogs = (
  log: string,
  sdkLogs: StoredSdkLogs,
): StoredSdkLogs => {
  // Extract category from the new log
  const category = log.match(/^\[([^/]*)\//)?.[1]

  // Update logs by pushing the new log entry
  sdkLogs.logs.push({ log, timestamp: new Date().toISOString() })

  // Set index of the new log from the updated logs
  const newIndex = sdkLogs.logs.length - 1

  // Iterate over orderedLogLevels to include the newIndex in the appropriate level array
  orderedLogLevels.forEach((level) => {
    if (logLevelMap[level].includes(category)) {
      sdkLogs.levelIndex[level] = (sdkLogs.levelIndex[level] || [])
      sdkLogs.levelIndex[level].push(newIndex)
    }
  })

  // Return the updated sdkLogs
  return sdkLogs
}

// generates a file name for the exported json file
export const generateFileName = () => {
  const timestamp = new Date()
  const date = format(timestamp, 'y-MM-dd')
  const time = format(timestamp, 'HH.mm.ss')
  return `SDK Logs ${date} at ${time}.json`
}

// UI UTILS

// generates an array of the log levels available based on the current logListenerLevel
export const getLogLevelTabs = (logListenerLevel: LogLevel): LogLevel[] => {
  const index = logLevelTabs.indexOf(logListenerLevel)
  if (index === -1) return []
  return logLevelTabs.slice(index)
}

// returns the logLevelKey corresponding to the given logLevel
export const getLogLevelKey = (logLevel: LogLevel):LogLevelKey => {
  switch (logLevel) {
  case LogLevel.Assert: return LogLevelKey.Assert
  case LogLevel.Debug: return LogLevelKey.Debug
  case LogLevel.Error: return LogLevelKey.Error
  case LogLevel.Info: return LogLevelKey.Info
  case LogLevel.Verbose: return LogLevelKey.Verbose
  case LogLevel.Warn: return LogLevelKey.Warn
  }
}
