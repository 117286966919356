/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** Feedback Events for WebApp */
export namespace WebAppFeedback {

  /** Feedback prompt types */
  export enum FeedbackPrompt {
    Default = "default",
    CurrentVersion = "currentVersion",
  }

  /** Fires when user submits star rating. */
  type StarRatingEventProps = {
    feedbackPrompt: FeedbackPrompt
    stars: number
    positiveFeedback?: string
    negativeFeedback?: string
  }

  export const StarRatingEvent = ({
    feedbackPrompt,
    stars,
    positiveFeedback,
    negativeFeedback,
  }: StarRatingEventProps): Event => {
    const properties: Record<string, any> = {
      feedback_prompt: feedbackPrompt,
      stars: stars,
      positive_feedback: positiveFeedback,
      negative_feedback: negativeFeedback,
    }

    return new Event({
      properties: properties,
      eventName: 'feedback_star_rating',
      type: EventType.Stat,
    })
  }

  /** Fires when user submits feedback via option in user settings */
  type UserFeedbackEventProps = {
    message: string
  }

  export const UserFeedbackEvent = ({
    message,
  }: UserFeedbackEventProps): Event => {
    const properties: Record<string, any> = {
      message: message,
    }

    return new Event({
      properties: properties,
      eventName: 'user_feedback',
      type: EventType.Stat,
    })
  }
}
