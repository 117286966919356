import React, { FC, useState, useMemo } from 'react'
import { startOfToday } from 'date-fns'
import {
  DateRangePicker,
  InsightsCard,
  InsightsCardProps,
} from '../../components'
import { SlidingTabs } from 'components/SlidingTabs'
import styles from './PuffHistory.module.scss'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Box } from 'components/Box'
import {
  PuffHistoryBarchart,
  PuffHistoryFormat,
} from '../../charts/PuffHistoryBarchart'
import { WebAppInsights, Event } from 'analytics-events'

// config
const PUFF_HISTORY_CHART_HEIGHT = 272

// data
export const puffHistoryTitle = 'Puff History'
const AVAILABLE_FORMATS: PuffHistoryFormat[] = [
  PuffHistoryFormat.SevenDay,
  PuffHistoryFormat.Month,
]

// types
export type PuffHistoryProps = Omit<InsightsCardProps, 'title'>

export const PuffHistory: FC<PuffHistoryProps> = ({ loading, ...rest }) => {
  const { trackClickEvent } = useAnalytics()

  const [isValid, setIsValid] = useState<boolean>(null)
  const [
    chartFormat,
    setChartFormat,
  ] = useState<PuffHistoryFormat>(PuffHistoryFormat.SevenDay)
  const [startDate, setStartDate] = useState<Date>(startOfToday())

  const trackNavigationClick = (viewClicked: string) =>
    trackClickEvent({
      screenEvent: 'UsageMonitor',
      viewClicked: `Summary|${viewClicked}`,
    })

  const changeChartFormat = (value: PuffHistoryFormat) => {
    trackNavigationClick(value === '7 days' ? 'Week' : 'Month')
    setChartFormat(value)
  }

  const handleDateSelection = (start) => {
    setStartDate(start)
  }

  const handleDirectionChange = (direction) => {
    const eventDirectionLabel = direction === 'prev' ? 'DatePrior' : 'DateForward'
    trackNavigationClick(eventDirectionLabel)
  }

  const puffHistoryEvent = useMemo<Event>(() => {
    if (isValid === null) return null
    return WebAppInsights.PuffHistoryEvent({ isValid })
  }, [isValid])

  const puffHistoryControls = (
    <Box
      className={styles.puffHistoryControls}
      padding={{ top: 'tight' }}
    >
      <SlidingTabs
        id='puffHistory'
        onClickValue={changeChartFormat}
        values={AVAILABLE_FORMATS}
      />
      <div className={styles.datePickers}>
        {AVAILABLE_FORMATS.map((format) => (
          <div key={format} className={styles.date}>
            {chartFormat === format && (
              <DateRangePicker
                onClickNavigation={handleDirectionChange}
                onPickDate={handleDateSelection}
                type={format}
              />
            )}
          </div>
        ))}
      </div>
    </Box>
  )

  return (
    <InsightsCard
      cardEvent={puffHistoryEvent}
      isValid={isValid}
      controls={puffHistoryControls}
      title={puffHistoryTitle}
      loading={loading || isValid === null}
      {...rest}
    >
      <PuffHistoryBarchart
        chartFormat={chartFormat}
        height={PUFF_HISTORY_CHART_HEIGHT}
        padding={{ top: 'baseLoose' }}
        setIsValid={setIsValid}
        startDate={startDate}
      />
    </InsightsCard>
  )
}
