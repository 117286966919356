import { TextFormats } from 'components/Text'

export const title = 'Battery Consumption'

type Estimate = {
  failure: TextFormats
  success: TextFormats
}
export const estimateMessage: Estimate = {
  failure: { content: 'An estimate for puffs per charge is not available.' },
  success: [
    { content: 'You get approximately ' },
    { content: '%{estimate} puffs', tagName: 'strong' },
    { content: ' per charge.' },
  ],
}

export const heading: TextFormats = { content: 'You use about %{charges} per day.' }

type FormatHeading = (charges: number) => { charges: string }
export const formatHeading: FormatHeading = (charges) => ({
  charges: (charges === 1)
    ? '1 full battery charge'
    : `${charges} full battery charges`,
})

export const overline: TextFormats = { content: '(Average over the last 7 days)' }
