import React, { FC } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from './Spinner.module.scss'

export type SpinnerProps = {
  /** visually hidden label */
  accessibilityLabel?: string
  /** custom classname for the circle itself */
  circleClassName?: string
  /** custom classname */
  className?: string
  /** optional color presets for base backgrounds */
  color?: 'light' | 'dark'
  /**
   * the size of the spinner
   * @default base
   */
  size?: 'base' | 'small'
}

export const Spinner: FC<SpinnerProps> = ({
  accessibilityLabel = 'Loading',
  circleClassName,
  className,
  color,
  size = 'base',
}) => (
  <div className={classNames(styles.Spinner, className)}>
    <span
      role='presentation'
      aria-hidden='true'
      className={classNames(
        styles.circle,
        size && styles[variationName('size', size)],
        color && styles[variationName('color', color)],
        circleClassName,
      )}
    ></span>
    <span className={styles.visuallyHidden}>{accessibilityLabel}</span>
  </div>
)
