import { useContext } from 'react'
import { AuthenticationContext } from './AuthenticationProvider'

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext)

  if (context === undefined) {
    throw new Error(
      '`useAuthentication` hook must be used within a `AuthenticationProvider` component',
    )
  }

  return context
}
