import { orderedLogLevels } from './config'
import { ConsoleAction } from './types'

// generate map of levels included within different level categories
export const logLevelMap = orderedLogLevels.reduce((map, level, index) => {
  map[level] = orderedLogLevels.slice(0, index+1)
  return map
}, {})

// generates an array of the log levels available based on the desired logLevel
export const getLogLevelTabs = (
  levels: ConsoleAction[],
  logLevel: ConsoleAction,
): ConsoleAction[] => {
  const index = levels.indexOf(logLevel)
  if (index === -1) return []
  return levels.slice(index)
}

export const stringifyReplacer = (_key, value) => {
  if (typeof value === 'function') return value.toString()
  else if (typeof value === 'bigint') return value.toString() + 'n'
  else return value
}
