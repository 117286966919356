import { useContext } from 'react'
import { SdkLogsContext } from '../SdkLogsProvider'

export const useSdkLogs = () => {
  const context = useContext(SdkLogsContext)

  if (context === undefined) {
    throw new Error(
      '`useSdkLogs` hook must be used within a `SdkLogsProvider` component',
    )
  }

  return context
}
