import React, { FC, PropsWithChildren } from 'react'
import { classNames, variationName } from 'utils/css'
import { Icon } from '@juullabs/react-components'
import { AtomColor } from '@juullabs/react-components/src/types'
import styles from './ButtonContent.module.scss'

export interface ButtonContentInterface {
  /** Align icon on left or right side */
  alignIcon?: 'left' | 'right'
  /** Additional classnames */
  className?: string
  /** Button text */
  content?: string
  /**
   * An Icon to be displayed in the button.
   * Name comes from specify see https://specifyapp.com/@juul/atom/vector
   */
  icon?: string
  /** Manages the position of the content and icon */
  justifyContent?: 'center' | 'spaceBetween'
  /**
   * Changes the size of the button, giving it more or less padding
   * @default base
   */
  size?: 'small' | 'base'
}

export interface ButtonContentProps extends ButtonContentInterface {
  /**
   * Color for the icon, format ex. steel|base|100
   * see https://specifyapp.com/@juul/atom/color for options
   */
  iconColor?: AtomColor
}

export const ButtonContent: FC<PropsWithChildren<ButtonContentProps>> = ({
  alignIcon = 'right',
  children,
  className,
  content,
  icon,
  iconColor,
  justifyContent = 'center',
  size = 'base',
}) => {
  const iconMarkdown = icon && (
    <Icon
      color={iconColor}
      className={classNames(
        styles.buttonIcon,
        content ? styles[variationName('iconAligned', alignIcon)]
          : styles[variationName('iconOnlySize', size)],
      )}
      name={icon}
    />
  )

  return (
    <div className={classNames(
      styles.ButtonContent,
      justifyContent && styles[variationName('justify', justifyContent)],
      className,
    )}>
      {alignIcon === 'left' && iconMarkdown}
      {content}
      {children}
      {alignIcon === 'right' && iconMarkdown}
    </div>
  )
}
