import classnames from 'classnames'

/**
 * Get a variation's className
 *
 * @param {string} name
 * @param {string} value
 * @returns - the corresponding className for the variation
 */
export const variationName = (name: string, value: string) =>
  `${name}${value.charAt(0).toUpperCase()}${value.slice(1)}`

/**
 * Get a variation's className using numbers
 *
 * @param {string} name
 * @param {number} value
 * @returns - the corresponding className for the variation
 */
export const variationSize = (name: string, value: number) =>
  `${name}${value}`

export const classNames = classnames
