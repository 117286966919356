import { TextFormats } from 'components/Text'

export const title = 'Average Puff Days'

export const body: TextFormats = [
  { content: 'You tend to vape the most on ' },
  { content: '%{max}', tagName: 'strong' },
  { content: ' and the least on ' },
  { content: '%{min}', tagName: 'strong' },
  { content: '.' },
]

type FormatBody = (min: string, max: string) => { max: string, min: string }
export const formatBody: FormatBody = (min, max) => (
  {
    max: formatDayOfWeek(max),
    min: formatDayOfWeek(min),
  }
)

// conx-sdk gives us days like MONDAY and TUESDAY
const formatDayOfWeek = (value: string) => (
  value.charAt(0) + value.slice(1).toLowerCase() + 's'
)
