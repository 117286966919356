import { useState, useEffect } from 'react'
import Chai from 'types/chai'

export const useListenable = <T>(initial: T, listenable: Chai.Listenable<T>) => {
  const [state, setState] = useState<T>(initial)

  useEffect(() => {
    if (!listenable) return
    listenable.addListener(setState)
    return () => { listenable.removeListener(setState) }
  }, [listenable])

  return state
}
