/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */

/** Property Keys */
export enum Key {
  EventName = "event_name",
  EventType = "event_type",
  EventData = "event_data",
  EventProperties = "properties",
  ScreenName = "name",
}

/** Event Types (i.e valid values for Key.EventType) */
export enum EventType {
  Screen = "screens",
  Stat = "stat",
  DeviceLog = "device_log",
}
