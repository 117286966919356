import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TokenSet } from 'next-auth'
import { camelCase, mapKeys, rearg } from 'lodash'

import { bugsnagClient } from 'utils/bugsnag'
import { getAppVersion } from 'utils/app'

import {
  Order,
  OrdersResponse,
  Subscription,
  SubscriptionResponse,
  UserInfo,
} from './types'

const OAUTH_HANDSHAKE_PATH = '/sso/handshake'
const OAUTH_TOKEN_PATH = '/auth/oauth/token'
const OAUTH_USER_INFO_PATH = '/mobile/user_info'
export const ORDERS_URL = '/api/v1/orders'
export const SUBSCRIPTIONS_URL = '/api/v1/subscriptions'

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  // We need this header to skip CSRF protection on Juulio. See:
  // 'Protecting REST Services: Use of Custom Request Headers':
  // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet
  'X-Juul-Mobile-App-Version': getAppVersion(),
  'X-Juul-Web-App-Version-Code': '1',
}

type MobilecloudRequest<D = any> = {
  body?: D
  options?: AxiosRequestConfig
  params?: AxiosRequestConfig['params']
  url: string
}

/** CLIENT METHODS **/

// Set an auth token to indicate requests coming from a valid user session.
export const setAuthToken = (token: string) => {
  mobilecloudClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// Clear the auth token when the user session is terminated.
export const clearAuthToken = () => delete mobilecloudClient.defaults.headers.common['Authorization']

/** ENDPOINTS **/

type UploadOAuthKeyBody = {
  /** Optional Juulio QA environment override, e.g, 'qa14' */
  environment?: string
  /** Serialize public key of the client's SSO handshake keypair */
  public_key: string
}

/**
 * Prime the SSO process by uploading a public key to secure the user's keychain in-transit.
 *
 * @param body - request body
 *
 * @returns - the session_id string from the response
 */
export const uploadOAuthKey = async(body: UploadOAuthKeyBody): Promise<string> =>
  post({
    body: {
      ...body,
      // Optional override to target the preprod (Actual Use Studies) environment
      // from this app pointed at a non-prod Mobilecloud environment.
      client_id: process.env.NEXT_PUBLIC_JUULIO_CLIENT_ID || undefined,
    },
    url: OAUTH_HANDSHAKE_PATH,
  }).then(({ data }) => data.session_id)

/**
 * Fetch the user's access token using an authorization code.
 *
 * @param body - request body
 *
 * @returns - the grant info
 */
export const makeTokenRequest = async({ params, provider }): Promise<TokenSet> => {
  if (!params.code) {
    bugsnagClient?.notify?.({ message: 'Access grant requested without auth code', name: 'oauth_access_grant_failed' })
  }

  const optionalParams = {}
  if (process.env.JUULIO_CLIENT_SECRET) {
    optionalParams['client_id'] = process.env.NEXT_PUBLIC_JUULIO_CLIENT_ID
    optionalParams['client_secret'] = process.env.JUULIO_CLIENT_SECRET
  }

  const { data: accessGrant } = await post({
    body: {
      ...params,
      ...optionalParams,
      grant_type: 'authorization_code',
      redirect_uri: provider.callbackUrl,
    },
    url: OAUTH_TOKEN_PATH,
  })

  const { access_token } = accessGrant
  // const { access_token } = grant_info
  setAuthToken(access_token)

  return accessGrant
}

/**
 * Fetch the user's most recent ecomm order
 *
 * @returns The most recent order
 */
export const getMostRecentOrder = async(): Promise<Order> => {
  const url = `${ORDERS_URL}?page[size]=1`
  const response = await get<OrdersResponse>({ url })

  return response.data.orders.map(remapPropertiesToCamelCase)?.[0] as Order
}

/**
 * Fetch the user's authoship subscription
 *
 * @returns The most
 */
export const getSubscription = async(): Promise<Subscription> => {
  const url = SUBSCRIPTIONS_URL
  const response = await get<SubscriptionResponse>({ url })

  return remapPropertiesToCamelCase(response.data.subscription) as Subscription
}


/**
 * Fetch the user's access token using an authorization code.
 *
 * @param body - request body
 *
 * @returns - the grant info
 */
export const makeUserInfoRequest = async(_context): Promise<UserInfo> => {
  const { data: userInfo } = await get({ url: OAUTH_USER_INFO_PATH })

  return userInfo
}

/**
 * Get Request
 *
 * @param request.params - request params
 * @param request.url - url string
 *
 * @returns - get response
 */
const get = <T = any, R = AxiosResponse<T>>({
  params = {},
  url,
}: MobilecloudRequest): Promise<R> => mobilecloudClient.get(url, params)


/**
 * Post Request
 *
 * @param request.body - request body
 * @param request.options - Axios request options
 * @param request.url - url string
 *
 * @returns - post response
 */
const post = <T = any, R = AxiosResponse<T>, D = any>({
  body,
  url,
}: MobilecloudRequest<D>): Promise<R> => mobilecloudClient.post<T, R, D>(url, body)

/** HELPERS **/

/**
 * Remap the auth server's snake_case fields into camelCase.
 * @param properties Record<string, any> properties to remap
 * @returns camelCased property keys
 */
export const remapPropertiesToCamelCase = (properties: Record<string, any>) =>
  typeof properties === 'object'
    ? mapKeys(properties, rearg(camelCase, 1))
    : null

export const getBaseParams = () => {
  const baseParamStr = process.env.NEXT_PUBLIC_OAUTH_CUSTOM_PARAMS

  if (!baseParamStr) return {}

  try {
    return Object.fromEntries(
      baseParamStr
        .split(',')
        .map((paramStr) => paramStr.split('=')),
    )
  } catch (e) {
    bugsnagClient?.notify({
      message: e.error,
      name: 'mobilecloud_env_param_parse_failure',
    })
    return {}
  }
}

export const getBackendEnvironmentParams = () => {
  const build = process.env.NEXT_PUBLIC_BUILD_VERSION
  if (!build) return {}

  return { environment: build }
}

const mobilecloudClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MOBILECLOUD_URL,
  headers: DEFAULT_HEADERS,
  params: getBaseParams(),
})

mobilecloudClient.interceptors.request.use(async(config) => {
  try {
    if (typeof window === 'undefined' || !window?.AwsWafIntegration) {
      return config
    }

    const wafToken = await window?.AwsWafIntegration?.getToken()

    if (wafToken) {
      config.headers['x-aws-waf-token'] = wafToken
    }

  } catch (error) {
    bugsnagClient?.notify?.(error)
  }

  return config
})

export default mobilecloudClient
