import { UnsupportedAgentScreen } from 'modules/UnsupportedAgent'

export const COMPATIBLE_CHROME_VERSION = 106
export const COMPATIBLE_BLUEFY_VERSION = '>=3.8.5'

export type UnsupportedAgentType = UnsupportedAgentScreen | null

export type AppProviderContextType = {
  isInitialRouteLoading: boolean
  unsupportedAgent: UnsupportedAgentType
}
