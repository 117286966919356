import React, {
  FC,
  PropsWithChildren,
  ComponentPropsWithoutRef,
} from 'react'
import styles from './SlidingPanel.module.scss'
import { classNames } from 'utils/css'
import { UnstyledButton } from 'components/UnstyledPrimitives'

export interface SlidingPanelProps extends ComponentPropsWithoutRef<'div'> {
 isOpen: boolean
 setIsOpen: (isOpen: boolean) => void
 tabLabel: string
}

export const SlidingPanel: FC<PropsWithChildren<SlidingPanelProps>> = ({
  children,
  className,
  isOpen,
  setIsOpen,
  tabLabel,
  ...rest
}) => (
  <div className={classNames(styles.SlidingPanel, className)} {...rest}>
    <div
      className={classNames(
        styles.container,
        isOpen ? styles.open : styles.closed,
      )}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <UnstyledButton
        aria-label={`Open ${tabLabel}`}
        className={styles.tab}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.buttonContent}>
          {tabLabel}
        </span>
      </UnstyledButton>
      <div className={classNames(styles.content, isOpen && styles.contentOpen)}>
        {children}
      </div>
    </div>
  </div>
)
