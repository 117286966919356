import React, {
  FC,
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react'
import {
  UsageSyncState,
  UsageInsightsState,
  UsagePageState,
} from './types'
import Chai from 'types/chai'
import { useDeviceConnectionReducer } from './reducer'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { useAuthentication } from 'modules/AuthenticationProvider'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { getIsDebugMode } from 'utils/app'

export type DeviceConnectionContextType = {
  currentHardwareId: string | null
  deviceEventHandler: Chai.DeviceEventHandler | null
  gadgetsHandler: Chai.gadgets.Gadgets | null
  gadgetsList: Chai.gadgets.Gadget[] | null
  gadgetsObject: Record<string, Chai.gadgets.Gadget> | null
  isBluetoothAvailable: typeof Chai.isBluetoothAvailable
  isFirmwareUpdateAvailable: boolean
  isGadgetsReady: boolean
  isLogSyncEnabled: boolean
  isLogSyncStatusKnown: boolean
  isLogSyncToggleUpdating: boolean
  isUsageInsightsReady: boolean
  lastUsageSyncDate: Date | null
  setCurrentHardwareId: (hardwareId: string | null) => void | null
  toggleCloudSyncOptIn: (optIn: boolean) => Promise<void>
  usageInsights: Chai.UsageInsights | null
  usageInsightsManager: Chai.UsageInsightsManager | null
  usageInsightsState: UsageInsightsState | null
  usageSyncState: UsageSyncState | null
}

export const DeviceConnectionContext =
  createContext<DeviceConnectionContextType | null>({
    currentHardwareId: null,
    deviceEventHandler: null,
    gadgetsHandler: null,
    gadgetsList: null,
    gadgetsObject: null,
    isBluetoothAvailable: null,
    isFirmwareUpdateAvailable: false,
    isGadgetsReady: false,
    isLogSyncEnabled: false,
    isLogSyncStatusKnown: false,
    isLogSyncToggleUpdating: false,
    isUsageInsightsReady: false,
    lastUsageSyncDate: null,
    setCurrentHardwareId: null,
    toggleCloudSyncOptIn: null,
    usageInsights: null,
    usageInsightsManager: null,
    usageInsightsState: null,
    usageSyncState: null,
  })

// Usage Insights days until all insights are available (7 days or 1 week)
const UI_DELAY_DAYS = 7

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `DeviceConnectionContext.Provider` component. This is where the
// Chai BLE stack is going to be exposed to the rest of the app.
export const DeviceConnectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { sdkContext } = useConxSdk()
  const [isInitializing, setIsInitializing] = useState<boolean>(false)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const {
    setCloudSyncOptIn,
    setCurrentHardwareId,
    setGadgets,
    setIsUsageInsightsReady,
    setLastUsageSyncDate,
    setUsageInsightsState,
    setUsageSyncState,
    setup,
    toggleCloudSyncOptIn,
    state,
  } = useDeviceConnectionReducer()

  const { userSession } = useAuthentication()

  const { setHasFirstWeekPuffData } = useAnalytics()

  const {
    gadgetsList,
    usageInsights,
    usageInsightsState,
  } = state

  /**
   * Setup the Chai BLE stack
   *
   * @returns {Promise<void>}
   */
  const initialize = async() => {
    setIsInitializing(true)

    try {
      const {
        deviceEventHandler,
        gadgetsHandler,
      } = await setup({ context: sdkContext })

      deviceEventHandler?.addListener(
        Chai.EventName.USER_PREFERENCES,
        setCloudSyncOptIn,
      )

      deviceEventHandler.addListener(
        Chai.EventName.USAGE_SYNC_STATE,
        setUsageSyncState,
      )

      deviceEventHandler.addListener(
        Chai.EventName.USAGE_SYNC_LAST_SYNC,
        setLastUsageSyncDate,
      )

      gadgetsHandler.addListener(setGadgets)

      setIsInitialized(true)
    } finally {
      setIsInitializing(false)
    }
  }

  /**
   * Manage conx-sdk initialization
   */
  useEffect(() => {
    if (sdkContext && !isInitializing && !isInitialized) {
      initialize()
    }

  }, [sdkContext, userSession?.isSessionActive, isInitialized, isInitializing])

  /**
   * Add listener for usageInsightsState
   */
  const setUsageInsightsStateCallback = ({
    daysOfData,
    showLongTermTrend,
    showShortTermTrend,
  }: UsagePageState) => {
    const hasWeekOfData = daysOfData >= UI_DELAY_DAYS
    const daysTillWeek = hasWeekOfData ? 0 : UI_DELAY_DAYS - daysOfData

    setUsageInsightsState({
      daysOfData,
      daysTillWeek,
      hasWeekOfData,
      showLongTermTrend,
      showShortTermTrend,
    })
    if (hasWeekOfData) setHasFirstWeekPuffData(hasWeekOfData)
  }

  useEffect(() => {
    if (!usageInsights || !userSession?.isSessionActive) return

    usageInsights?.usagePageState((
      daysOfData: number,
      showShortTermTrend: boolean,
      showLongTermTrend: boolean,
    ) => setUsageInsightsStateCallback({
      daysOfData,
      showLongTermTrend,
      showShortTermTrend,
    }))

    return () => {
      usageInsights?.removeListener(setUsageInsightsStateCallback)
    }
  }, [usageInsights, userSession?.isSessionActive])

  /**
   * Set usage insights ready when ready (used for loading states)
   */
  useEffect(() => {
    if (!usageInsightsState || !usageInsights) return

    setIsUsageInsightsReady(true)
  }, [usageInsightsState, usageInsights])

  /**
   * Identify the user when their gadgets list changes.
   */
  useEffect(() => {
    if (!window?.analytics || !gadgetsList) return

    window.analytics.identify(
      // Leaving the user identifier undefined prevents it from being overwritten
      undefined,
      { hardwareIds: gadgetsList?.map(({ hardwareId }) => hardwareId) },
    )
  }, [gadgetsList?.length])

  // Setup the device connection sdkContext interface
  const values: DeviceConnectionContextType = {
    ...state,
    isBluetoothAvailable: getIsDebugMode() ? () => Promise.resolve(true)
      : Chai.isBluetoothAvailable,
    setCurrentHardwareId,
    toggleCloudSyncOptIn,
  }

  // Finally, return the interface that we want to expose to our other components
  return (
    <DeviceConnectionContext.Provider value={values}>
      {children}
    </DeviceConnectionContext.Provider>
  )
}
