import React, { useState, forwardRef } from 'react'
import { Button } from 'components/Button'
import omit from 'lodash/fp/omit'
import styles from './InputField.module.scss'
import { InputProps } from './types'
import { classNames } from 'utils/css'

export const Input = forwardRef<any, InputProps>((props, ref) => {
  if (props.textarea === true) {
    const { className } = props

    const textareaClasses = classNames(
      styles.textarea,
      className,
    )

    return (
      <textarea
        {...omit('textarea', props)}
        className={textareaClasses}
        ref={ref}
      />
    )

  } else {
    const { className, disabled, secure, type } = props
    const SECURE_TYPE = 'password'

    const [inputType, setInputType] = useState<string>(() => {
      if (secure) {
        return SECURE_TYPE
      } else {
        return type
      }
    })

    const isSecure = inputType === SECURE_TYPE
    const toggleVisibility = (e) => {
      e.preventDefault()
      if (isSecure) {
        setInputType(type)
      } else {
        setInputType(SECURE_TYPE)
      }
    }

    const inputClasses = classNames(
      styles.input,
      secure && styles.secure,
      className,
    )

    return (
      <>
        <input
          {...omit(['secure', 'textarea', 'type'], props)}
          type={inputType}
          className={inputClasses}
          ref={ref}
        />
        {secure && !disabled && (
          <Button
            icon={isSecure ? 'icon/16x16/eye_outlined' : 'icon/16x16/eye_hide'}
            title={isSecure ? 'Show' : 'Hide'}
            accessibilityLabel={isSecure ? 'Show' : 'Hide'}
            className={styles.showHideButton}
            disabled={disabled}
            onClick={(e) => toggleVisibility(e)}
            type='button'
            size='small'
          />
        )}
      </>
    )
  }
})

Input.displayName = 'Input'
