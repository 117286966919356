export enum AcceptedCountryCodes {
  CA = 'CA',
  GB = 'GB',
  KW = 'KW',
  US = 'US',
}

export const acceptedCountryCodesSet = new Set<string>(
  Object.values(AcceptedCountryCodes),
)
