import React, { FC, useState } from 'react'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { Text } from 'components/Text'
import { Modal, ModalProps } from '../Modal'
import { InputField } from 'components/InputField'
import { bugsnagClient } from 'utils/bugsnag'
import { PrivacyPolicyLink } from 'components/Links'
import { ThankYou } from 'modules/Home/Feedback/components/ThankYou'

const MAX_RESPONSE_LENGTH = 1024

export const feedbackModalData = {
  heading: 'Give feedback',
  privacyPolicy: 'To help Juul Labs address your feedback, the app will send technical details about your app and paired devices. Learn more about our %{privacyPolicy}.',
  prompt: 'How could the app be more helpful to you?',
  submitButton: 'Submit',
  textareaLabel: 'Leave your comments here',
}

export type FeedbackModalForm = {
  userResponse: string
}

export interface FeedbackModalProps extends ModalProps {
  onSubmitFeedback(values: FeedbackModalForm): void
}

export const FeedbackModal: FC<FeedbackModalProps> = ({
  onSubmitFeedback,
  ...rest
}) => {
  const formId = 'user-feedback-form'
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: { userResponse: '' },
    onSubmit: async(values: FeedbackModalForm, { setSubmitting }) => {
      try {
        await onSubmitFeedback(values)
      } catch (e) {
        bugsnagClient?.notify?.({
          message: `${e}`,
          name: 'submit_user_feedback_failed',
        })
      } finally {
        setSubmitting(false)
        setIsSubmitted(true)
      }
    },
    validationSchema: object({
      userResponse: string()
        .required()
        .max(MAX_RESPONSE_LENGTH),
    }),
  })

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    values,
  } = formik

  return (
    <Modal
      overlay={isSubmitted && (
        <ThankYou padding={{ left: 'baseLoose', right: 'baseLoose' }} />
      )}
      primaryButton={{
        content: feedbackModalData.submitButton,
        disabled: !isValid || !dirty,
        form: formId,
        loading: isSubmitting,
        type: 'submit',
      }}
      {...rest}
    >
      <form
        id={formId}
        onSubmit={handleSubmit}>
        <Text
          align='left'
          margin={{ bottom: 'baseLoose' }}
          message={{ content: feedbackModalData.heading }}
          typography='bodyBigBold'
        />
        <Text
          align='left'
          margin={{ bottom: 'baseLoose' }}
          message={{ content: feedbackModalData.prompt }}
          typography='bodyBase'
        />
        <InputField
          form={formId}
          label={feedbackModalData.textareaLabel}
          maxLength={MAX_RESPONSE_LENGTH}
          name='userResponse'
          onBlur={handleBlur}
          onChange={handleChange}
          textarea
          value={values.userResponse}
        />
        <Text
          align='left'
          margin={{ top: 'baseLoose' }}
          message={{ content: feedbackModalData.privacyPolicy }}
          typography='bodyBase'
          values={{ privacyPolicy: <PrivacyPolicyLink key='privacyPolicyLink' /> }}
        />
      </form>
    </Modal>
  )
}
