import React, { FC, useState, useEffect, useRef } from 'react'
import { format } from 'date-fns'
import { useRouter } from 'next/router'
import { useSessionStorageValue, useSessionStorage } from 'hooks'
import styles from './ConsoleLogsDisplay.module.scss'
import { Box } from 'components/Box'
import { SlidingTabs } from 'components/SlidingTabs'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import {
  CONSOLE_LOGS_KEY,
  initialConsoleLogs,
  orderedLogLevels,
  CONSOLE_LOGS_DEBUG,
  initialDebugState,
} from '../../config'
import { StoredConsoleLogs, ConsoleAction } from '../../types'
import { Toggle } from '@juullabs/react-components'
import { getLogLevelTabs } from '../../utils'

// todo use react-window for list construction

export const ConsoleLogsDisplay: FC = () => {
  // refs
  const logListRef = useRef(null)

  // router
  const router = useRouter()

  // session storage
  const [
    isDebugMode,
    setIsDebugMode,
  ] = useSessionStorage<boolean>(CONSOLE_LOGS_DEBUG, initialDebugState)

  // tabs for the slider
  const levelTabs = getLogLevelTabs(
    [...orderedLogLevels].reverse(),
    isDebugMode ? ConsoleAction.Debug : ConsoleAction.Info,
  )

  // used to set level to filter logs by
  const [level, setLevel] = useState<ConsoleAction>(levelTabs[0])

  // state for current stored logs
  const [
    { levelIndex, logs },
    setConsoleLogs,
  ] = useState<StoredConsoleLogs>(initialConsoleLogs)

  // function to get and set logs
  const getLogs = () => {
    const consoleLogs = useSessionStorageValue<StoredConsoleLogs>(
      CONSOLE_LOGS_KEY,
      initialConsoleLogs,
    )
    setConsoleLogs(consoleLogs)
  }

  // handler to toggle debug mode
  const toggleIsDebugMode = () => {
    isDebugMode ? setIsDebugMode(false) : setIsDebugMode(true)
    router.reload()
  }

  // set logs on initial load
  useEffect(() => {
    getLogs()
  }, [])

  // auto scroll to the bottom of the log list
  useEffect(() => {
    if (logListRef.current) {
      logListRef.current.scrollTop = logListRef.current.scrollHeight
    }
  }, [level, logListRef.current])

  // reset level tabs when isDebugMode changes
  useEffect(() => {
    setLevel(levelTabs[0])
  }, [isDebugMode])

  return (
    <div className={styles.ConsoleLogsDisplay}>
      <Text
        align='left'
        color='green|base|100'
        message={{ content: 'Console Logs' }}
        tagName='h3'
        typography='subtitleSmall'
      />
      <Box padding={{ bottom: 'base', top: 'tight' }}>
        <SlidingTabs
          id='sdkLogLevels'
          onClickValue={(value: ConsoleAction) => setLevel(value)}
          values={levelTabs}
        />
      </Box>
      <ol className={styles.logList} ref={logListRef}>
        {levelIndex?.[level]?.map((logIndex) => (
          <li className={styles.logListItem} key={'log-' + logIndex}>
            <Text
              color='ink|base|50'
              message={{
                content: format(
                  logs[logIndex].timestamp,
                  'P – HH:mm:ss.SSS',
                ),
              }}
              padding={{ bottom: 'extraTight' }}
              typography='captionSmall'
            />
            <Text
              className={styles.logText}
              color='ink|base|75'
              message={{ content: logs[logIndex].log.toString() }}
              tagName='p'
              typography='bodySmall'
            />
          </li>
        ))}
        <div className={styles.anchor} />
      </ol>
      <Box margin={{ top: 'baseLoose' }} className={styles.actionWrap}>
        <Button
          content='Refresh Logs'
          onClick={getLogs}
          size='small'
          outline
        />
        <Text
          color='ink|base|75'
          message={{ content: 'Console logs do not update automatically.' }}
          typography='bodySmallBold'
          padding={{ left: 'tight' }}
        />
      </Box>
      <Box margin={{ top: 'baseLoose' }} className={styles.actionWrap}>
        <Toggle
          ariaLabel='toggle debug mode'
          onClick={toggleIsDebugMode}
          checked={isDebugMode}
          showIcons
        />
        <Text
          color='ink|base|75'
          message={{ content: 'Enable Debug Mode: Page will refresh.' }}
          padding={{ left: 'tight' }}
          typography='bodySmallBold'
        />
      </Box>
    </div>
  )
}
