import { useContext } from 'react'
import { AnalyticsContext } from '.'

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (context === undefined) {
    throw new Error(
      '`useAnalytics` hook must be used within a `AnalyticsProvider` component',
    )
  }

  return context
}
