import React, { FC, useState, useMemo } from 'react'
import { DailyPuffTrendline } from '../../charts/DailyPuffTrendline'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import { description, title } from './localization'
import { WebAppInsights, Event } from 'analytics-events'

// config
const TRENDLINE_CHART_HEIGHT = 240

// types
export type PuffsSoFarProps = Omit<InsightsCardProps, 'title'>

export const PuffsSoFar: FC<PuffsSoFarProps> = ({ loading, ...rest }) => {
  // state
  const [hasAverage, setHasAverage] = useState<boolean>(null)
  const [isValid, setIsValid] = useState<boolean>(null)
  const [puffCount, setPuffCount] = useState(0)

  // events
  const puffSoFarEvent = useMemo<Event>(() => {
    if (isValid === null || hasAverage === null) return null

    return WebAppInsights.PuffsSoFarEvent({
      chartState: hasAverage
        ? WebAppInsights.PuffsSoFarLineChartState.HasAverageLine
        : WebAppInsights.PuffsSoFarLineChartState.NoAverageLine,
      isValid: isValid,
    })
  }, [isValid, hasAverage])

  return (
    <InsightsCard
      cardEvent={puffSoFarEvent}
      isValid={isValid}
      title={title}
      loading={loading || isValid === null}
      {...rest}
    >
      <Text
        margin={{ bottom: 'baseLoose' }}
        message={description}
        tagName='p'
        typography='bodyBig'
        values={{ puffs: puffCount }}
      />
      <DailyPuffTrendline
        height={TRENDLINE_CHART_HEIGHT}
        setHasAverage={setHasAverage}
        setIsValid={setIsValid}
        setPuffCount={setPuffCount}
      />
    </InsightsCard>
  )
}
