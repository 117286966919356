import Routes from 'types/routes'
import { WebAppNavigation } from 'analytics-events'
import {
  NavViews,
  NavViewName,
  NavIcons,
  NavButtonProps,
} from './types'

export const NavViewArray: Array<NavViewName> = [
  'Home', 'UsageInsights', 'Settings',
]

export const Home: NavButtonProps = {
  activeIcon: NavIcons.JuulActive,
  content: 'Home',
  idleIcon: NavIcons.JuulIdle,
  linkClicked: WebAppNavigation.PageName.Home,
  route: Routes.Home,
}

export const Settings: NavButtonProps = {
  activeIcon: NavIcons.SettingsActive,
  content: 'Settings',
  idleIcon: NavIcons.SettingsIdle,
  linkClicked: WebAppNavigation.PageName.Settings,
  route: Routes.Settings,
}

export const UsageInsights: NavButtonProps = {
  activeIcon: NavIcons.UsageInsightsActive,
  content: 'Usage Insights',
  idleIcon: NavIcons.UsageInsightsIdle,
  linkClicked: WebAppNavigation.PageName.UsageInsights,
  route: Routes.UsageInsights,
}

export default {
  Home,
  Settings,
  UsageInsights,
} as NavViews

