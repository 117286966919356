import React, { FC } from 'react'
import styles from './RemoteConfigItem.module.scss'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'
import Chai from 'types/chai'

export type RemoteConfigItemProps = {
  item: Chai.remoteconfig.RemoteConfigItem
  manager: Chai.remoteconfig.RemoteConfigManager
}

export const RemoteConfigItem: FC<RemoteConfigItemProps> = ({ manager, item }) => {
  let editor: React.JSX.Element
  switch (item.type) {
  case Chai.remoteconfig.TYPE_BOOLEAN:
    editor = (
      <BooleanEditor
        value={item.value == 'true'}
        update={async(value) => manager.setBoolean(item, value)}
      />
    )
    break
  case Chai.remoteconfig.TYPE_DOUBLE:
    editor = (
      <DoubleEditor
        value={+item.value}
        update={async(value) => manager.setDouble(item, value)}
      />
    )
    break
  case Chai.remoteconfig.TYPE_LONG:
    editor = (
      <LongEditor
        value={BigInt(item.value)}
        update={async(value) => manager.setLong(item, value)}
      />
    )
    break
  default:
    editor = (
      <StringEditor
        value={item.value}
        update={async(value) => manager.setString(item, value)}
      />
    )
    break
  }

  return (
    <div className={styles.RemoteConfigItem}>
      <Text
        message={{ content: item.name }}
        tagName='h4'
        typography='h4'
      />
      <Text
        message={{ content: item.description ?? 'No parameter description' }}
        color={item.description ? 'ink|base|100' : 'ink|base|50'}
        typography='bodyBase'
      />
      <ValueDisplay value={item.value} />
      <IsOverwritten
        value={item.isOverwrote}
        toggle={async() => { await manager.toggleOverwrote(item) }}
      />
      {item.isOverwrote ? editor : null}
    </div>
  )
}

type ValueDisplayProps = { value: string }
const ValueDisplay: FC<ValueDisplayProps> = ({ value }) => {
  const className = value.includes('\n') ? styles.ValueDisplayMultiLine : styles.ValueDisplaySingleLine
  return (
    <div className={className}>
      <Text
        message={{ content: 'Current Value:' }}
        typography='subtitleBase'
      />
      <Text
        message={{ content: value }}
        typography='bodyBase'
      />
    </div>
  )
}

type IsOverwrittenProps = {
  toggle: () => Promise<void>
  value: boolean
}
const IsOverwritten: FC<IsOverwrittenProps> = ({ value, toggle }) => (
  <div className={styles.Editor}>
    <Text
      message={{ content: 'Is overwritten:' }}
      typography='subtitleBase'
    />
    <Text
      message={{ content: `${value}` }}
      typography='bodyBase'
    />
    <div style={{ flexGrow: 1 }} />
    <Button content='Toggle' onClick={toggle} primary='black' />
  </div>
)

type BooleanEditorProps = {
  update: (value: boolean) => Promise<void>
  value: boolean
}
const BooleanEditor: FC<BooleanEditorProps> = ({ value, update }) => {
  const enable = async() => { await update(true) }
  const disable = async() => { await update(false) }
  return (
    <div className={styles.Editor}>
      <Button content={'True'} onClick={enable} disabled={value} />
      <Button content={'False'} onClick={disable} disabled={!value} />
    </div>
  )
}

type DoubleEditorProps = {
  update: (value: number) => Promise<void>
  value: number
}
const DoubleEditor: FC<DoubleEditorProps> = ({ value, update }) => {
  const onChange = async(element: HTMLInputElement) => {
    const value = element.valueAsNumber ? element.valueAsNumber : 0
    await update(value)
  }
  return (
    <div className={styles.Editor}>
      <input
        type='number'
        defaultValue={value}
        onChange={e => onChange(e.target)}
      />
    </div>
  )
}

type LongEditorProps = {
  update: (value: BigInt) => Promise<void>
  value: BigInt
}
const LongEditor: FC<LongEditorProps> = ({ value, update }) => {
  const onChange = async(element: HTMLInputElement) => {
    const value = element.valueAsNumber ? element.valueAsNumber : 0
    await update(BigInt(value.toFixed(0)))
  }
  return (
    <div className={styles.Editor}>
      <input
        type='number'
        defaultValue={value.toString()}
        onChange={e => onChange(e.target)}
      />
    </div>
  )
}


type StringEditorProps = {
  update: (value: string) => Promise<boolean>
  value: string
}
const StringEditor: FC<StringEditorProps> = ({ value, update }) => {
  const onChange = async(element: HTMLTextAreaElement) => {
    await update(element.value)
  }
  return (
    <div className={styles.Editor}>
      <Textarea
        className={styles.EditorTextArea}
        name='text-editor'
        defaultValue={value}
        onChange={e => onChange(e.target)}
      />
    </div>
  )
}
