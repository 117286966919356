import { TextFormats } from 'components/Text'

export const title = 'Activity Updates'

export type TrendPreview = {
  accessibilityLabel: string
  body: TextFormats
  heading: TextFormats
}

export const shortTrendPreview: TrendPreview = {
  accessibilityLabel: 'Short Term Trend',
  body: { content: 'You have vaped %{trend} last week.' },
  heading: { content: 'This Week' },
}

export const longTrendPreview: TrendPreview = {
  accessibilityLabel: 'Long Term Trend',
  body: { content: 'You have vaped %{trend} usual.' },
  heading: { content: 'Past %{weeks} Weeks' },
}
