
/**
 * This hook should be installed into the ConX SDK analytics pipeline to consume emitted events.
 * This is plumbing - DO NOT USE this to send analytics - use `track` instead.
 */
export const segmentAnalyticsHook = (
  eventName: string,
  properties: Record<string, any> = {},
) => {
  if (!window?.analytics) return
  window.analytics.track(eventName, properties)
}

export const segmentAnalyticsIdentify = (guid: string) => {
  if (!window?.analytics) return
  window.analytics.identify(guid)
}

export const segmentAnalyticsIdentifyReset = () => {
  if (!window?.analytics) return
  window.analytics.reset()
}

export const segmentAnalyticsPage = (url: string) => {
  if (!window?.analytics) return
  window.analytics.page(url)
}
