import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'

export interface PodPictographProps extends BoxProps {
  height: number
  setMaxPuffsPerPod(maxPuffsPerPod: number): void
  setMinPuffsPerPod(minPuffsPerPod: number): void
  setPodsPerDay(podsPerDay: number): void
}

export const PodPictograph: FC<PodPictographProps> = ({
  height,
  setPodsPerDay,
  setMinPuffsPerPod,
  setMaxPuffsPerPod,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachPodConsumptionAdapter(
      currentCanvas,
      (podsPerDay: number, minPuffsPerPod: number, maxPuffsPerPod: number) => {
        setPodsPerDay(podsPerDay)
        setMinPuffsPerPod(minPuffsPerPod)
        setMaxPuffsPerPod(maxPuffsPerPod)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
