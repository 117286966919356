import { MouseEventHandler } from 'react'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'

export const useTrackClick = <T>(
  action?: MouseEventHandler<T>,
  clickEvent?: Event,
) => {
  const { reportEvent } = useAnalytics()

  const onClickWithTracking: MouseEventHandler<T> = (e) => {
    if (action) action(e)
    if (clickEvent) reportEvent(clickEvent)
  }

  return { onClickWithTracking }
}
