import Chai from 'types/chai'
import { Event, EventType } from '@juullabs/analytics-multiplatform'

export const legacyTrack = (
  analytics: Chai.Analytics,
  eventName: string,
  eventType: EventType,
  properties: Record<string, any> = {},
) => {
  if (!analytics) {
    return
  }

  const event = new Event({
    eventName: eventName,
    properties,
    type: eventType,
  })

  try {
    trackChaiEvent(analytics, event)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to track event', e)
  }
}

export const trackChaiEvent = (
  analytics: Chai.Analytics,
  event: Event,
) => analytics.legacyTrack(event)

export const parseRoute = (pathname: string) => {
  let screen = pathname.slice(1)
  if (!screen) screen = 'home'
  return screen
}
