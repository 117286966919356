import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { TrendDirection } from '../../config'

const MICROCHART_SIZE = 56

export interface WeeklyPuffsMicrochartProps extends BoxProps {
  setIsValid(isValid: boolean): void
  setTrend(trend: TrendDirection): void
}

export const WeeklyPuffsMicrochart: FC<WeeklyPuffsMicrochartProps> = ({
  setIsValid,
  setTrend,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachShortTermTrendMicro(
      currentCanvas,
      (trend: TrendDirection, isValid: boolean) => {
        setTrend(trend)
        setIsValid(isValid)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${MICROCHART_SIZE}px`,
          width: `${MICROCHART_SIZE}px`,
        }}
      />
    </Box>
  )
}
