import { useContext } from 'react'
import { DeviceConnectionContext } from './DeviceConnectionProvider'

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abstracting
// them from the fact that we are using React's context, but we also skip some imports.
export const useDeviceConnection = () => {
  const context = useContext(DeviceConnectionContext)

  if (context === undefined) {
    throw new Error(
      '`useDeviceConnection` hook must be used within a `DeviceConnectionProvider` component',
    )
  }

  return context
}
