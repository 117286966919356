import {
  addDays,
  addMinutes,
  addSeconds,
  addMilliseconds,
  getHours,
  parseISO,
  setHours,
  differenceInDays,
  differenceInMilliseconds,
  isBefore,
  subSeconds,
} from 'date-fns'
import {
  GenerateRandomPuffsType,
  GeneratePuffsType,
  GeneratePatternOfPuffsType,
  HandleFormSubmitType,
  Pod,
  Puff,
  CSVMode,
} from '../types'
import { TabValues } from '../types'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { useRemoveKeyLocalStorage } from 'hooks'
import { LONG_TERM_TREND_SEEN, SHORT_TERM_TREND_SEEN } from '../../../config'

export const useMockPuffs = () => {
  const { usageInsightsManager } = useDeviceConnection()

  /**
   * Generate a random number between min and max,
   * where probability favors the minimum value provided.
   *
   * @param min - number
   * @param max - number
   *
   * @returns - a random number between min and max
   */
  const getRandomNumberFavoringMin = (min: number, max: number) => (
    Math.floor(Math.abs(Math.random() - Math.random()) * (1 + max - min) + min)
  )

  /**
   * Generate an array of unique puff objects,
   * either a single puff at a timestamp or
   * multiple puffs evenly distributed between two timestamps.
   *
   * @param GeneratePuffsType - object containing start/end timestamps,
   * number of puffs, and hardware and pod id's
   *
   * @returns - array of timestamped puff objects
   */
  const generatePuffs = ({
    startTimestamp,
    endTimestamp,
    puffCount,
    hardwareId,
    podId,
  }: GeneratePuffsType) => {
    const start = parseISO(startTimestamp)
    if (puffCount === 1) return [{ hardwareId, podId, timestamp: start }]

    const end = parseISO(endTimestamp)
    const duration = differenceInMilliseconds(end, start)
    const interval = Math.floor(duration / puffCount)
    const puffs = []

    let timestamp = start
    for (let count = puffCount; count > 0; count--) {
      timestamp = addMilliseconds(timestamp, interval)
      puffs.push({ hardwareId, podId, timestamp })
    }

    return puffs
  }

  /**
   * Generate an array of unique puff objects,
   * following the provided pattern of puffs per day,
   * will only generate puffs during daytime hours to simulate actual usage.
   *
   * @param GeneratePuffsType - object containing start/end timestamps,
   * patten of puffs, and hardware and pod id's
   *
   * @returns - array of timestamped puff objects
   */
  const generatePatternOfPuffs = ({
    startTimestamp,
    endTimestamp,
    hardwareId,
    podId,
    pattern,
  }: GeneratePatternOfPuffsType) => {
    const start = parseISO(startTimestamp)
    const end = parseISO(endTimestamp)
    const duration = differenceInDays(end, start)
    const patternArray = pattern.split(',').map(Number)
    const patternLength = patternArray.length
    const puffs = []

    let timestamp = start
    let puffsPerDay, interval
    for (let day = 0; day < duration; day++) {
      puffsPerDay = patternArray[day % patternLength]
      interval = Math.floor(57600 / puffsPerDay)
      timestamp = setHours(timestamp, 6)

      for (let dayCount = puffsPerDay; dayCount > 0; dayCount--) {
        timestamp = addSeconds(timestamp, interval)
        puffs.push({ hardwareId, podId, timestamp })
      }

      timestamp = addDays(timestamp, 1)
    }

    return puffs
  }

  /**
   * Generate an array of unique puff objects,
   * randomized over a period of time,
   * will only generate puffs during daytime hours to simulate actual usage.
   *
   * @param GeneratePuffsType - object containing start/end timestamps,
   * number of puffs, and hardware and pod id's
   *
   * @returns - array of timestamped puff objects
   */
  const generateRandomPuffs = ({
    startTimestamp,
    endTimestamp,
    hardwareId,
    podId,
  }: GenerateRandomPuffsType) => {
    const start = parseISO(startTimestamp)
    const end = parseISO(endTimestamp)
    const puffs = []

    let timestamp = start
    let interval
    while (isBefore(timestamp, end)) {
      if (getHours(timestamp) < 6) timestamp = setHours(timestamp, 6)
      interval = getRandomNumberFavoringMin(1, 30)
      timestamp = addMinutes(timestamp, interval)
      puffs.push({ hardwareId, podId, timestamp })
    }

    return puffs
  }

  /**
   * Subtracts one second from timestamp
   *
   * @param timestamp - string, ISO date
   *
   * @returns - timestamp formatted as a date
   */
  const podTimestamp = (timestamp: string) => subSeconds(parseISO(timestamp), 1)

  /**
   * Clear puff data with chai
   */
  const clearPuffData = () => usageInsightsManager.clearPuffs()

  /**
   * Post pod with chai
   */
  const postPod = ({ isAuthentic, podId, recipeId, timestamp }: Pod) => (
    usageInsightsManager.injectPodEvent(podId, isAuthentic, recipeId, timestamp)
  )

  /**
   * Post puffs with chai
   */
  const postPuffs = (puffs: Puff[]) => (
    usageInsightsManager.injectPuffEvents(puffs)
  )

  /**
   * Handle CSV Mode Import/Export of puff data
   */
  const handleCSVMode = (csvMode: CSVMode) => {
    switch (csvMode) {
    case CSVMode.Import:
      usageInsightsManager.importCsv()
      break
    case CSVMode.Export:
      usageInsightsManager.exportCsv()
      break
    }
  }

  /**
   * Handles form submission
   *
   */
  const handleFormSubmit = ({
    csvMode,
    endTimestamp,
    hardwareId,
    mockPods,
    puffCount,
    pattern,
    pod,
    startTimestamp,
    tab,
  }: HandleFormSubmitType) => {
    const { podId, recipeId } = mockPods[pod]
    const args = { endTimestamp, hardwareId, podId, startTimestamp }

    if (tab === TabValues.RemovePuffs) {
      clearPuffData()
      useRemoveKeyLocalStorage(LONG_TERM_TREND_SEEN)()
      useRemoveKeyLocalStorage(SHORT_TERM_TREND_SEEN)()
    } else if (tab === TabValues.CSV) {
      handleCSVMode(csvMode)
    } else {
      const pod: Pod = {
        isAuthentic: true,
        podId,
        recipeId,
        timestamp: podTimestamp(startTimestamp),
      }
      let puffs: Puff[]

      switch (tab) {
      case TabValues.AddPuffs:
        puffs = generatePuffs({ ...args, puffCount })
        break
      case TabValues.PatternOfPuffs:
        puffs = generatePatternOfPuffs({ ...args, pattern })
        break
      case TabValues.RandomPuffs:
        puffs = generateRandomPuffs(args)
        break
      }

      postPod(pod)
      postPuffs(puffs)
    }
  }

  return handleFormSubmit
}
