import React, { FC } from 'react'
import Head from 'next/head'
import {
  getAppTitle,
  getPageTitle,
  getFaviconEnv,
  FaviconEnv,
} from './utils'
import { useRouter } from 'next/router'

/**
 * for favicon documentation see:
 * https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
*/

const FAVICON_VERSION_NUMBER = '1'

export const AppHead: FC = () => {
  const { pathname } = useRouter()
  const faviconEnv = getFaviconEnv()
  const appTitle = getAppTitle()
  const faviconVersion = faviconEnv.concat('-', FAVICON_VERSION_NUMBER)

  return (
    <Head>
      {/* Font */}
      <link rel='preload' href='/fonts/dogpatch.woff2' as='font'
        type='font/woff2' crossOrigin='anonymous'
      />
      <link rel='preload' href='/fonts/dogpatch.woff' as='font'
        type='font/woff' crossOrigin='anonymous'
      />

      {/* Title */}
      <title>{getPageTitle(pathname)}</title>

      {/* Favicon */}
      <link rel='icon' sizes='48x48' href={faviconEnv === FaviconEnv.Prod
        ? `/favicon.ico?v=${faviconVersion}`
        : `/favicon-${faviconEnv}.ico?v=${faviconVersion}`}
      />
      <link
        rel='icon' type='image/svg+xml'
        href={`/favicon/${faviconEnv}/icon.svg?v=${faviconVersion}`}
      />
      <link rel='apple-touch-icon' sizes='180x180'
        href={`/favicon/${faviconEnv}/apple-touch-icon.png?v=${faviconVersion}`}
      />
      <link rel='manifest'
        href={`/favicon/${faviconEnv}/site.webmanifest?v=${faviconVersion}`}
      />
      <meta name='apple-mobile-web-app-title' content={appTitle} />
      <meta name='application-name' content={appTitle} />
      <meta name='theme-color' media='(prefers-color-scheme: light)' content='black' />
      <meta name='theme-color' media='(prefers-color-scheme: dark)' content='white' />

      {/* Block Mobile Zoom */}
      <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
    </Head>
  )
}
