import React, { FC, useState, useMemo } from 'react'
import { Text } from 'components/Text'
import { title, primaryHeading, secondaryHeading, legend } from './localization'
import { TrendDirection, trendWording } from '../../config'
import { WeeklyPuffsBarchart } from '../../charts/WeeklyPuffsBarchart'
import { WeeklyPuffsGroupedBarchart } from '../../charts/WeeklyPuffsGroupedBarchart'
import { ActivityUpdatePage } from '../../components'
import { WebAppScreens, WebAppInsights, Event } from 'analytics-events'

// short term trends config
const WEEKLY_PUFFS_BARCHART_HEIGHT = 168
const WEEKLY_PUFFS_GROUPED_BARCHART_HEIGHT = 288
const TREND_THRESHOLD = '10%'

export const ShortTermTrends: FC = () => {
  // state
  const [isBarChartValid, setIsBarChartValid] = useState<boolean>(null)
  const [isGroupedBarChartValid, setIsGroupedBarChartValid] = useState<boolean>(null)
  const [trend, setTrend] = useState<TrendDirection>(null)

  // variables
  const isValid = isBarChartValid && isGroupedBarChartValid
  const isLoading = isBarChartValid === null || isGroupedBarChartValid === null

  // memoized events
  const screenEvent = useMemo(() => WebAppScreens.ShortTermTrendsEvent(), [])
  const shortTermTrendEvent = useMemo<Event>(() => {
    if (isLoading) return null
    return WebAppInsights.ShortTermTrendExpandedEvent({ isValid })
  }, [isBarChartValid, isGroupedBarChartValid])

  return (
    <ActivityUpdatePage
      backButton={{ clickEvent: WebAppScreens.ShortTermTrends.ClickBack() }}
      isValid={isValid}
      loading={isLoading}
      pageStateEvent={shortTermTrendEvent}
      screenEvent={screenEvent}
    >
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'tight' }}
        message={title}
        tagName='h2'
        typography='subtitleSmall'
      />
      <Text
        margin={{ bottom: 'baseLoose' }}
        message={primaryHeading}
        tagName='p'
        typography='bodyBigBold'
        values={{ trend: trendWording[trend] }}
      />
      <WeeklyPuffsBarchart
        height={WEEKLY_PUFFS_BARCHART_HEIGHT}
        setIsValid={setIsBarChartValid}
        setTrend={setTrend}
      />
      <Text
        margin={{ bottom: 'tight', top: 'baseLoose' }}
        message={secondaryHeading}
        tagName='h3'
        typography='bodyBaseBold'
      />
      <WeeklyPuffsGroupedBarchart
        height={WEEKLY_PUFFS_GROUPED_BARCHART_HEIGHT}
        setIsValid={setIsGroupedBarChartValid}
      />
      <Text
        color='ink|base|50'
        margin={{ top: 'baseLoose' }}
        message={legend}
        tagName='p'
        typography='captionBase'
        values={{ threshold: TREND_THRESHOLD }}
      />
    </ActivityUpdatePage>
  )
}
