export enum GadgetConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Intermediate = 'intermediate',
}

export enum InterfaceType {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Reconnecting = 'reconnecting',
  Unauthenticated = 'unauthenticated'
}
