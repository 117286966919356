import { useContext } from 'react'
import { AppProviderContext } from './AppProvider'

export const useApp = () => {
  const context = useContext(AppProviderContext)

  if (context === undefined) {
    throw new Error(
      '`useApp` hook must be used within a `AppProvider` component',
    )
  }

  return context
}
