import {
  CSVMode,
  Fieldset,
  InstructionsType,
  MockPodsType,
  MockPuffsInput,
  NotificationsType,
  PodOptions,
  SubmitButton,
  TabValues,
} from './types'
import { NotificationIcons } from 'components/Notification'

const MockPods: MockPodsType = {
  [PodOptions.AutumnTobacco]: {
    podId: '13579111313',
    recipeId: BigInt(786206),
  },
  [PodOptions.BlackcurrantTobacco]: {
    podId: '13579111314',
    recipeId: BigInt(2359070),
  },
  [PodOptions.CrispMenthol]: {
    podId: '13579111315',
    recipeId: BigInt(458526),
  },
  [PodOptions.PolarMenthol]: {
    podId: '13579111316',
    recipeId: BigInt(589598),
  },
  [PodOptions.RubyMenthol]: {
    podId: '13579111317',
    recipeId: BigInt(851742),
  },
  [PodOptions.SummerMenthol]: {
    podId: '13579111318',
    recipeId: BigInt(982814),
  },
  [PodOptions.VirginiaTobacco]: {
    podId: '13579111319',
    recipeId: BigInt(327454),
  },
}

const Notifications: NotificationsType = {
  icon: {
    [TabValues.RemovePuffs]: NotificationIcons.AlertFilledRed,
    default: null,
  },
  message: {
    [TabValues.AddPuffs]: `Choose a pod, then either add one puff on the
    time and date selected, or add multiple puffs that will be evenly spaced
    between the selected time periods. Best used for shorter periods of time
    (less than a day).`,
    [TabValues.PatternOfPuffs]: `Choose a pod, then add a pattern to run
    between selected dates. The numbers in the pattern represent number of
    puffs per day on rotating days (ex. 20,15 = 20 puffs on day 1, 15 puffs
    on day 2, repeating until the end date).`,
    [TabValues.RandomPuffs]: `Choose a pod and start/end dates to generate
    randomized mock puff data during daylight hours, mimicking average usage.`,
    [TabValues.RemovePuffs]: 'Clicking the button below will erase all puff data.',
    [TabValues.CSV]: `Data import can take several minutes. Check the console
    to see if values are still being imported.`,
  },
  title: {
    [TabValues.RemovePuffs]: 'Caution',
    default: '',
  },
  type: {
    [TabValues.RemovePuffs]: 'warning',
    [TabValues.CSV]: 'warning',
    default: 'neutral',
  },
}

const TabValuesArray: TabValues[] = [
  TabValues.AddPuffs,
  TabValues.PatternOfPuffs,
  TabValues.RandomPuffs,
  TabValues.RemovePuffs,
  TabValues.CSV,
]

const Required = 'Field is required.'

const Max = 'Must be earlier than the current date and time.'

const PuffCount: MockPuffsInput = {
  accessibilityLabel: 'Enter the desired number of puffs.',
  error: {
    min: 'Must be greater than or equal to one.',
    required: Required,
  },
  label: 'number of puffs',
}

const Pattern: MockPuffsInput = {
  accessibilityLabel: 'Enter repeating pattern of daily puffs.',
  datalist: [
    '20,15',
    '30,50,20',
    '25,50,30,35',
  ],
  error: {
    invalid: 'Must follow pattern: ex. 25,50,30,35',
    required: Required,
  },
  label: 'select or customize a pattern',
  placeholder: 'ex. 25,50,30,35',
}

const EndTimestamp: MockPuffsInput = {
  accessibilityLabel: 'Enter the ending date and time.',
  error: {
    max: Max,
    min: 'Must be later than the start date and time.',
    required: Required,
  },
  label: 'end date',
}

const StartTimestamp: MockPuffsInput = {
  accessibilityLabel: 'Enter the starting date and time.',
  error: {
    max: Max,
    required: Required,
  },
  label: 'start date',
}

const Submit: SubmitButton = {
  [TabValues.AddPuffs]: 'add puffs',
  [TabValues.PatternOfPuffs]: 'add a pattern of puffs',
  [TabValues.RandomPuffs]: 'add random puffs',
  [TabValues.RemovePuffs]: 'remove all puff data',
  [TabValues.CSV]: 'start',
}

export const CSVModes: Fieldset<CSVMode> = {
  accessibilityLabel: 'Choose a CSV import/export mode.',
  error: {
    invalid: 'invalid selection.',
    required: Required,
  },
  options: [
    CSVMode.Import,
    CSVMode.Export,
  ],
}

const podLabelMaker = (key: PodOptions) => (
  `– Pod ID: ${MockPods[key].podId}/Recipe ID: ${MockPods[key].recipeId}`
)

export const Pods: Fieldset<PodOptions> = {
  accessibilityLabel: 'Choose a mock pod.',
  error: {
    invalid: 'invalid selection.',
    required: Required,
  },
  labels: {
    [PodOptions.AutumnTobacco]: podLabelMaker(PodOptions.AutumnTobacco),
    [PodOptions.BlackcurrantTobacco]: podLabelMaker(PodOptions.BlackcurrantTobacco),
    [PodOptions.CrispMenthol]: podLabelMaker(PodOptions.CrispMenthol),
    [PodOptions.PolarMenthol]: podLabelMaker(PodOptions.PolarMenthol),
    [PodOptions.RubyMenthol]: podLabelMaker(PodOptions.RubyMenthol),
    [PodOptions.SummerMenthol]: podLabelMaker(PodOptions.SummerMenthol),
    [PodOptions.VirginiaTobacco]: podLabelMaker(PodOptions.VirginiaTobacco),
  },
  options: [
    PodOptions.AutumnTobacco,
    PodOptions.BlackcurrantTobacco,
    PodOptions.VirginiaTobacco,
    PodOptions.PolarMenthol,
    PodOptions.RubyMenthol,
    PodOptions.SummerMenthol,
    PodOptions.CrispMenthol,
  ],
}

const Instructions: InstructionsType = {
  csvMode: { default: 'CSV Mode:' },
  pattern: { default: 'Add a Pattern:' },
  pods: { default: 'Choose a Pod:' },
  puffCount: { default: 'Enter number of puffs to add:' },
  timestamps: {
    [TabValues.AddPuffs]: 'Select a Date and Time Range:',
    default: 'Select a Start and End Date:',
  },
}

export const MockPuffsData = {
  csvModes: CSVModes,
  endTimestamp: EndTimestamp,
  hardwareId: '1123581321345589144',
  instructions: Instructions,
  mockPods: MockPods,
  notifications: Notifications,
  pattern: Pattern,
  pods: Pods,
  puffCount: PuffCount,
  startTimestamp: StartTimestamp,
  submit: Submit,
  tabs: TabValuesArray,
  title: 'Mock Puffs',
}
