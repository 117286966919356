import React, { FC } from 'react'
import { classNames } from 'utils/css'
import styles from './Fieldset.module.scss'
import { CSVMode, PodOptions, FieldsetProps } from '../../types'

/* eslint-disable react/display-name */ // Component name specified in specific exports
const FieldsetFactory = <T extends string>(): FC<FieldsetProps<T>> => ({
  accessibilityLabel,
  error,
  errorText,
  labels,
  name,
  onBlur,
  onChange,
  options,
  selected,
  ...rest
}) => (
  <div>
    <fieldset
      aria-labelledby={accessibilityLabel}
      className={classNames(
        styles.fieldset,
        error && styles.error,
      )}
      {...rest}>
      {options.map((option) => (
        <div key={option} className={styles.option}>
          <input
            id={option}
            name={name}
            type='radio'
            value={option}
            onChange={onChange}
            onBlur={onBlur}
            checked={option === selected}
          />
          <label htmlFor={option} className={styles.label}>
            {option}
            {labels && (
              <span className={styles.labelContext}>
                &nbsp;{labels[option]}
              </span>
            )}
          </label>
        </div>
      ))}
    </fieldset>
    {errorText && (
      <div className={styles.errorText}>
        {errorText}
      </div>
    )}
  </div>
)

export const CSVModeFieldset: FC<FieldsetProps<CSVMode>> = FieldsetFactory()
export const PodFieldset: FC<FieldsetProps<PodOptions>> = FieldsetFactory()
