import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { Link, LinkProps } from 'components/Link'

export const ReloadPageLink: FC<LinkProps> = (props) => {
  const router = useRouter()

  return (
    <Link
      accessibilityLabel='refresh page'
      onClick={router?.reload}
      type='button'
      rel='noreferrer'
      {...props}
    />
  )
}
