import React, { FC, PropsWithChildren, useRef, useEffect } from 'react'
import { Reoverlay } from 'reoverlay'
import styles from './ModalWrapper.module.scss'
import { classNames } from 'utils/css'

export type ModalWrapperProps = {
  className?: string
  onClose?(): void
  wrapperClassName?: string
}

export const ModalWrapper: FC<PropsWithChildren<ModalWrapperProps>> = ({
  children,
  className,
  onClose = () => Reoverlay.hideModal(),
  wrapperClassName,
}) => {
  const wrapperElement = useRef(null)

  const handleClickOutside = (e) => {
    if (e.target === wrapperElement.current) {
      onClose()
    }
  }

  useEffect(() => {
    const { body } = document
    body.classList.add('prevent-scroll')

    return () => {
      body.classList.remove('prevent-scroll')
    }
  }, [])

  return (
    <div
      className={classNames(styles.ModalWrapper, wrapperClassName)}
      onClick={handleClickOutside}
      ref={wrapperElement}
      role='presentation'
    >
      <div className={className} role='dialog'>
        {children}
      </div>
    </div>
  )
}
