import React, { FC, HTMLAttributes } from 'react'
import { classNames } from 'utils/css'
import styles from './JuulLoading.module.scss'
import { LoadingPlaceholder } from 'components/LoadingPlaceholder'
import { InverseJuulLogo } from './InverseJuulLogo'

export interface JuulLoadingProps extends HTMLAttributes<HTMLDivElement> {
  theme?: 'light' | 'dark'
}

export const JuulLoading: FC<JuulLoadingProps> = ({
  className,
  theme = 'light',
  ...rest
}) => (
  <div
    className={classNames(styles.JuulLoading, styles[theme], className)}
    role='presentation'
    {...rest}
  >
    <div className={styles.loader}>
      <div className={styles.image}>
        <InverseJuulLogo fill={theme === 'dark' ? 'black' : 'white'} />
      </div>
      <LoadingPlaceholder className={styles.animation} theme={theme} />
    </div>
  </div>
)
