import { useEffect, useState } from 'react'

/**
 * Use this hook to manage data concurrency. Any values that are attempted
 * to be stored are put through a delay timer. If a new value is not set until
 * the timer expires, the latest value is returned.
 *
 * If multiple new values are set before the expiration, only the last value
 * is selected and returned.
 *
 * @param value any value that needs to be stored
 * @param delay delay timer
 *
 * @returns latest value
 */
export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
    // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}
