import { useRouter } from 'next/router'
import React, { FC, createContext, useState, useEffect, PropsWithChildren } from 'react'
import { EventType, WebLoginRedirection } from '@juullabs/analytics-multiplatform'
import { Event } from 'analytics-events'
import Chai from 'types/chai'

import { legacyTrack, parseRoute, trackChaiEvent } from './utils'

import {
  AnalyticsContextType,
  EventContextPayload,
  LegacyEventProps,
  TrackClickEventProps,
} from './types'

import {
  segmentAnalyticsHook,
  segmentAnalyticsIdentify,
  segmentAnalyticsIdentifyReset,
  segmentAnalyticsPage,
} from './segment'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { bugsnagClient } from 'utils/bugsnag'
import Routes from 'types/routes'
import { getAppVersion } from 'utils/app'

export const AnalyticsContext =
  createContext<AnalyticsContextType | null>({
    analytics: null,
    analyticsIdentify: null,
    analyticsIdentifyReset: null,
    reportEvent: null,
    setHasFirstWeekPuffData: null,
    trackClickEvent: null,
    trackCloudSyncOptionEvent: null,
    trackEvent: null,
    trackLocalStorageCleared: null,
    trackSignIn: null,
  })

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  /** ROUTER */
  const router = useRouter()
  const {
    isNewClient,
    sdkContext,
    userClientId,
  } = useConxSdk()

  /** LOCAL STATE */
  const [analytics, setAnalytics] = useState<Chai.Analytics | null>(null)
  const [isFirstWeekPuffDataAvailable, setIsFirstWeekPuffDataAvailable] =
    useState<boolean | null>(null)

  /** DERIVED STATE */
  const screenName = parseRoute(router.pathname)

  /** STATE MANAGEMENT */
  const setHasFirstWeekPuffData = (isFirstWeekAvailable: boolean) =>
    setIsFirstWeekPuffDataAvailable(isFirstWeekAvailable)

  /** WATCHERS */

  // Setup the analytics pipeline
  useEffect(() => {
    if (!sdkContext || !userClientId) return

    if (!analytics) {
      const sdkAnalytics = new Chai.Analytics(sdkContext)
      sdkAnalytics.attachConsumer(segmentAnalyticsHook)
      setAnalytics(sdkAnalytics)

      bugsnagClient?.setUser(userClientId)
    }
  }, [analytics, sdkContext, userClientId])

  /**
   * Track the current screen name.
   * Track client-side page views with Segment
   */
  useEffect(() => {
    if (!analytics) {
      // wait until app load to track the first screen
      return
    }
    trackWithContext('screens', EventType.Screen, { screenName })
    segmentAnalyticsPage(router.pathname)
  }, [analytics, router.pathname])

  /**
   * Track app-launch events.
   */
  useEffect(() => {
    if (!analytics) return

    const appLaunchEvent = WebLoginRedirection.LoginEvent(
      {
        type: isNewClient
          ? WebLoginRedirection.LaunchType.FirstAttempt
          : WebLoginRedirection.LaunchType.ReturnUser,
      },
    )

    trackChaiEvent(analytics, appLaunchEvent)
  }, [analytics, isNewClient])

  /**
   * Identify visitors with Segment.
   */
  useEffect(() => {
    if (!window?.analytics || !userClientId) return

    window.analytics.identify(
      undefined,
      {
        release: getAppVersion(),
        userClientId,
      },
    )
  }, [userClientId])

  /** EVENT TRACKERS */

  const trackClickEvent = ({
    properties = {},
    screenEvent,
    viewClicked,
  }: TrackClickEventProps) => {
    const payload = {
      ...getContextPayload(),
      screenEvent,
      screenName,
      viewClicked,
      ...properties,
    }

    legacyTrack(analytics, 'click_event', EventType.Stat, payload)
  }

  /**
   * Track events relating to user interactions with cloud sync opt-in/-out.
   * @param eventName the option the user selected (e.g., 'TurnOn', 'MaybeLater')
   * @param screenEvent
   */
  const trackCloudSyncOptionEvent = (
    eventName: string,
    screenEvent: string,
  ) => {
    const isSettingsScreen = Routes.Settings.includes(screenName)

    const viewClicked = isSettingsScreen
      ? `CloudSync|${eventName}`
      : `${screenEvent}|${eventName}`

    trackClickEvent({
      screenEvent,
      viewClicked,
    })
  }

  const trackSignIn = (userGuid: string) => {
    segmentAnalyticsIdentify(userGuid)

    trackEvent(WebLoginRedirection.SucceededEvent())
  }

  /**
   * Event handler for a cache clear event
   */
  const trackLocalStorageCleared = () =>
    trackWithContext('puff_queue', EventType.Stat, { cacheCleared: true })

  const trackEvent = (event: LegacyEventProps) => trackChaiEvent(analytics, event)

  // Return a payload representing the current app state.
  const getContextPayload = (): EventContextPayload => {
    const contextPayload = { screenName }

    // todo
    if (screenName === 'usage-insights') {
      contextPayload['state'] ||= isFirstWeekPuffDataAvailable ? 'has_first_week' : 'no_first_week'
    }

    return contextPayload
  }

  const trackWithContext = (
    eventName: string,
    eventType: EventType,
    payload: Record<string, any> = {},
  ) =>
    legacyTrack(
      analytics,
      eventName,
      eventType,
      {
        ...getContextPayload(),
        // Flatten the payload object to get around Segment's field prefixing
        ...payload,
      },
    )

  const reportEvent = (event: Event) => {
    if (!analytics) return
    analytics.report(event)
  }

  // Setup the device connection context interface
  const values: AnalyticsContextType = {
    analytics,
    analyticsIdentify: segmentAnalyticsIdentify,
    analyticsIdentifyReset: segmentAnalyticsIdentifyReset,
    reportEvent,
    setHasFirstWeekPuffData,
    trackClickEvent,
    trackCloudSyncOptionEvent,
    trackEvent,
    trackLocalStorageCleared,
    trackSignIn,
  }

  // Finally, return the interface that we want to expose to our other components
  return (
    <AnalyticsContext.Provider value={values}>
      {children}
    </AnalyticsContext.Provider>
  )
}
