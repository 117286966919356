import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import styles from './Layout.module.scss'
import { Grid } from './components/Grid'
import { NicotineBanner } from './components/NicotineBanner'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { SideBar } from './components/SideBar'
import { classNames } from 'utils/css'
import { useScroll } from 'hooks/use-scroll'
import { Text } from 'components/Text'
import { Link } from 'components/Link'
import Chai from 'types/chai'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { useAuthentication } from 'modules/AuthenticationProvider'
import { useApp } from 'modules/AppProvider'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { NavIndicators } from './types'
import { JuulLoading } from './components/JuulLoading'
import { useLocalStorageValue, useThrottledValue } from 'hooks'
import { LONG_TERM_TREND_SEEN, SHORT_TERM_TREND_SEEN } from 'modules/UsageInsights'

// todo fix up complexity if possible

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  // local state
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false)
  // Throttle speed shoud be slower than bottom nav animation speed (500)
  const throttledIsScrollDown = useThrottledValue({ throttleMs: 600, value: isScrollDown })
  const [hideNicotineBanner, setHideNicotineBanner] = useState<boolean>(true)
  const [shouldHideNavigation, setShouldHideNavigation] = useState<boolean>(true)
  const [isGridFullWidth, setIsGridFullWidth] = useState<boolean>(false)
  const [navIndicators, setNavIndicators] = useState<NavIndicators>({})

  // hooks
  const router = useRouter()
  const { isValidSession, signOut, userSession } = useAuthentication()
  const { userAgent, locale, region } = useConxSdk()
  const { isInitialRouteLoading } = useApp()
  const scroll = useScroll(!userAgent?.isDesktop)
  const {
    isFirmwareUpdateAvailable,
    usageSyncState,
    isLogSyncEnabled,
    usageInsightsState,
  } = useDeviceConnection()

  // local storage
  const hasSeenLongTermTrend = useLocalStorageValue<boolean>(LONG_TERM_TREND_SEEN)
  const hasSeenShortTermTrend = useLocalStorageValue<boolean>(SHORT_TERM_TREND_SEEN)

  // variables
  const currentRoute = router.pathname
  const isDeviceSyncing = usageSyncState === Chai.UsageSyncState.SYNCING

  const headerVisibleUrls: string[] = [
    Routes.UnsupportedAgent,
    Routes.Pair,
    Routes.Pairing,
    Routes.FirmwareUpdate,
  ]
  const isOnlyHeaderVisible = headerVisibleUrls.includes(router.pathname)

  const unsecuredUrls: string[] = [
    Routes.Welcome,
    Routes.UnsupportedAgent,
  ]
  const isPermittedContent = isValidSession || unsecuredUrls.includes(router.pathname)

  const routesWithoutNavigation: string[] = [
    Routes.Pair,
    Routes.Pairing,
    Routes.FirmwareUpdate,
    Routes.UnsupportedAgent,
    Routes.Welcome,
  ]
  const isWithoutNavigation = routesWithoutNavigation.includes(router.pathname)

  const routesWithoutNicotineBanner: string[] = [
    Routes.Pairing,
    Routes.FirmwareUpdate,
    Routes.UnsupportedAgent,
    Routes.Welcome,
  ]
  const isWithoutNicotineBanner = routesWithoutNicotineBanner.includes(router.pathname)

  const routesWithFullScreenLayout: string[] = [Routes.Welcome]
  const isFullScreenLayout = routesWithFullScreenLayout.includes(router.pathname)

  // temporary logic for handling keychain error
  const allowedPagesBackupError: string[] =
    [Routes.Home, Routes.Settings, Routes.UsageInsights]
  const isNotificationBannerVisible = isValidSession
    && isLogSyncEnabled
    && !userSession?.hasPrivacyKey
    && allowedPagesBackupError.includes(router.pathname)

  // styles
  const contentClasses = classNames(
    styles.content,
    isValidSession && styles.animate,
    shouldHideNavigation && styles.navigationHidden,
  )

  // effects
  useEffect(() => {
    setShouldHideNavigation(isWithoutNavigation)
    setIsGridFullWidth(isFullScreenLayout)
    setHideNicotineBanner(isWithoutNicotineBanner)
  }, [router.pathname])

  useEffect(() => {
    setNavIndicators(() => ({
      ...navIndicators,
      Settings: isFirmwareUpdateAvailable,
    }))
  }, [isFirmwareUpdateAvailable])

  useEffect(() => {
    const indicateShortTermTrend = (!hasSeenShortTermTrend
      && usageInsightsState?.showShortTermTrend)
    const indicateLongTermTrend = (!hasSeenLongTermTrend
      && usageInsightsState?.showLongTermTrend)

    setNavIndicators(() => ({
      ...navIndicators,
      UsageInsights: indicateShortTermTrend || indicateLongTermTrend,
    }))
  }, [
    hasSeenLongTermTrend,
    hasSeenShortTermTrend,
    usageInsightsState?.showShortTermTrend,
    usageInsightsState?.showLongTermTrend,
  ])

  useEffect(() => {
    setIsScrollDown(scroll.y > 64 && scroll.y - scroll.prevY > 0)
  }, [scroll.prevY, scroll.y])

  const Content = (
    <div className={contentClasses}>
      <div className={styles.stickyBanners}>
        <Header
          isHidden={isFullScreenLayout}
          isResponsive={!isOnlyHeaderVisible}
          isSyncing={!isOnlyHeaderVisible && isDeviceSyncing}
        />
        {isNotificationBannerVisible && (
          <Text
            align='center'
            color='white|base|100'
            className={styles.notificationBanner}
            padding='base'
            message={{ content: 'Error: Automatic Backups are not loading at the moment. Please %{signOut} and sign in again.' }}
            typography='bodyBase'
            values={{
              signOut: <Link
                key='signOut'
                aria-label='sign out'
                content='Sign Out'
                onClick={() => signOut()}
                type='button'
                rel='noreferrer'
                className={styles.notificationBannerLink}
              />,
            }}
          />
        )}
      </div>
      <Grid fullWidth={isGridFullWidth}>
        {children}
      </Grid>
    </div>
  )

  return (isInitialRouteLoading || !isPermittedContent)
    ? <JuulLoading />
    : (
      <div>
        <NicotineBanner
          isHidden={hideNicotineBanner}
          locale={locale}
          region={region}
        />
        <div className={classNames(
          styles.Layout,
          !shouldHideNavigation && styles.divider,
        )}>
          {isValidSession ? (
            <>
              <SideBar
                currentRoute={currentRoute}
                isHidden={shouldHideNavigation}
                isSyncing={isDeviceSyncing}
                navIndicators={navIndicators}
              />
              {Content}
              <Footer
                currentRoute={currentRoute}
                isHidden={shouldHideNavigation || throttledIsScrollDown}
                navIndicators={navIndicators}
              />
            </>
          ) : Content}
        </div>
      </div>
    )
}
