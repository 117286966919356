import React from 'react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getAppVersion, getIsEtherEnvironment } from 'utils/app'

const ETHER_BUGSNAG_RELEASE_STAGE = 'test'
const isEtherEnvironment = getIsEtherEnvironment()
const enabledReleaseStages = (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED_RELEASE_STAGES || '').trim().split(',')
const isBugsnagConsoleLoggingEnabled = isEtherEnvironment
  || process.env.NEXT_PUBLIC_WEB_APP_ENV === 'development'
  || process.env.NEXT_PUBLIC_WEB_APP_ENV === 'preview'

export const bugsnagClient = Bugsnag.start({
  // This initializer has to run before the hooks in hooks/user-user run as they'll expect the
  // client to be present in order to associate the user-client identifier with it.
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: getAppVersion(),
  enabledReleaseStages,
  logger: null,
  onError: (event) => {
    if (window?.FS?.getCurrentSessionURL) {
      event.addMetadata(
        'fullstory',
        { urlAtTime: window.FS.getCurrentSessionURL(true) },
      )
    }

    if (isBugsnagConsoleLoggingEnabled) {
      // eslint-disable-next-line no-console
      console.error('[Bugsnag] caught error', event.originalError)
    }
  },
  plugins: [new BugsnagPluginReact()],
  releaseStage: isEtherEnvironment
    ? ETHER_BUGSNAG_RELEASE_STAGE
    : process.env.NEXT_PUBLIC_WEB_APP_ENV,
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
