import React, { FC } from 'react'
import { Link, LinkProps } from 'components/Link'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'

export const PrivacyPolicyLink: FC<LinkProps> = ({
  content = 'privacy policy',
  ...rest
}) => (
  <Link
    accessibilityLabel='open privacy policy'
    content={content}
    href={MobilecloudLink.PrivacyPolicy}
    target='_blank'
    rel='noreferrer'
    {...rest}
  />
)
