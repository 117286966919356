import { useContext } from 'react'
import { ConxSdkProviderContext } from './ConxSdkProvider'

export const useConxSdk = () => {
  const context = useContext(ConxSdkProviderContext)

  if (context === undefined) {
    throw new Error(
      '`useConxSdk` hook must be used within a `ConxSdkProvider` component',
    )
  }

  return context
}
