import { LogLevel, StoredSdkLogs, LogLevelKey } from './types'

// PROVIDER CONFIG

// sdk logs session storage key
export const SDK_LOGS_KEY = 'sdk-logs'

// initial value for stored sdk logs
export const initialSdkLogs = { levelIndex: {}, logs: [] } as StoredSdkLogs

// sdk logs level session storage key
export const SDK_LOGS_LISTENER_LEVEL_KEY = 'sdk-log-level'

// initial level for sdk log listener
export const initialSdkLogsListenerLevel = LogLevel.Warn

// initial log count
export const initialLogCount = 0

// debounce time for log count in MS
export const LOG_COUNT_TIMEOUT_MS = 500

// log level keys in order from narrow focused to most inclusive
export const orderedLogLevels = [
  LogLevelKey.Assert,
  LogLevelKey.Error,
  LogLevelKey.Warn,
  LogLevelKey.Info,
  LogLevelKey.Debug,
  LogLevelKey.Verbose,
]

// UI CONFIG

// log levels as an array to be options for the SlidingTabs component
export const logLevelTabs = [
  LogLevel.Verbose,
  LogLevel.Debug,
  LogLevel.Info,
  LogLevel.Warn,
  LogLevel.Error,
  LogLevel.Assert,
]
