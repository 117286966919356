import React, { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from './GridItem.module.scss'

export interface GridItemProps extends ComponentPropsWithoutRef<'div'> {
  verticalAlign?: 'top' | 'center'
}

export const GridItem: FC<PropsWithChildren<GridItemProps>> = ({
  children,
  className,
  verticalAlign = 'center',
  ...rest
}) => (
  <div
    className={classNames(
      styles.GridItem,
      styles[variationName('verticalAlign', verticalAlign)],
      className,
    )}
    {...rest}
  >
    {children}
  </div>
)
