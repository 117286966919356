import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './InvalidInsight.module.scss'
import { classNames, variationName } from 'utils/css'
import { Icon } from '@juullabs/react-components'
import { Text } from 'components/Text'

export interface InvalidInsightProps extends ComponentPropsWithoutRef<'div'> {
  format?: 'horizontal' | 'vertical'
}

export const InvalidInsight: FC<InvalidInsightProps> = ({
  className,
  format = 'vertical',
  ...rest
}) => (
  <div className={classNames(styles.InvalidInsight, className)} {...rest}>
    <div className={classNames(
      styles.content,
      styles[variationName('format', format)],
    )}>
      <Icon name='icon/24x24/dev-tools' />
      <div className={styles.description}>
        <Text
          message={{ content: 'Temporarily unavailable.' }}
          tagName='p'
          typography='bodyBaseBold'
        />
        <Text
          message={{ content: 'Check back later.' }}
          tagName='p'
          typography='bodyBase'
        />
      </div>
    </div>
  </div>
)
