import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './Navigation.module.scss'
import { NavButton } from '../NavButton'
import NavViews, { NavViewArray } from '../../data'
import { NavVersion, NavIndicators, NavViewName } from '../../types'
import Routes from 'types/routes'
import { WebAppNavigation } from 'analytics-events'

export interface NavigationProps extends ComponentPropsWithoutRef<'ul'> {
  /** is the current page url */
  currentRoute: string
  /** all navigation is disabled */
  disabled: boolean
  /** indicators on navigation */
  indicators: NavIndicators
  /** action on navigation */
  onNavigate?: (routeName: NavViewName) => void
  /** denotes the style to use based on layout version */
  version: NavVersion
}

export const Navigation: FC<NavigationProps> = ({
  className,
  currentRoute,
  disabled,
  indicators,
  onNavigate,
  version,
}) => (
  <ul className={className}>
    {NavViewArray.map(viewName => {
      const {
        activeIcon,
        content,
        idleIcon,
        linkClicked,
        route,
      } = NavViews[viewName]

      const isActive = currentRoute === route
        || (currentRoute.includes(route) && route !== Routes.Home)

      const hasIndicator = indicators[viewName]

      const navButtonTestId = (version === 'mobileTablet')
        ? `nav-${viewName.toLowerCase()}-footer`
        : `nav-${viewName.toLowerCase()}`

      return (
        <li key={content} className={styles.navListItem}>
          <NavButton
            accessibilityLabel={content}
            clickEvent={WebAppNavigation.NavigationEvent({ linkClicked })}
            content={content}
            data-testid={navButtonTestId}
            disabled={disabled}
            href={route}
            icon={isActive ? activeIcon : idleIcon}
            indicator={hasIndicator}
            isActive={isActive}
            onClick={() => onNavigate?.(viewName)}
            version={version}
          />
        </li>
      )
    })}
  </ul>
)
