import React, { FC, ComponentProps } from 'react'
import { Text, Message, TextFormats } from 'components/Text'
import { Modal, ModalProps } from '../Modal'
import { Icon } from '@juullabs/react-components'
import { Box } from 'components/Box'
import { Entries } from 'components/Typography'
import styles from './TextModal.module.scss'

export interface TextModalProps extends ModalProps {
  align?: 'left' | 'right' | 'center'
  description: Message[] | Message[][]
  descriptionVariables?: Entries
  heading: TextFormats
  headingVariables?: Entries
  icon?: ComponentProps<typeof Icon>
}

export const TextModal: FC<TextModalProps> = ({
  align = 'left',
  description,
  descriptionVariables,
  heading,
  headingVariables,
  icon,
  ...rest
}) => (
  <Modal {...rest}>
    {icon?.name && (
      <Box align={align}>
        <Icon className={styles.icon} {...icon} />
      </Box>
    )}
    <Text
      align={align}
      margin={{ top: 'tight' }}
      message={heading}
      tagName='h4'
      typography='bodyBigBold'
      values={headingVariables}
    />
    {description.map((paragraph, i) => (
      <Text
        key={'paragraph-' + i}
        align={align}
        color='ink|base|75'
        margin={{ top: 'baseLoose' }}
        message={paragraph}
        tagName='p'
        typography='bodyBase'
        values={descriptionVariables}
      />
    ))}
  </Modal>
)
