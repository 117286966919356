import { useEffect } from 'react'

/**
 * Use this hook to observe window's storage event
 *
 * @param callback callback triggered on a storage event
 * @param deps dependencies for the callback (e.g., state that needs to remain current)
 */
export const useStorageObserver = (
  callback: (...args: any) => void,
  deps: any[] = [],
) => {
  useEffect(() => {
    const trackStorage = () => {
      if (localStorage.length === 0) {
        // If the callback relies on any state, it will be stale unless added to the deps array
        callback()
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('storage', trackStorage)
    }

    return () => {
      window?.removeEventListener('storage', trackStorage)
    }
  }, [callback, ...deps])
}
