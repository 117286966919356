import React, { FC, useEffect, useRef, useState } from 'react'
import Chai from 'types/chai'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { Text } from 'components/Text'
import styles from './PodHistoryDonut.module.scss'
import { formatCount } from './utils'

// types
export enum PodHistoryPeriod {
  CalendarWeek='calendar week',
  NinetyDays='90 days',
  SevenDays='7 days',
  ThirtyDays='30 days',
}

type PodGroupInfo = {
  count: number
  flavor: string
  hue: string
  strength: string
}

export interface PodHistoryDonutProps extends BoxProps {
  maxDiameter: number
  setHasPods(hasPods: boolean): void
  setHasUnknownFlavor(hasUnknownFlavor: boolean): void
  timePeriod: PodHistoryPeriod
}

export const PodHistoryDonut: FC<PodHistoryDonutProps> = ({
  maxDiameter,
  setHasPods,
  setHasUnknownFlavor,
  timePeriod,
  ...rest
}) => {
  // hooks
  const { usageInsights } = useDeviceConnection()

  // refs
  const canvasRef = useRef<HTMLCanvasElement>(null)

  // state
  const [uiState, setUiState] = useState<Chai.PodHistoryInsightUiState>(null)
  const [{ count, flavor, hue, strength },
    setPodGroupInfo] = useState<PodGroupInfo>(
      { count: 0, flavor: null, hue: null, strength: null },
    )

  // variables
  const colorStyle = { color: hue }

  // Setup chart observation
  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    /**
     * NOTE: Count can either be total count OR recipe specific count.
     * Therefore, Boolean(count) is sufficient to determine if there are pods
     * because a recipe specific count cannot be less than a positive integer.
     */
    setUiState(usageInsights?.attachPodHistoryAdapter(currentCanvas, (
      count: number,
      flavor: string,
      strength: string,
      hue: string,
      hasUnknownFlavor: boolean,
    ) => {
      setPodGroupInfo({ count, flavor, hue, strength })
      setHasPods(Boolean(count))
      setHasUnknownFlavor(hasUnknownFlavor)
    }))

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  // Push ui state to chart
  useEffect(() => {
    uiState?.setMode(timePeriod)
  }, [uiState, timePeriod])

  return (
    <Box tagName='figure' {...rest}>
      <div
        className={styles.podHistoryDonut}
        style={{ maxWidth: `${maxDiameter}px` }}
      >
        <canvas
          className={styles.chart}
          ref={canvasRef}
        />
        <figcaption className={styles.label}>
          <div className={styles.content}>
            <Text
              align='center'
              className={styles.heading}
              message={{ content: 'Unique Pods Attached' }}
            />
            <div className={styles.divider} />
            {flavor && (
              <Text
                align='center'
                className={styles.podFlavor}
                style={colorStyle}
                message={{ content: flavor }}
              />
            )}
            {strength && (
              <Text
                align='center'
                className={styles.podStrength}
                style={colorStyle}
                message={{ content: strength }}
              />
            )}
            <Text
              align='center'
              className={styles.podCount}
              style={colorStyle}
              message={{ content: '%{pods} %{unit}' }}
              values={formatCount(count)}
            />
          </div>
        </figcaption>
      </div>
    </Box>
  )
}
