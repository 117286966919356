import React, { FC } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from './Text.module.scss'
import { Typography } from 'components/Typography'
import { Box } from 'components/Box'
import { TextProps } from './types'

const getColorClass = (color: string) => {
  if (!color) {
    return ''
  }
  const args = color.split('|')
  const [colorHue] = args
  const colorVariant = args[1] || 'base'
  const colorOpacity = args[2] || 100

  return [colorHue, colorVariant, colorOpacity].join('-')
}

export const Text: FC<TextProps> = ({
  align = 'left',
  className,
  color = 'ink|base|100',
  margin,
  message,
  padding,
  tagName = 'div',
  typography,
  values = {},
  ...rest
}) => (
  <Box
    tagName={tagName}
    padding={padding}
    margin={margin}
    className={classNames(
      styles.Text,
      styles[variationName('align', align)],
      typography && styles[variationName('typography', typography)],
      { [styles[getColorClass(color)]]: color },
      className,
    )} {...rest}>
    {Array.isArray(message) ? (
      message.map((partialMessage, i) => (
        <Typography
          key={'text-' + i}
          {...partialMessage}
          values={values}
        />
      ))
    ) : (
      <Typography
        {...message}
        values={values}
      />
    )}
  </Box>
)
