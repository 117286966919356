import React, { FC, useEffect, useState } from 'react'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'

import styles from './DateRangePicker.module.scss'
import { classNames, variationName } from 'utils/css'
import { Button } from 'components/Button'
import {
  getSlidesFromInterval,
  getLastDayOfThisMonth,
  getToday,
  getMonthsBeforeDate,
  getWeeksAfterDate,
} from './utils'
import { DateRangePickerProps, Slide } from './types'

export const DateRangePicker: FC<DateRangePickerProps> = ({
  className,
  onClickNavigation,
  onPickDate,
  startDate,
  type = '7 days',
}) => {
  const [slides, setSlides] = useState<Slide[]>([])
  const [swiper, setSwiper] = useState<SwiperCore>(null)

  const handleSlideChange = () => {
    const { start } = slides.find((slide) => slide.id === swiper.realIndex)
    onPickDate(start)
  }

  useEffect(() => {
    let end

    if (type === '7 days') {
      // this is required for rendering the last day of
      // the current 7 days in the date range
      end = getWeeksAfterDate(getToday())
    } else {
      end = getLastDayOfThisMonth()
    }

    const start = startDate || getMonthsBeforeDate(end)
    const interval = { end, start }
    const slides = getSlidesFromInterval(interval, type)

    setSlides(slides)
  }, [startDate, type])

  useEffect(() => {
    // set the default slide to reflect the current date
    if (slides?.length && swiper) {
      swiper.slideTo(slides[slides.length - 1].id, 0)
    }
  }, [slides, swiper])

  return (
    <div className={classNames(styles.DateRangePicker, className)}>
      <Swiper
        className={styles.slideDeck}
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
        observeParents={true}
        observer={true}
      >
        {slides.map((slide) => (
          <SwiperSlide key={`${type}-${slide.id}`} className={styles.slide}>
            {slide.label}
          </SwiperSlide>
        ))}
        {['prev', 'next'].map((item) => (
          <Button
            slot={item === 'prev'
              ? 'container-start'
              : 'container-end'}
            key={item}
            className={classNames(
              `button-${item}`,
              styles[variationName('button', item)],
              styles.navigationButton,
            )}
            size='small'
            icon={item === 'prev'
              ? 'icon/24x24/arrow_left'
              : 'icon/24x24/arrow_right'}
            accessibilityLabel={item}
            onClick={() => {
              onClickNavigation(item)
              item === 'prev'
                ? swiper.slidePrev()
                : swiper.slideNext()
            }}
            disabled={item === 'prev'
              ? swiper?.isBeginning
              : swiper?.isEnd}
          />
        ))}
      </Swiper>
    </div>
  )
}
