import React, { FC } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from '../Button.module.scss'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { ButtonContent, ButtonContentInterface } from '../ButtonContent'
import { ButtonDesignProps } from '../types'

export interface LinkButtonProps
  extends ButtonDesignProps, ButtonContentInterface, UnstyledLinkProps {
  /** Visually hidden text for screen readers */
  accessibilityLabel?: string
  /** additional className for content component */
  contentClassName?: string
}

export const LinkButton: FC<LinkButtonProps> = ({
  alignIcon = 'right',
  accessibilityLabel,
  children,
  className,
  content,
  contentClassName,
  design = 'classic',
  disabled,
  fullWidth,
  icon,
  justifyContent = 'center',
  outline,
  primary,
  size = 'base',
  ...rest
}) => {
  const iconOnly = icon && !content
  const iconColor = disabled ? 'steel|base|30'
    : primary ? 'cloud|base|100' : 'steel|base|100'

  return (
    <UnstyledLink
      className={classNames(
        styles.LinkButton,
        design && styles[variationName('design', design)],
        primary && styles[variationName('primary', primary)],
        outline && styles.outline,
        size && content && styles[variationName('textButtonSize', size)],
        fullWidth && styles.fullWidth,
        iconOnly && styles.iconOnly,
        disabled && styles.disabled,
        className,
      )}
      disabled={disabled}
      aria-label={accessibilityLabel}
      {...rest}
    >
      <ButtonContent
        alignIcon={alignIcon}
        className={contentClassName}
        content={content}
        icon={icon}
        iconColor={iconColor}
        justifyContent={justifyContent}
        size={size}
      >
        {children}
      </ButtonContent>
    </UnstyledLink>
  )
}
