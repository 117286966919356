import React, { FC } from 'react'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { NavIcons, NavVersion } from '../../types'
import { Icon } from '@juullabs/react-components'
import styles from './NavButton.module.scss'
import { classNames } from 'utils/css'

export interface NavButtonProps extends UnstyledLinkProps {
  /** Visually hidden text for screen readers */
  accessibilityLabel?: string
  /** Additional classnames */
  className?: string
  /** NavButton text */
  content?: string
  /** Icon to display to the left of the button content */
  icon?: NavIcons
  /** A red circle to attract the users attention to the page */
  indicator?: boolean
  /** allows for styling of NavButton when on selected page */
  isActive?: boolean
  /** required, denotes the style to use based on layout version */
  version?: NavVersion
}

export const NavButton: FC<NavButtonProps> = ({
  accessibilityLabel,
  className,
  content,
  disabled,
  icon,
  indicator,
  isActive,
  version = 'desktop',
  ...rest
}) => {
  const getIconColor = () => {
    if (disabled) return 'steel|base|15'
    else if (isActive) return 'ink|base|100'
    else if (version === 'desktop') return 'ink|base|75'
    else return 'ink|base|50'
  }

  return (
    <UnstyledLink
      aria-label={accessibilityLabel}
      className={classNames(
        styles.NavButton,
        disabled && styles.disabled,
        isActive && styles.activeNav,
        version === 'mobileTablet' ? styles.mobileTablet : styles.desktop,
        className,
      )}
      disabled={disabled}
      local
      {...rest}
    >
      {icon && (
        <Icon
          className={styles.icon}
          color={getIconColor()}
          name={icon}
        />
      )}
      {content && (
        <span className={styles.content}>
          {content}
        </span>
      )}
      {indicator && (
        <span className={classNames(
          styles.indicator,
          disabled && styles.disabled,
        )}/>
      )}
    </UnstyledLink>
  )
}
