import { TextFormats } from 'components/Text'

export const title = 'Average Puff Hours'

export const body: TextFormats = [
  { content: 'You tend to vape the most ' },
  { content: 'between %{max}', tagName: 'strong' },
  { content: ', and vape the least ' },
  { content: 'between %{min}', tagName: 'strong' },
  { content: '.' },
]

type FormatBody = (min: number, max: number) => { max: string, min: string }
export const formatBody: FormatBody = (min, max) => (
  {
    max: formatHour(max),
    min: formatHour(min),
  }
)

const formatHour = (value: number) => {
  const numeric = (value: number) => {
    const relative = value % 12
    return relative == 0 ? 12 : relative
  }
  const meridiem = (value: number) => value < 12 ? 'am' : 'pm'
  const startHour = numeric(value)
  const startMeridiem = meridiem(value)
  const endHour = numeric(value + 1)
  const endMeridiem = meridiem(value + 1)
  if (startMeridiem == endMeridiem) {
    return `${startHour}-${endHour}${endMeridiem}`
  } else {
    return `${startHour}${startMeridiem}-${endHour}${endMeridiem}`
  }
}
