import { useState, useCallback } from 'react'

/**
 * Handles declaring and toggling boolean state.
 *
 * @returns:
 *  -state as boolean, using default value.
 *  -toggle callback to change boolean state.
 */

export const useToggle = (initialState: boolean) => {
  const [isToggled, setIsToggled] = useState(initialState)
  const toggle = useCallback(
    () => setIsToggled(state => !state),
    [setIsToggled],
  )

  return [isToggled, toggle] as const
}
