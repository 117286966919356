import React, { FC, useState } from 'react'
import { PodPictograph } from '../../charts/PodPictograph'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import {
  title,
  estimateMessage,
  formatEstimate,
  formatHeading,
  heading,
  overline,
} from './localization'

// config
const POD_GRAPH_HEIGHT = 76

// types
export type PodConsumptionProps = Omit<InsightsCardProps, 'title'>

export const PodConsumption: FC<PodConsumptionProps> = ({ ...rest }) => {
  const [podsPerDay, setPodsPerDay] = useState(0)
  const [minPuffsPerPod, setMinPuffsPerPod] = useState<number>(null)
  const [maxPuffsPerPod, setMaxPuffsPerPod] = useState<number>(null)

  return (
    <InsightsCard title={title} {...rest}>
      <PodPictograph
        height={POD_GRAPH_HEIGHT}
        padding={{ bottom: 'baseLoose', top: 'base' }}
        setMaxPuffsPerPod={setMaxPuffsPerPod}
        setMinPuffsPerPod={setMinPuffsPerPod}
        setPodsPerDay={setPodsPerDay}
      />
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'extraTight' }}
        message={heading}
        tagName='p'
        typography='h3Mobile'
        values={formatHeading(podsPerDay)}
      />
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'baseLoose' }}
        message={overline}
        tagName='p'
        typography='overlineBase'
      />
      <Text
        message={(minPuffsPerPod != null && maxPuffsPerPod != null)
          ? estimateMessage['success']
          : estimateMessage['failure']
        }
        tagName='p'
        typography='bodyBig'
        values={formatEstimate(minPuffsPerPod, maxPuffsPerPod)}
      />
    </InsightsCard>
  )
}
