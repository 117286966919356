import React, { FC } from 'react'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { classNames } from 'utils/css'
import styles from './Link.module.scss'

export interface LinkProps extends UnstyledLinkProps {
  /** Visually hidden text for screen readers */
  accessibilityLabel?: string
  /** Additional classnames */
  className?: string
  /** Link text */
  content?: string
}

export const Link: FC<LinkProps> = ({
  accessibilityLabel,
  className,
  content,
  disabled,
  ...rest
}) => (
  <UnstyledLink
    aria-label={accessibilityLabel}
    className={classNames(
      styles.Link,
      disabled && styles.disabled,
      className,
    )}
    disabled={disabled}
    {...rest}
  >
    {content}
  </UnstyledLink>
)
