import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import Chai from 'types/chai'
import { Box, BoxProps } from 'components/Box'

export interface BatteryPictographProps extends BoxProps {
  height: number
  setBatteriesPerDay(batteriesPerDay: number): void
  setPuffEstimatePerCharge(puffEstimatePerCharge: Chai.UsageEstimate): void
}

export const BatteryPictograph: FC<BatteryPictographProps> = ({
  height,
  setBatteriesPerDay,
  setPuffEstimatePerCharge,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachBatteryConsumptionAdapter(
      currentCanvas,
      setBatteriesPerDay,
      setPuffEstimatePerCharge,
    )

    return () => {
      if (currentCanvas !== null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
