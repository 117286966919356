import React, { FC } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from '../Button.module.scss'
import { Spinner } from 'components/Spinner'
import { UnstyledButton, UnstyledButtonProps } from 'components/UnstyledPrimitives'
import { ButtonContent, ButtonContentInterface } from '../ButtonContent'
import { ButtonDesignProps } from '../types'

export interface ButtonProps
  extends ButtonDesignProps, ButtonContentInterface, UnstyledButtonProps {
    /** Visually hidden text for screen readers */
    accessibilityLabel?: string
    /** additional className for content component */
    contentClassName?: string
    /** Disables the button, disallowing interaction */
    disabled?: boolean
    /**
     * Replaces button text with a spinner while a background action is being
     * performed.
     */
    loading?: boolean
  }

export const Button: FC<ButtonProps> = ({
  alignIcon = 'right',
  accessibilityLabel,
  children,
  className,
  content,
  contentClassName,
  design = 'classic',
  disabled,
  fullWidth,
  icon,
  justifyContent = 'center',
  loading,
  outline,
  primary,
  size = 'base',
  ...rest
}) => {
  const iconOnly = icon && !content
  const iconColor = disabled ? 'steel|base|30'
    : primary ? 'cloud|base|100' : 'steel|base|100'

  return (
    <UnstyledButton
      className={classNames(
        styles.Button,
        design && styles[variationName('design', design)],
        primary && styles[variationName('primary', primary)],
        outline && styles.outline,
        size && content && styles[variationName('textButtonSize', size)],
        fullWidth && styles.fullWidth,
        iconOnly && styles.iconOnly,
        className,
      )}
      disabled={disabled || loading}
      aria-label={accessibilityLabel}
      aria-busy={loading}
      {...rest}
    >
      {loading && (
        <Spinner
          size={iconOnly ? 'small' : size}
          color='dark'
          className={styles.loadingSpinner}
        />
      )}
      <ButtonContent
        alignIcon={alignIcon}
        className={classNames(loading && styles.hideContent, contentClassName)}
        content={content}
        icon={icon}
        iconColor={iconColor}
        justifyContent={justifyContent}
        size={size}
      >
        {children}
      </ButtonContent>
    </UnstyledButton>
  )
}
