export type StoredConsoleLogs = {
  levelIndex: Partial<Record<ConsoleAction, number[]>>
  logs: { log: string, timestamp: string }[]
}

export enum ConsoleAction {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Log = 'LOG',
  Warn = 'WARN',
}
