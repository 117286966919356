import getConfig from 'next/config'

// regex constant
export const BLUEFY_REGEX = /Bluefy\/([\d.]+)/i

// environment getters
export const getIsDebugMode = () => (
  process.env.NEXT_PUBLIC_DEBUG_MODE === 'true'
)

export const getIsEUTEnvironment = () => (
  process.env.NEXT_PUBLIC_IS_EUT_BUILD === 'true'
)

export const getIsPreProdEnvironment = () => (
  process.env.NEXT_PUBLIC_WEB_APP_ENV !== 'production'
)

export const getIsVercelProdEnvironment = () => (
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'conx-web'
)

export const getIsEtherEnvironment = () => (
  Boolean(process.env.NEXT_PUBLIC_IS_ETHER)
)

export const getIsFeedbackEnabled = () => (
  process.env.NEXT_PUBLIC_FEEDBACK_ENABLED === 'true'
)

// for use outside of root provider (ConxSdkProvider)
export const getIsBluefyEnvironment = () => {
  if (typeof window !== 'undefined') {
    return Boolean(window.navigator.userAgent.match(BLUEFY_REGEX))
  }
}

// set local env variables
const isEUTEnvironment = getIsEUTEnvironment()
const isPreProdEnvironment = getIsPreProdEnvironment()
const isVercelProdEnvironment = getIsVercelProdEnvironment()
const isEtherEnvironment = getIsEtherEnvironment()

type DevelopmentStage = 'dev' | 'preprod' | undefined
const getDevelopmentStage = ():DevelopmentStage => {
  let buildType: DevelopmentStage = 'dev'

  if (isVercelProdEnvironment) {
    if (isPreProdEnvironment) {
      buildType = 'preprod'
    } else {
      // undefined represents prod
      buildType = undefined
    }
  }

  return buildType
}

export const getAppSemverVersion = () => (
  getConfig()?.publicRuntimeConfig?.appVersion || 'unknown'
)

export const getAppVersion = () => {
  const semver = getAppSemverVersion()
  const buildVersion = process.env.NEXT_PUBLIC_BUILD_VERSION
  const developmentStage = getDevelopmentStage()
  let appVersion = semver

  if (developmentStage) {
    appVersion = `${appVersion}+${developmentStage}`
  }
  if (buildVersion) {
    appVersion = `${appVersion}+${buildVersion}`
  }

  return appVersion
}

// function to generate dataDog build type for chaiContext
enum BuildType {
  Dev = 'debug',
  PreProd = 'preproduction',
  Prod = 'release',
  Study = 'eut',
  Test = 'ether',
}
export const getDataDogBuildType = (): BuildType => {
  let buildType = BuildType.Dev

  if (isVercelProdEnvironment) {
    if (isPreProdEnvironment) {
      buildType = BuildType.PreProd
    } else if (isEUTEnvironment) {
      // production study environments/urls (not study mode via SDK context)
      buildType = BuildType.Study
    } else {
      buildType = BuildType.Prod
    }
  }

  if (isEtherEnvironment) {
    buildType = BuildType.Test
  }

  return buildType
}
