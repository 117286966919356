import React, { FC } from 'react'
import styles from './RemoteConfigEditor.module.scss'
import { Text } from 'components/Text'
import Chai from 'types/chai'
import { RemoteConfigItem } from './RemoteConfigItem'
import { useListenable } from 'hooks'

export type RemoteConfigEditorProps = {
  manager: Chai.remoteconfig.RemoteConfigManager
}

export const RemoteConfigEditor: FC<RemoteConfigEditorProps> = ({ manager }) => {
  const items = useListenable([], manager)

  return (
    <div className={styles.RemoteConfigEditor}>
      <Text
        align='left'
        color='green|base|100'
        message={{ content: 'Remote Config Editor' }}
        tagName='h3'
        typography='subtitleSmall'
      />
      {manager ? <FilterEditor manager={manager} /> : null}
      <div className={styles.itemsList}>
        {items.map(item => <RemoteConfigItem manager={manager} key={item.name} item={item} />)}
      </div>
    </div>
  )
}


type FilterEditorProps = { manager: Chai.remoteconfig.RemoteConfigManager }
const FilterEditor: FC<FilterEditorProps> = ({ manager }) => (
  <label className={styles.Filter}>
    Filter
    <input defaultValue={manager.getFilter()}
      onChange={e => manager.setFilter(e.target.value)} />
  </label>
)
