import { CloseableNoticeProps } from '../../components'

export const keepInsightsNoticeData: Omit<CloseableNoticeProps, 'open' | 'closeButton'> = {
  button: {
    accessibilityLabel: 'This will turn on automatic backups',
    content: 'Turn on automatic backups',
  },
  description: { message: { content: 'Sync your data so that you don’t lose it when you open the JUUL App from a different computer or delete your browsing history. Backups are securely stored and fully encrypted so JUUL Labs can never access your usage data. See our %{privacyPolicyLink}.' } },
  heading: { content: 'Keep Your Insights' },
}
