enum Locales {
  EN = 'en',
  EN_CA = 'en-CA',
  EN_GB = 'en-GB',
  EN_KW = 'en-KW',
  EN_US = 'en-US',
  FR = 'fr',
  FR_CA = 'fr-CA',
}

export default Locales
