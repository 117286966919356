import React, { FC } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import styles from './InsightsUnavailable.module.scss'
import { remainingDays, description } from './localization'
import { formatDays } from '../../utils'

export interface InsightsUnavailableProps {
  daysTillAvailable: number
}

export const InsightsUnavailable: FC<InsightsUnavailableProps> = (
  { daysTillAvailable },
) => (
  <Box id='insights-unavailable' role='status' tagName='aside'>
    <Player
      className={styles.player}
      autoplay
      loop
      src='/static/anim/insights/insights-unavailable.json'
    />
    <Text
      align='center'
      margin={{ top: 'tight' }}
      message={description}
      tagName='p'
      typography='bodyBig'
    />
    <Text
      align='center'
      margin={{ top: 'tight' }}
      message={remainingDays}
      tagName='p'
      typography='h4'
      values={formatDays(daysTillAvailable)}
    />
  </Box>
)
