enum Routes {
  Diagnostics = '/diagnostics',
  FirmwareUpdate = '/firmware-update',
  Home = '/',
  LongTermTrends = '/usage-insights/long-term-trends',
  Pair = '/pair',
  Pairing = '/pairing',
  Settings = '/settings',
  ShortTermTrends = '/usage-insights/short-term-trends',
  UnsupportedAgent = '/unsupported-agent',
  UsageInsights = '/usage-insights',
  Welcome = '/welcome',
}

export default Routes
