import { TextFormats } from 'components/Text'

export const title: TextFormats = { content: '2 Week View' }

export const primaryHeading: TextFormats = { content: 'This week you have vaped %{trend} last week.' }

export const secondaryHeading: TextFormats = { content: 'Day-by-day comparison' }

export const legend: TextFormats = [
  { content: '“...vaped about the same amount as last week” is displayed for a change between -%{threshold} and +%{threshold}.' },
  { tagName: 'br' },
  { content: '“...vaped less than last week” is displayed for a change of -%{threshold} or lower.' },
  { tagName: 'br' },
  { content: '“...vaped more than last week” is displayed for a change of +%{threshold} or higher.' },
]
