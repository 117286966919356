import React from 'react'
import { Reoverlay } from 'reoverlay'
import { ConsoleLogsDisplay } from '../components/ConsoleLogsDisplay'

interface ConsoleLogs {
  openConsoleLogsModal: () => void
}

// hook providing the function to open the ConsoleLogsModal
export const useConsoleLogs = (): ConsoleLogs => {
  const openConsoleLogsModal = () => Reoverlay.showModal('Modal', {
    children: <ConsoleLogsDisplay />,
    contentFlexDirection: 'row',
    width: 'large',
  })

  return { openConsoleLogsModal }
}
