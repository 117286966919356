import React, { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import styles from './InsightsSection.module.scss'
import { classNames } from 'utils/css'
import { Box } from 'components/Box'
import { UnstyledButtonProps } from 'components/UnstyledPrimitives'
import { InsightsHeader } from '../InsightsHeader'

export interface InsightsSectionProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'section'>> {
    /** are the section contents hidden/collapsed */
    isHidden?: boolean
    /** title of the section */
    title: string
    /** show/hide callback to set isHidden */
    toggleButton?: UnstyledButtonProps
  }

export const InsightsSection: FC<InsightsSectionProps> = ({
  children,
  className,
  isHidden,
  title,
  toggleButton,
  ...rest
}) => (
  <section
    className={classNames(
      styles.InsightsSection,
      className,
    )}
    {...rest}
  >
    <InsightsHeader
      isHidden={isHidden}
      level='h2'
      title={title}
      toggleButton={toggleButton}
    />
    <div className={styles.animateContent}>
      {!isHidden && (
        <Box className={styles.body} padding={{ top: 'base' }}>
          {children}
        </Box>
      )}
    </div>
  </section>
)
