import React, { FC, HTMLAttributes } from 'react'
import styles from './SideBar.module.scss'
import { Navigation } from '../Navigation'
import { classNames } from 'utils/css'
import { Icon } from '@juullabs/react-components'
import { NavIndicators, NavViewName } from '../../types'
import { SyncingIndicator } from '../SyncingIndicator'

export interface SideBarProps extends HTMLAttributes<HTMLDivElement> {
  /** is the current page url */
  currentRoute: string
  /** sideBar is hidden */
  isHidden: boolean
  /** is cloud sync in progress */
  isSyncing: boolean
  /** indicators on navigation */
  navIndicators: NavIndicators
  /** action on navigation */
  onNavigate?: (routeName: NavViewName) => void
}

export const SideBar: FC<SideBarProps> = ({
  currentRoute,
  isHidden,
  isSyncing,
  navIndicators,
  onNavigate,
  ...rest
}) => (
  <div
    aria-hidden={isHidden}
    className={classNames(styles.SideBar, isHidden && styles.hidden)}
    data-testid='sidebar'
    {...rest}
  >
    <div className={styles.innerWrap}>
      <div className={styles.header}>
        <Icon
          className={styles.logo}
          name='header_logo/juul-54x24-black_base_100'
        />
        {isSyncing && <SyncingIndicator className={styles.syncingIndicator} />}
      </div>
      <nav id='desktop-nav' aria-label='Main'>
        <Navigation
          className={styles.navigation}
          currentRoute={currentRoute}
          disabled={isHidden}
          indicators={navIndicators}
          onNavigate={onNavigate}
          version='desktop'
        />
      </nav>
    </div>
  </div>
)
