/* eslint-disable no-console */
import { useMemo } from 'react'
import { stringify } from 'flatted'
import { useSetSessionStorage, useSessionStorageValue } from 'hooks'
import {
  CONSOLE_LOGS_KEY,
  initialConsoleLogs,
  orderedLogLevels,
  CONSOLE_LOGS_DEBUG,
  initialDebugState,
} from '../config'
import { StoredConsoleLogs, ConsoleAction } from '../types'
import { logLevelMap, stringifyReplacer } from '../utils'
import { getIsPreProdEnvironment, getIsBluefyEnvironment } from 'utils/app'

export const useCustomConsole = () => {
  // if we are not in a pre production environment on bluefy do not proceed
  if (!getIsPreProdEnvironment() || !getIsBluefyEnvironment()) return

  // console actions
  const { log, warn, error, debug, info } = console

  // session storage
  const setLogsInStorage = useSetSessionStorage<StoredConsoleLogs>(
    CONSOLE_LOGS_KEY,
  )
  const isDebugMode = useSessionStorageValue<boolean>(
    CONSOLE_LOGS_DEBUG,
    initialDebugState,
  )

  // function to log and then store logs in session storage
  const logAndStore = (
    method: (...args: any[]) => void,
    actionKey: ConsoleAction,
    args: string[],
  ) => {
    // get most resent logs from session storage
    const consoleLogs = useSessionStorageValue<StoredConsoleLogs>(
      CONSOLE_LOGS_KEY,
      initialConsoleLogs,
    )

    // transformation for sdk logs
    if (typeof args[0] === 'string' && args[0]?.includes('[%s]')) {
      args.shift()
      args[0] = `[${args[0]}]`
    }

    // add log type for clarity
    args.unshift(`[${actionKey}]`)

    // build string version of log for session storage
    const logString = args
      .map(arg => typeof arg !== 'string'
        ? stringify(arg, stringifyReplacer)
        : arg)
      .join(' ')

    // add new log to most recent console logs
    consoleLogs.logs.push({
      log: logString,
      timestamp: new Date().toISOString(),
    })

    // get index of the newest log
    const newIndex = consoleLogs.logs.length - 1

    // store the index in its relevant level categories
    orderedLogLevels.forEach((level) => {
      if (logLevelMap[level].includes(actionKey)) {
        consoleLogs.levelIndex[level] = (consoleLogs.levelIndex[level] || [])
        consoleLogs.levelIndex[level].push(newIndex)
      }
    })

    // log the logs, both with the console and into session storage
    method.apply(console, args)
    setLogsInStorage(consoleLogs)
  }

  // build custom logger
  const customLogger = {
    debug: (...args: string[]) => logAndStore(debug, ConsoleAction.Debug, args),
    error: (...args: string[]) => logAndStore(error, ConsoleAction.Error, args),
    info: (...args: string[]) => logAndStore(info, ConsoleAction.Info, args),
    log: (...args: string[]) => logAndStore(log, ConsoleAction.Log, args),
    warn: (...args: string[]) => logAndStore(warn, ConsoleAction.Warn, args),
  }

  // replace the the built in console functions with our custom ones
  useMemo(() => {
    setLogsInStorage(initialConsoleLogs)

    if (isDebugMode) {
      console.debug = customLogger.debug
    }

    console.error = customLogger.error
    console.info = customLogger.info
    console.log = customLogger.log
    console.warn = customLogger.warn
  }, [])
}
