import { GrantInfoKeychainPayload } from 'types/user'
import { SignOutParams, SignOutResponse } from 'next-auth/react'
import Chai from 'types/chai'

export type AuthenticationContextType = {
  authCredentials: AuthCredentials | null
  authErrorCode: string | null
  isSessionLoading: boolean | null
  isValidSession: boolean | null
  oauthSessionId: string | null
  onOauthSessionFailureRetry: (() => void) | null
  signOut: ((params?: SignOutParams) => Promise<SignOutResponse>) | null
  userSession: Chai.UserSession | null
}

export type AuthCredentials = {
  guid: string
  keychain: GrantInfoKeychainPayload
  userAccessToken: string
}

export const OAUTH_SESSION_FAILED_KEY = 'oauth_session_failed'

