import React, { FC, HTMLAttributes } from 'react'
import styles from './SyncingIndicator.module.scss'
import { classNames } from 'utils/css'
import { Spinner } from 'components/Spinner'

export interface SyncingIndicatorProps extends HTMLAttributes<HTMLDivElement> {
  /** additional classes */
  className?: string
  /** additional label classes */
  labelClassName?: string
}

export const SyncingIndicator: FC<SyncingIndicatorProps> = ({
  className,
  labelClassName,
  ...rest
}) => (
  <div
    className={classNames(
      styles.SyncingIndicator,
      className,
    )}
    {...rest}
  >
    <Spinner
      accessibilityLabel='Syncing JUUL2 Device'
      color='dark'
      size='small'
    />
    <span className={classNames(
      styles.label,
      labelClassName,
    )}>
      Syncing...
    </span>
  </div>
)
