import React, { FC, ComponentProps } from 'react'
import styles from './CloseableNotice.module.scss'
import { Button, ButtonProps } from 'components/Button'
import { Text, TextFormats, ComplexMessage } from 'components/Text'
import { Box, BoxProps, Alignment } from 'components/Box'
import { classNames } from 'utils/css'
import { Icon } from '@juullabs/react-components'

export interface CloseableNoticeProps extends BoxProps {
  button?: ButtonProps
  buttonAlign?: Alignment
  className?: string
  closeButton: Pick<ButtonProps, 'clickEvent' | 'onClick'>
  description: ComplexMessage
  heading?: TextFormats
  icon?: ComponentProps<typeof Icon>
  open: boolean
}

export const CloseableNotice: FC<CloseableNoticeProps> = ({
  button,
  buttonAlign = 'left',
  className,
  closeButton,
  description,
  heading,
  icon,
  open,
  ...rest
}) => open && (
  <Box
    className={classNames(
      styles.CloseableNotice,
      className,
    )}
    role='status'
    tagName='aside'
    {...rest}
  >
    <Button
      accessibilityLabel='close'
      className={styles.closeButton}
      icon='icon/16x16/close'
      size='small'
      {...closeButton}
    />
    {icon && (
      <Box margin={{ right: 'tight' }}>
        <Icon className={styles.icon} {...icon} />
      </Box>
    )}
    <div className={styles.body}>
      {heading && (
        <Text
          margin={{ bottom: 'tight' }}
          message={heading}
          tagName='h4'
          typography='bodyBaseBold'
        />
      )}
      <Text
        tagName='p'
        typography='bodySmall'
        {...description}
      />
      {button && (
        <Box
          align={buttonAlign}
          margin={{ top: 'base' }}
        >
          <Button
            primary='black'
            size='small'
            {...button}
          />
        </Box>
      )}
    </div>
  </Box>
)
