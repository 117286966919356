import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import NextLink from 'next/link'
import { classNames } from 'utils/css'
import styles from './UnstyledLink.module.scss'
import { useTrackClick } from 'modules/AnalyticsProvider/hooks'
import { Event } from 'analytics-events'

export interface UnstyledLinkProps extends ComponentPropsWithoutRef<'a'> {
  clickEvent?: Event
  /** Disables the Link, disallowing interaction */
  disabled?: boolean
  /** The path or URL to navigate to. It can also be an object if using NextLink */
  href?: string
  /** is the link internal to the app */
  local?: boolean
}

export const UnstyledLink = forwardRef<
  HTMLAnchorElement,
  UnstyledLinkProps
>(({
  className,
  clickEvent,
  children,
  disabled,
  href,
  local,
  onClick,
  ...rest
}, ref) => {
  const { onClickWithTracking }
    = useTrackClick<HTMLAnchorElement>(onClick, clickEvent)

  const classes = classNames(styles.UnstyledLink, className)
  const linkProps = {
    ...rest,
    className: classes,
    href,
    onClick: onClickWithTracking,
    ref,
  }

  if (disabled) {
    return <span className={classes} ref={ref} {...rest}>{children}</span>
  } else if (local) {
    return <NextLink {...linkProps}>{children}</NextLink>
  } else {
    return <a {...linkProps}>{children}</a>
  }
})

UnstyledLink.displayName = 'UnstyledLink'
