import React, { FC, ComponentPropsWithoutRef } from 'react'

export interface InverseJuulLogoProps extends ComponentPropsWithoutRef<'svg'> {
  fill?: string
}

export const InverseJuulLogo: FC<InverseJuulLogoProps> = ({ fill = 'white' }) => (
  <svg viewBox='0 0 280 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.1072 0H0V113.893H0.0140353C12.7821 113.893 23.1341 103.53 23.1341 90.7541V0.0001H38.2291V90.7541C38.219 111.875 21.109 129 0 129H280C258.895 129 241.779 111.875 241.775 90.7541V0.0001H256.872V90.7541C256.872 103.53 267.222 113.893 279.989 113.893H280L280 0H224.899V90.754C224.887 111.875 207.778 128.998 186.668 128.998C165.558 128.998 148.447 111.875 148.441 90.754V0H131.561V90.754C131.549 111.875 114.441 128.998 93.3302 128.998C72.2202 128.998 55.1092 111.875 55.1072 90.754V0ZM70.2021 0V90.754C70.2021 103.531 80.5501 113.896 93.3182 113.896H93.3302H93.3473C106.114 113.896 116.466 103.531 116.466 90.754V0H70.2021ZM163.534 0V90.754C163.534 103.531 173.884 113.896 186.654 113.896H186.668H186.68C199.452 113.896 209.804 103.531 209.804 90.754V0H163.534Z'
      fill={fill}
    />
  </svg>
)
