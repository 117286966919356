import React, { FC, useState } from 'react'
import { BatteryPictograph } from '../../charts/BatteryPictograph'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { Text } from 'components/Text'
import Chai from 'types/chai'
import { EstimateStatus } from '../../config'
import {
  title,
  estimateMessage,
  formatHeading,
  heading,
  overline,
} from './localization'

// config
const BATTERY_GRAPH_HEIGHT = 76

// types
export type BatteryConsumptionProps = Omit<InsightsCardProps, 'title'>

export const BatteryConsumption: FC<BatteryConsumptionProps> = ({ ...rest }) => {
  const [batteriesPerDay, setBatteriesPerDay] = useState<number>(null)
  const [
    puffEstimatePerCharge,
    setPuffEstimatePerCharge,
  ] = useState<Chai.UsageEstimate>(null)

  return (
    <InsightsCard title={title} {...rest}>
      <BatteryPictograph
        height={BATTERY_GRAPH_HEIGHT}
        padding={{ bottom: 'baseLoose', top: 'base' }}
        setBatteriesPerDay={setBatteriesPerDay}
        setPuffEstimatePerCharge={setPuffEstimatePerCharge}
      />
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'extraTight' }}
        message={heading}
        typography='h3Mobile'
        values={formatHeading(batteriesPerDay)}
        tagName='p'
      />
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'baseLoose' }}
        message={overline}
        typography='overlineBase'
        tagName='p'
      />
      <Text
        message={(puffEstimatePerCharge?.status === EstimateStatus.Calculated)
          ? estimateMessage['success']
          : estimateMessage['failure']
        }
        typography='bodyBig'
        values={{ estimate: puffEstimatePerCharge?.estimate }}
        tagName='p'
      />
    </InsightsCard>
  )
}
