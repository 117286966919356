import React, { FC, HTMLAttributes } from 'react'
import styles from './Footer.module.scss'
import { Navigation } from '../Navigation'
import { classNames } from 'utils/css'
import { NavIndicators, NavViewName } from '../../types'

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  /** is the current page url */
  currentRoute: string
  /** sideBar is hidden */
  isHidden: boolean
  /** indicators on navigation */
  navIndicators: NavIndicators
  /** action on navigation */
  onNavigate?: (routeName: NavViewName) => void
}

export const Footer: FC<FooterProps> = ({
  currentRoute,
  onNavigate,
  isHidden,
  navIndicators,
  ...rest
}) => (
  <nav
    className={classNames(styles.Footer, isHidden && styles.hidden)}
    id='mobile-nav'
    aria-label='Main'
    aria-hidden={isHidden}
    {...rest}
  >
    <Navigation
      className={styles.content}
      currentRoute={currentRoute}
      disabled={isHidden}
      indicators={navIndicators}
      onNavigate={onNavigate}
      version='mobileTablet'
    />
  </nav>
)
