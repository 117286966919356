import { Reoverlay } from 'reoverlay'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
interface SetupPlaywright {
  openPlaywrightModal: () => void
}

export const modalData = {
  description: [{ content: 'Handy tool for setting up the environment before playwright tests.' }],
  heading: { content: 'Playwright Developer Tools' },
  primaryButton: { content: 'Disable Cloud Sync' },
  secondaryButton: { content: 'Unpair all devices' },
}
const disableCloudSync = (toggleCloudSyncOptIn: (optIn: boolean) => void) => {
  toggleCloudSyncOptIn(false)
}

const unpairAllDevices = (gadgetsList) => {
  if (gadgetsList && gadgetsList.length > 0) {
    gadgetsList.forEach(gadget => gadget.unpair())
  }
}

const getModalData = (disableCloudSync: () => void, unpairAllDevices: () => void) => ({
  ...modalData,
  primaryButton: {
    ...modalData.primaryButton,
    onClick: disableCloudSync,
    primary: 'black',
  },
  secondaryButton: {
    ...modalData.secondaryButton,
    onClick: unpairAllDevices,
    primary: 'black',
  },
})

export const useSetupPlaywright = (): SetupPlaywright => {
  const { gadgetsList, toggleCloudSyncOptIn } = useDeviceConnection()

  const handleDisableCloudSync = () => disableCloudSync(toggleCloudSyncOptIn)
  const handleUnpairAllDevices = () => unpairAllDevices(gadgetsList)

  const openPlaywrightModal = () => Reoverlay.showModal(
    'TextModal',
    getModalData(handleDisableCloudSync, handleUnpairAllDevices),
  )

  return { openPlaywrightModal }
}
