/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** WebApp Login Events */
export namespace WebLoginRedirection {

  /** Indicates if the view is launched for the first time, or if it is a return user. The first_attempt will get triggered as long as the client ID is valid, if a client ID changes, first_attempt could repeat for a user. */
  export enum LaunchType {
    FirstAttempt = "first_attempt",
    ReturnUser = "return_user",
  }

  /** Event fires when a user installs the app along with first login or return user information. */
  type LoginEventProps = {
    type: LaunchType
  }

  export const LoginEvent = ({
    type,
  }: LoginEventProps): Event => {
    const properties: Record<string, any> = {
      type: type,
    }

    return new Event({
      properties: properties,
      eventName: 'login_attempt',
      type: EventType.Stat,
    })
  }

  /** Indicates when a user has been redirected back to the app from login flow with a success. */
  export const SucceededEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'login_success',
      type: EventType.Stat,
    })
  }
}
