import { useState } from 'react'
import { bugsnagClient } from 'utils/bugsnag'

interface StorageObject {
  // Function to retrieve an item from storage
  get: <T>(key: string, initialValue: T) => T
  // Function to remove an item from storage
  remove: (key: string) => void
  // Function to set an item in storage
  set: <T>(key: string, value: T) => void
}

// Enum representing different storage types
enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}

/**
 * Function to get the appropriate storage object based on the storage type.
 * This function returns an object containing methods to interact with
 * localStorage or sessionStorage based on the specified storageType.
 *
 * @param storageType The type of storage to interact with (localStorage or sessionStorage).
 * @returns An object with methods for interacting with the specified storage type.
 */
const getStorage = (storageType: StorageType): StorageObject => {
  // Check if window is defined to avoid errors during build, return a dummy StorageObject
  if (typeof window === 'undefined') return {
    get: <T>(_key: string, initialValue: T): T => initialValue,
    remove: () => undefined,
    set: () => undefined,
  }

  // Selecting storage based on the storageType provided
  const storage = storageType === StorageType.LocalStorage
    ? window.localStorage
    : window.sessionStorage

  return {
    get: <T>(key: string, initialValue: T): T => {
      try {
        const item = storage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        bugsnagClient?.notify?.({
          message: `${error}`,
          name: `get_${storageType}_error`,
        })
        return initialValue
      }
    },
    remove: (key: string): void => {
      try {
        storage.removeItem(key)
      } catch (error) {
        bugsnagClient?.notify?.({
          message: `${error}`,
          name: `remove_${storageType}_error`,
        })
      }
    },
    set: <T>(key: string, value: T): void => {
      try {
        storage.setItem(key, JSON.stringify(value))
      } catch (error) {
        bugsnagClient?.notify?.({
          message: `${error}`,
          name: `set_${storageType}_error`,
        })
      }
    },
  }
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/**
 * Function to create a custom storage hook.
 * This function generates a storage hook that interacts with localStorage or sessionStorage.
 * It returns a tuple containing the stored value, a function to update the stored value,
 * and a function to remove the stored value.
 *
 * @param storageType The type of storage to interact with (localStorage or sessionStorage).
 * @returns A custom storage hook for interacting with the specified storage type.
 */
const createUseStorage = <T>(storageType: StorageType) => (
  key: string,
  initialValue: T = undefined,
) => {
  const storage = getStorage(storageType)

  // Retrieve the stored value from storage or use the provided initialValue
  const [storedValue, setStoredValue] = useState<T>(
    () => storage.get(key, initialValue),
  )

  // Function to update the stored value
  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    storage.set(key, valueToStore)
  }

  // Function to remove the stored value
  const removeKey = () => {
    storage.remove(key)
    setStoredValue(initialValue)
  }

  return [storedValue, setValue, removeKey] as const
}

/**
 * Set or retrieve an item from `window.localStorage`.
 *
 * @param key
 * @param initialValue optional default to undefined
 * @returns local stored item and functions, [value, setValue, removeValue]
 */
export const useLocalStorage = <T>(key: string, initialValue?: T) => (
  createUseStorage<T>(StorageType.LocalStorage)(key, initialValue)
)

/**
 * Set or retrieve an item from `window.sessionStorage`.
 *
 * @param key
 * @param initialValue optional default to undefined
 * @returns session stored item and functions, [value, setValue, removeValue]
 */
export const useSessionStorage = <T>(key: string, initialValue?: T) => (
  createUseStorage<T>(StorageType.SessionStorage)(key, initialValue)
)

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/**
 * Function to create a custom set storage hook.
 * This function generates a hook to set a value in localStorage or sessionStorage
 * based on the provided storage type.
 *
 * @param storageType The type of storage to interact with (localStorage or sessionStorage).
 * @returns A function that sets a value in the specified storage type.
 */
const createUseSetStorage = <T>(storageType: StorageType) => (
  key: string,
) => {
  const storage = getStorage(storageType)
  return (value: T) => storage.set(key, value)
}

/**
 * Hook to set a value in localStorage.
 *
 * @param key The key under which the value is stored in localStorage.
 * @returns A function to set a value in localStorage.
 */
export const useSetLocalStorage = <T>(key: string) => (
  createUseSetStorage<T>(StorageType.LocalStorage)(key)
)

/**
 * Hook to set a value in sessionStorage.
 *
 * @param key The key under which the value is stored in sessionStorage.
 * @returns A function to set a value in sessionStorage.
 */
export const useSetSessionStorage = <T>(key: string) => (
  createUseSetStorage<T>(StorageType.SessionStorage)(key)
)

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/**
 * Function to create a custom storage value hook.
 * This function generates a hook to retrieve a value from localStorage or sessionStorage
 * based on the provided storage type.
 *
 * @param storageType The type of storage to interact with (localStorage or sessionStorage).
 * @returns A function that retrieves a value from the specified storage type using provided key.
 */
const createUseStorageValue = <T>(storageType: StorageType) => (
  key: string,
  initialValue: T = undefined,
) => {
  const storage = getStorage(storageType)
  return storage.get(key, initialValue)
}

/**
 * Hook to retrieve a value from localStorage.
 *
 * @param key The key of the value stored in localStorage.
 * @returns The value retrieved from localStorage.
 */
export const useLocalStorageValue = <T>(key: string, initialValue?: T) => (
  createUseStorageValue<T>(StorageType.LocalStorage)(key, initialValue)
)

/**
 * Hook to retrieve a value from sessionStorage.
 *
 * @param key The key of the value stored in sessionStorage.
 * @returns The value retrieved from sessionStorage.
 */
export const useSessionStorageValue = <T>(key: string, initialValue?: T) => (
  createUseStorageValue<T>(StorageType.SessionStorage)(key, initialValue)
)

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/**
 * Function to create a custom remove storage hook.
 * This function generates a hook to remove a value from localStorage or sessionStorage
 * based on the provided storage type.
 *
 * @param storageType The type of storage to interact with (localStorage or sessionStorage).
 * @returns A function that removes a value from the specified storage type using the provided key.
 */
const createUseRemoveStorage = (storageType: StorageType) => (
  key: string,
) => {
  const storage = getStorage(storageType)
  return () => storage.remove(key)
}

/**
 * Hook to remove a value from localStorage.
 *
 * @param key The key of the value to be removed from localStorage.
 * @returns A function to remove the value from localStorage.
 */
export const useRemoveKeyLocalStorage = createUseRemoveStorage(StorageType.LocalStorage)

/**
 * Hook to remove a value from sessionStorage.
 *
 * @param key The key of the value to be removed from sessionStorage.
 * @returns A function to remove the value from sessionStorage.
 */
export const useRemoveKeySessionStorage = createUseRemoveStorage(StorageType.SessionStorage)
