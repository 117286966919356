import React, { FC, HTMLAttributes } from 'react'
import styles from './Header.module.scss'
import { Icon } from '@juullabs/react-components'
import { classNames } from 'utils/css'
import { SyncingIndicator } from '../SyncingIndicator'

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  /** is header hidden */
  isHidden: boolean
  /** is header responsive to navigation location */
  isResponsive: boolean
  /** is cloud sync in progress */
  isSyncing: boolean
}

export const Header: FC<HeaderProps> = ({
  isHidden,
  isResponsive,
  isSyncing,
  ...rest
}) => (
  <div
    className={classNames(
      styles.Header,
      isHidden ? styles.hidden : styles.visible,
      isResponsive && styles.responsive,
    )}
    {...rest}
  >
    <div className={styles.wideWrap}>
      <div className={styles.content}>
        {isSyncing && (
          <SyncingIndicator
            className={styles.syncingIndicator}
            labelClassName={styles.syncingIndicatorLabel}
          />
        )}
        <Icon
          className={styles.logo}
          name='header_logo/juul-54x24-black_base_100'
        />
      </div>
    </div>
  </div>
)

