import React, { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { classNames } from 'utils/css'
import { camelToDash } from 'utils/text'
import styles from './FlexColumn.module.scss'

type Spacing =
  | 'none'
  | 'tightest'
  | 'extremeTight'
  | 'extraTight'
  | 'tighter'
  | 'tight'
  | 'baseTight'
  | 'base'
  | 'baseSemiLoose'
  | 'baseLoose'
  | 'loose'
  | 'extraLoose'
  | 'extraExtraLoose'
  | 'extremeLoose'

type ResponsiveSpacing = {
  default: Spacing
  desktop?: Spacing
  desktopBig?: Spacing
  tablet?: Spacing
  tallMobile?: Spacing
  wide?: Spacing
}

export interface FlexColumnProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'div'>> {
    /** Adjust spacing between elements */
    spacing?: ResponsiveSpacing
  }

export const FlexColumn: FC<PropsWithChildren<FlexColumnProps>> = ({
  className,
  children,
  spacing = { default: 'base' },
  ...rest
}) => {
  const spacingStyles = spacing && Object.keys(spacing).length !== 0
    ? Object.entries(spacing).map(([key, value]) =>
      styles[`${camelToDash(key)}-spacing-${camelToDash(value)}`])
    : []

  return (
    <div
      className={classNames(
        styles.FlexColumn,
        ...spacingStyles,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
