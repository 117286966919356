import React, {
  FC,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react'
import styles from './InsightsCard.module.scss'
import { classNames } from 'utils/css'
import { UnstyledButtonProps } from 'components/UnstyledPrimitives'
import { Spinner } from 'components/Spinner'
import { InsightsHeader } from '../InsightsHeader'
import { InvalidInsight } from '../InvalidInsight'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'

export interface InsightsCardProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'article'>> {
    /** optional insight card event */
    cardEvent?: Event
    /** optional controller for charts or other content */
    controls?: ReactNode
    /** are the card contents hidden/collapsed */
    isHidden?: boolean
    /** controls UI for a chart invalid state  */
    isValid?: boolean
    /** are the card contents loading */
    loading?: boolean
    /** title of the card */
    title: string
    /** show/hide button props */
    toggleButton?: UnstyledButtonProps
  }

export const InsightsCard: FC<InsightsCardProps> = ({
  cardEvent,
  children,
  className,
  controls,
  isHidden,
  isValid = true,
  loading,
  title,
  toggleButton,
  ...rest
}) => {
  const { reportEvent } = useAnalytics()

  useEffect(() => {
    if (!cardEvent) return

    reportEvent(cardEvent)
  }, [cardEvent])

  return (
    <article
      className={classNames(
        styles.InsightsCard,
        className,
      )}
      {...rest}
    >
      <InsightsHeader
        className={classNames(styles.header, isHidden && styles.bodyHidden)}
        isHidden={isHidden}
        level='h3'
        title={title}
        toggleButton={toggleButton}
      />
      {!isHidden && (
        <div className={styles.body}>
          <div
            aria-hidden={loading}
            className={classNames(loading && styles.bodyLoading)}
          >
            {controls && (
              <div className={styles.controls}>
                {controls}
              </div>
            )}
            <div className={styles.contentWrap}>
              <div
                aria-hidden={!isValid}
                className={styles.content}
              >
                {children}
              </div>
              {!isValid && <InvalidInsight />}
            </div>
          </div>
          {loading && <Spinner className={styles.spinner} color='dark' />}
        </div>
      )}
    </article>
  )
}
