import React, { FC } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from './Controls.module.scss'
import { Button, ButtonProps } from 'components/Button'
import { ControlsProps } from './types'
import { Box } from 'components/Box'

export const Controls: FC<ControlsProps> = ({
  align = 'center',
  className,
  gap = 'baseTight',
  margin,
  padding,
  primaryButton,
  secondaryButton,
  size = 'base',
  stacked = false,
  tertiaryButton,
  ...rest
}) => {
  const controls: ButtonProps[] = [
    { ...tertiaryButton },
    { ...secondaryButton },
    { primary: 'black', ...primaryButton },
  ]

  if (stacked) controls.reverse()

  return (
    <Box
      className={classNames(
        styles.Controls,
        stacked ? styles.stacked : styles.inline,
        align && styles[variationName('align', align)],
        gap && styles[variationName('gap', gap)],
        className,
      )}
      margin={margin}
      padding={padding}
      tagName='div'
      type={null}
      {...rest}
    >
      {controls.map((button, i) => button?.content && (
        <Button
          key={'control-' + i}
          {...button}
          fullWidth
          size={size}
        />
      ))}
    </Box>
  )
}
