import React, { FC, useState, useEffect } from 'react'

import styles from './SlidingTabs.module.scss'

export const MockTabs = ['7 days', 'month'] as Array<'7 days' | 'month'>

export type SlidingTabsProps = {
  defaultValue?: string
  id: string
  onClickValue(value: string): void
  values: string[]
}

export const SlidingTabs: FC<SlidingTabsProps> = ({
  onClickValue,
  id,
  defaultValue,
  values,
}) => {
  const formId = `sliding-tabs-${id}`
  const tabWidth = 100.00 / values.length
  const gliderOffset = 4.00 / values.length
  const defaultValueIndex = defaultValue ? values.indexOf(defaultValue) : 0
  const initialGliderTranslation = `${defaultValueIndex * 100}%`
  const [gliderTranslation, setGliderTranslation]
    = useState<string>(initialGliderTranslation)

  const handleSelection = (e) => {
    const { target: { value } } = e

    onClickValue(value)
    setGliderTranslation(`${values.indexOf(value) * 100}%`)
  }

  useEffect(() => {
    setGliderTranslation(initialGliderTranslation)
  }, [values.length])

  return (
    <form className={styles.SlidingTabs} id={formId}>
      <fieldset className={styles.tabs}>
        {values.map((value, index) => (
          <div
            key={`${value}-${index}`}
            className={styles.tab}
            style={{ width: `${tabWidth}%` }}
          >
            <input
              className={styles.tabInput}
              defaultChecked={index === defaultValueIndex}
              form={formId}
              id={`${formId}-radio-${value}-${index}`}
              name='tabs'
              onClick={handleSelection}
              type='radio'
              value={value}
            />
            <label
              className={styles.tabLabel}
              htmlFor={`${formId}-radio-${value}-${index}`}
            >
              {value}
            </label>
          </div>
        ))}

        <span
          className={styles.glider}
          style={{
            transform: `translateX(${gliderTranslation})`,
            width: `calc(${tabWidth}% - ${gliderOffset}px)`,
          }}
        />
      </fieldset>
    </form>
  )
}
