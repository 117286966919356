import { EventType, Key } from "./generated/DataTypes";
import { HardwareId } from "./HardwareId";

interface EventAttributes {
  eventName: string,
  hardwareId?: HardwareId,
  properties: Record<string, any>,
  screenName?: string,
  type: EventType,
}

export class Event {
  eventName: string;
  hardwareId?: HardwareId;
  properties: Record<string, any>;
  screenName?: string;
  type: EventType;

  constructor({
    eventName,
    hardwareId,
    properties,
    screenName,
    type,
  }: EventAttributes) {
    this.eventName = eventName;
    this.hardwareId = hardwareId;
    this.properties = properties;
    this.screenName = screenName;
    this.type = type;
  }
}
