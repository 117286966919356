import {
  DeviceConnectionAction,
  DeviceConnectionState,
  UsageSyncState,
  UsageInsightsState,
} from './types'
import { useReducer } from 'react'
import Chai from 'types/chai'
import { bugsnagClient } from 'utils/bugsnag'
import { pick } from 'lodash/fp'
import { getIsDebugMode } from 'utils/app'

export const INITIAL_STATE: DeviceConnectionState = {
  context: null,
  currentHardwareId: null,
  deviceEventHandler: null,
  gadgetsHandler: null,
  gadgetsList: null,
  gadgetsObject: null,
  isFirmwareUpdateAvailable: false,
  isGadgetsReady: false,
  isLogSyncEnabled: false,
  isLogSyncStatusKnown: false,
  isLogSyncToggleUpdating: false,
  isUsageInsightsReady: false,
  lastUsageSyncDate: null,
  setupError: null,
  usageInsights: null,
  usageInsightsManager: null,
  usageInsightsState: null,
  usageSyncState: null,
}

/**
 * Device connection reducer
 *
 * @param prevState - previous state
 * @param action - device connection action
 */
export const deviceConnectionReducer = (
  prevState: DeviceConnectionState,
  action: DeviceConnectionAction,
): DeviceConnectionState => {
  switch (action.type) {
  case 'CLEAR_STATE':
    return {
      ...prevState,
      ...action.state,
    }
  case 'RESET': {
    return INITIAL_STATE
  }
  case 'SETUP':
    return {
      ...prevState,
      context: action.context,
      deviceEventHandler: action.deviceEventHandler,
      gadgetsHandler: action.gadgetsHandler,
      usageInsights: action.usageInsights,
      usageInsightsManager: action.usageInsightsManager,
    }
  case 'SET_CURRENT_HARDWARE_ID':
    return {
      ...prevState,
      currentHardwareId: action.currentHardwareId,
    }
  case 'SET_GADGETS':
    return {
      ...prevState,
      gadgetsList: action.gadgetsList,
      gadgetsObject: action.gadgetsObject,
      isFirmwareUpdateAvailable: action.isFirmwareUpdateAvailable,
      isGadgetsReady: action.isGadgetsReady,
    }
  case 'SET_IS_USAGE_INSIGHTS_READY':
    return {
      ...prevState,
      isUsageInsightsReady: action.isUsageInsightsReady,
    }
  case 'SET_USAGE_INSIGHTS_STATE':
    return {
      ...prevState,
      usageInsightsState: action.usageInsightsState,
    }
  case 'SET_USAGE_SYNC_STATE':
    return {
      ...prevState,
      usageSyncState: action.usageSyncState,
    }
  case 'SET_LAST_USAGE_SYNC_DATE':
    return {
      ...prevState,
      lastUsageSyncDate: action.lastUsageSyncDate,
    }
  case 'TOGGLE_CLOUD_SYNC':
    return {
      ...prevState,
      isLogSyncEnabled: action.isLogSyncEnabled,
      isLogSyncStatusKnown: action.isLogSyncStatusKnown,
      isLogSyncToggleUpdating: action.isLogSyncToggleUpdating,
    }
  default:
    return prevState
  }
}

/**
 * Builds a reducer for the device connection
 *
 * @param initialState - initial state
 * @returns the device connection actions and state
 */
export const useDeviceConnectionReducer = (initialState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(deviceConnectionReducer, initialState)

  /**
   * Initializes various Chai api's
   *
   * @param reducer - device connection reducer
   * @param options - device connection options
   */
  const setup = async(options: {
    context: Chai.Context
  }): Promise<{
    deviceEventHandler: Chai.DeviceEventHandler
    gadgetsHandler: Chai.gadgets.Gadgets
  }> => {
    try {
      const { context } = options

      const usageInsights = new Chai.UsageInsights(context)
      const usageInsightsManager = new Chai.UsageInsightsManager(context)
      const deviceEventHandler = new Chai.DeviceEventHandler(context)
      const gadgetsHandler = new Chai.gadgets.Gadgets(context)
      getIsDebugMode() && (window.fakes = Chai.gadgets.fakes)

      dispatch({
        context,
        deviceEventHandler,
        gadgetsHandler,
        type: 'SETUP',
        usageInsights,
        usageInsightsManager,
      })

      return {
        deviceEventHandler,
        gadgetsHandler,
      }
    } catch (e) {
      dispatch({
        setupError: e,
        type: 'SETUP_ERROR',
      })

      bugsnagClient?.notify?.({
        message: `${e}`,
        name: 'failed_setup',
      })
    }
  }

  /**
   * Test Gadget setter
   *
   * @param gadgets - all of a users devices
   * @returns - nothing at the moment
   */
  const setGadgets = (gadgets: Chai.gadgets.Gadget[]) => {
    dispatch({
      gadgetsList: gadgets,
      gadgetsObject: gadgets ? Object.fromEntries(gadgets.map(
        gadget => [gadget.hardwareId, gadget],
      )) : null,
      isFirmwareUpdateAvailable: gadgets.some(({ firmwareUpdatable }) => (
        firmwareUpdatable?.status === Chai.dfu.DfuStatus.READY
      )),
      isGadgetsReady: gadgets != null,
      type: 'SET_GADGETS',
    })
  }

  /**
   * Store the account level usage sync status
   *
   * @param reducer - device connection reducer
   * @param e - Chai event
   */
  const setUsageSyncState = (e: Chai.ValueEvent | Chai.ErrorEvent) => {
    if (e instanceof Chai.ValueEvent) {
      dispatch({
        type: 'SET_USAGE_SYNC_STATE',
        usageSyncState: e.value as UsageSyncState,
      })
    }

    if (e instanceof Chai.ErrorEvent) {
      bugsnagClient?.notify?.({
        message: `${e.error}`,
        name: 'failed_usage_sync_state_listener',
      })
    }
  }

  /**
   * Store the account level last usage sync date
   *
   * @param reducer - device connection reducer
   * @param e - Chai event
   */
  const setLastUsageSyncDate = (e: Chai.ValueEvent | Chai.ErrorEvent) => {
    if (e instanceof Chai.ValueEvent) {
      dispatch({
        lastUsageSyncDate: e.value as Date,
        type: 'SET_LAST_USAGE_SYNC_DATE',
      })
    }

    if (e instanceof Chai.ErrorEvent) {
      bugsnagClient?.notify?.({
        message: `${e.error}`,
        name: 'failed_last_usage_sync_date_listener',
      })
    }
  }

  /**
   * Clears the device connection state
   *
   * @param clearFor - whether to clear all or partial state
   * @param customState - device connection state
   */
  const clearState = (
    clearFor: 'user' | 'all' | 'custom',
    customState?: DeviceConnectionState,
  ) => {
    type DeviceConnectionStateKeys = Array<keyof DeviceConnectionState>

    switch (clearFor) {
    case 'user':
      dispatch({
        state: {
          ...pick([
            'usageInsights',
            'usageInsightsManager',
            'context',
          ] as DeviceConnectionStateKeys)(INITIAL_STATE),
        },
        type: 'CLEAR_STATE',
      })
      break
    case 'all':
      dispatch({
        state: { ...INITIAL_STATE },
        type: 'CLEAR_STATE',
      })
      break
    case 'custom':
      dispatch({
        state: { ...customState },
        type: 'CLEAR_STATE',
      })
      break
    default:
      dispatch({
        state: { ...INITIAL_STATE },
        type: 'CLEAR_STATE',
      })
    }
  }

  /**
   * Toggles the cloud sync status
   *
   * @param optIn - whether to opt in or opt out
   */
  const toggleCloudSyncOptIn = async(optIn: boolean) => {
    const { context } = state

    try {
      await context.userPreferences.set(Chai.UserPreferenceName.LOG_CLOUD_SYNC, optIn)
    } catch (e) {
      bugsnagClient?.notify?.({
        message: `${e}`,
        name: 'failed_toggle_cloud_sync_opt_in',
      })
      throw e
    }
  }

  /**
   * Sets the Log Sync Opt In status
   *
   * @param event - conx-sdk value event
   */
  const setCloudSyncOptIn = (event: Chai.ValueEvent) => {
    if (event instanceof Chai.ValueEvent) {
      const preference = event.value as Chai.UserPreference

      dispatch({
        isLogSyncEnabled: preference.value !== null ? preference.value : false,
        isLogSyncStatusKnown: preference.value !== null,
        isLogSyncToggleUpdating: preference?.isUpdating ?? false,
        type: 'TOGGLE_CLOUD_SYNC',
      })
    }

    if (event instanceof Chai.ErrorEvent) {
      bugsnagClient?.notify?.({
        message: `${event.error}`,
        name: 'failed_toggle_cloud_sync_opt_in',
      })
    }
  }

  /**
   * Set users usage insights state
   *
   * @param usageInsightsState - see UsageInsightsState type for details
   * @returns void
   */
  const setUsageInsightsState = (usageInsightsState: UsageInsightsState) => {
    dispatch({ type: 'SET_USAGE_INSIGHTS_STATE', usageInsightsState })
  }

  /**
   * Set is usage insights ready
   *
   * @param isUsageInsightsReady - boolean
   * @returns void
   */
  const setIsUsageInsightsReady = (isUsageInsightsReady: boolean) => {
    dispatch({ isUsageInsightsReady, type: 'SET_IS_USAGE_INSIGHTS_READY' })
  }

  /**
   * Set current hardwareId, to key into the correct gadget in gadgetsObject
   * for use by firmwareUpdate, etc.
   *
   * @param hardwareId - string
   * @returns void
   */
  const setCurrentHardwareId = (hardwareId: string) => {
    dispatch({
      currentHardwareId: hardwareId,
      type: 'SET_CURRENT_HARDWARE_ID',
    })
  }

  return {
    clearState,
    setCloudSyncOptIn,
    setCurrentHardwareId,
    setGadgets,
    setIsUsageInsightsReady,
    setLastUsageSyncDate,
    setUsageInsightsState,
    setUsageSyncState,
    setup,
    state,
    toggleCloudSyncOptIn,
  }
}
