import { ComponentProps, FocusEvent } from 'react'
import { NotificationType } from 'components/Notification'
import { Icon } from '@juullabs/react-components'

export interface GenerateRandomPuffsType {
  endTimestamp: string
  hardwareId: string
  podId: string
  startTimestamp: string
}

export interface GeneratePuffsType extends GenerateRandomPuffsType {
  puffCount: number
}

export interface GeneratePatternOfPuffsType extends GenerateRandomPuffsType {
  pattern: string
}

export enum TabValues {
  AddPuffs = 'Add Puffs',
  CSV = 'CSV',
  PatternOfPuffs = 'Pattern of Puffs',
  RandomPuffs = 'Random Puffs',
  RemovePuffs = 'Remove Puffs',
}

export enum CSVMode {
  Export = 'Export',
  Import = 'Import',
}

export enum PodOptions {
  AutumnTobacco = 'Autumn Tobacco 18 mg/mL',
  BlackcurrantTobacco = 'Blackcurrant Tobacco 18 mg/mL',
  CrispMenthol = 'Crisp Menthol 18 mg/mL',
  PolarMenthol = 'Polar Menthol 18 mg/mL',
  RubyMenthol = 'Ruby Menthol 18 mg/mL',
  SummerMenthol = 'Summer Menthol 18 mg/mL',
  VirginiaTobacco = 'Virginia Tobacco 18 mg/mL',
}

export type MockPodsType = {
  [pod in PodOptions]: {
    podId: string
    recipeId: BigInt
  }
}

export type NotificationsType = {
  icon: {[tab in TabValues | 'default']?: ComponentProps<typeof Icon>}
  message: {[tab in TabValues | 'default']?: string}
  title: {[tab in TabValues | 'default']?: string}
  type: {[tab in TabValues | 'default']?: NotificationType}
}

type Error = {
  invalid?: string
  max?: string
  min?: string
  required?: string
}

export type MockPuffsInput = {
  accessibilityLabel: string
  datalist?: string[] | number[]
  error: Error
  label: string
  placeholder?: string
}

export type SubmitButton = {
  [tab in TabValues]: string
}

export type Fieldset<T extends string>= {
  accessibilityLabel: string
  error: Error
  labels?: {[value in T]: string}
  options: T[]
}

export type InstructionsType = {
  csvMode: {[tab in TabValues | 'default']?: string}
  pattern: {[tab in TabValues | 'default']?: string}
  pods: {[tab in TabValues | 'default']?: string}
  puffCount: {[tab in TabValues | 'default']?: string}
  timestamps: {[tab in TabValues | 'default']?: string}
}

export interface MockPuffsFormValues {
  csvMode: CSVMode
  endTimestamp: string
  pattern: string
  pod: PodOptions
  puffCount: number
  startTimestamp: string
  tab: TabValues
}

export interface HandleFormSubmitType extends MockPuffsFormValues {
  hardwareId: string
  mockPods: MockPodsType
}

export interface MockPuffsFormTypes {
  csvModes: Fieldset<CSVMode>
  endTimestamp: MockPuffsInput
  instructions: InstructionsType
  pattern: MockPuffsInput
  pods: Fieldset<PodOptions>
  puffCount: MockPuffsInput
  startTimestamp: MockPuffsInput
  submit: SubmitButton
}

export interface MockPuffsCommonProps extends MockPuffsFormTypes {
  onSubmit(values: MockPuffsFormValues): void
}

export interface MockPuffsCardProps extends MockPuffsCommonProps {
  notifications: NotificationsType
  tabs: TabValues[]
  title: string
}

export interface MockPuffsFormProps extends MockPuffsCommonProps {
  tab: TabValues
}

export interface FieldsetProps<T extends string> extends ComponentProps<'fieldset'> {
  accessibilityLabel: string
  error: boolean
  errorText: string
  labels?: {[value in T]: string}
  name: string
  onBlur(e: FocusEvent<any, Element>): void
  onChange(e: FocusEvent<any, Element>): void
  options: T[]
  selected: T
}

export type Puff = {
  hardwareId: string
  podId: string
  timestamp: Date
}

export type Pod = {
  isAuthentic: boolean
  podId: string
  recipeId: BigInt
  timestamp: Date
}
