import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './NicotineWarningUS.module.scss'
import { classNames } from 'utils/css'

export const NicotineWarningUS: FC<ComponentPropsWithoutRef<'div'>> =
  ({ className }) => (
    <div
      className={classNames(styles.NicotineWarningUS, className)}
      data-testid='nicotineWarning-enUS'
    >
      <div className={styles.content}>
        WARNING:<wbr/>
        &nbsp;This product contains nicotine.&nbsp;
        <br className={styles.lineBreak} />
        Nicotine is an addictive chemical.
      </div>
    </div>
  )
