import React, { FC, useState, useEffect, useRef } from 'react'
import { classNames } from 'utils/css'
import styles from './InputField.module.scss'
import { InputFieldProps } from './types'
import { Input } from './Input'

const INTERVAL_MS = 500
const INTERVAL_LIMIT = 5
const DEFAULT_INPUT_BACKGROUND_COLORS = {
  DARK: 'rgb(0, 0, 0)',
  DISABLED: 'rgba(0, 0, 0, 0)', // transparent
  LIGHT: 'rgb(255, 255, 255)',
}

export const InputField: FC<InputFieldProps> = (props) => {
  const {
    accessibilityLabel,
    className,
    disabled,
    error,
    errorText,
    fieldClassName,
    helperText,
    id,
    inputClassName,
    label,
    labelClassName,
    name,
    ...rest
  } = props
  const inputRef = useRef<(HTMLInputElement | HTMLTextAreaElement)>()
  const autofillInterval = useRef(0)

  const hasValue = Boolean(props.value)
  const [isFloating, setIsFloating] =
    useState<boolean>(hasValue || Boolean(props.defaultValue))

  const htmlId = id || `input-field-${name}`
  const accessibilityText = !label
    ? accessibilityLabel || `input ${name}`
    : null

  const hasError = error || Boolean(errorText)
  const shouldFloat = isFloating || hasValue
  const fieldClasses = classNames(
    styles.InputField,
    props.readOnly && styles.readOnly,
    disabled && styles.disabled,
    shouldFloat && styles.floating,
    hasError && styles.error,
    fieldClassName,
  )

  const labelClasses = classNames(
    styles.labelCommon,
    disabled && styles.disabled,
    shouldFloat && styles.floating,
    props.textarea && styles.labelTextarea,
    labelClassName,
  )

  const inputClasses = classNames(
    styles.inputCommon,
    shouldFloat && styles.floating,
    inputClassName,
  )

  // This useEffect manages chrome autofill, if this feature stops working:
  // check browser to see if autofill still changes the background color
  useEffect(() => {
    const input = inputRef.current
    const defaultBackgoundColors = Object.values(DEFAULT_INPUT_BACKGROUND_COLORS)

    const cleanup = () => {
      clearInterval(autofillCheck)
      autofillInterval.current = 0
    }

    const autofillCheck = setInterval(() => {
      autofillInterval.current++
      const currentBackgroundColor = getComputedStyle(input).backgroundColor

      if (defaultBackgoundColors.every(color => color !== currentBackgroundColor)) {
        setIsFloating(true)
        cleanup()
      } else if (autofillInterval.current === INTERVAL_LIMIT) cleanup()
    }, INTERVAL_MS)

    return cleanup
  }, [])

  return (
    <div className={className}>
      <div className={fieldClasses}>
        <label htmlFor={htmlId} className={labelClasses}>
          {label}
        </label>
        <Input
          {...rest}
          ref={inputRef}
          id={htmlId}
          name={name}
          aria-label={accessibilityText}
          className={inputClasses}
          disabled={disabled}
          onBlur={e => {
            setIsFloating(Boolean(e?.target?.value))
            rest?.onBlur?.(e)
          }}
          onFocus={e => {
            setIsFloating(true)
            rest?.onFocus?.(e)
          }}
        />
      </div>
      {helperText && (
        <div className={styles.helperText}>
          {helperText}
        </div>
      )}
      {errorText && (
        <div className={styles.errorText}>
          {errorText}
        </div>
      )}
    </div>
  )
}
