import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './NicotineWarningCA.module.scss'
import { classNames } from 'utils/css'
import {
  NicotineWarning_enCA_DesktopSVG,
  NicotineWarning_enCA_MobileSVG,
  NicotineWarning_enCA_TabletSVG,
  NicotineWarning_enCA_WideSVG,
  NicotineWarning_frCA_DesktopSVG,
  NicotineWarning_frCA_MobileSVG,
} from './images'

export interface NicotineWarningCAProps extends ComponentPropsWithoutRef<'div'> {
  language?: string
}

export const NicotineWarningCA: FC<NicotineWarningCAProps> = ({
  className,
  language = 'en',
}) => (
  <div
    className={classNames(styles.NicotineWarningCA, className)}
    data-testid={`nicotineWarning-${language}CA`}
  >
    {language === 'fr' ? (
      <>
        <NicotineWarning_frCA_DesktopSVG className={styles.desktopFrench} />
        <NicotineWarning_frCA_MobileSVG className={styles.mobileFrench} />
      </>
    ) : (
      <>
        <NicotineWarning_enCA_DesktopSVG className={styles.desktopEnglish} />
        <NicotineWarning_enCA_MobileSVG className={styles.mobileEnglish} />
        <NicotineWarning_enCA_TabletSVG className={styles.tabletEnglish} />
        <NicotineWarning_enCA_WideSVG className={styles.wideEnglish} />
      </>
    )}
  </div>
)
