import { useState, RefObject } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect'

export type Size = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

export const useSize = (target: RefObject<HTMLElement>): Size => {
  const [size, setSize] = useState<Size>()

  useIsomorphicLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}
