import {
  format,
  eachWeekOfInterval,
  eachMonthOfInterval,
  add,
  sub,
  lastDayOfMonth,
  getDay,
  startOfToday,
  isSameMonth,
} from 'date-fns'
import type { Interval } from 'date-fns'
import { Slide } from './types'

const DEFAULT_WEEKS = 1
const DEFAULT_MONTHS = 6

/**
 * Format interval to `dd MMM - dd MMM`
 *
 * @param interval - time interval
 *
 * @returns - formatted date range string
 */
export const formatWeek = ({ start, end }) => {
  const endDate = sub(end, { days: 1 })
  const sameMonth = isSameMonth(start, endDate)
  return [start, endDate]
    .map((date, i) => (
      i === 0 || !sameMonth ? format(date, 'MMM d') : format(date, 'd')))
    .join('–')
}

/**
 * Get today
 *
 * @returns - Get today
 */
export const getToday = (): Date => startOfToday()

/**
 * Get the last day of current month
 *
 * @returns - last day of the current month
 */
export const getLastDayOfThisMonth = (): Date => lastDayOfMonth(Date.now())

/**
 * Get the day six months before a date
 *
 * @param date - the end date
 * @param months - desired number of months before the end date
 *
 * @returns - six months before a date
 */
export const getMonthsBeforeDate = (
  date: Date,
  months = DEFAULT_MONTHS,
): Date => sub(date, { months })

/**
 * Get the day after 7 days/week
 *
 * @param date - the start date
 * @param weeks - desired number of weeks after the start date
 *
 * @returns - day after 7 days/week
 */
export const getWeeksAfterDate = (date: Date, weeks = DEFAULT_WEEKS): Date =>
  add(date, { weeks })

/**
 * Serialize time interval into Date range slides
 *
 * @param interval - time interval
 * @param type - type of interval split
 *
 * @returns - a list of slides
 */
export const getSlidesFromInterval = (
  interval: Interval,
  type: '7 days' | 'month',
): Slide[] =>
  type === '7 days' ? splitSlidesByWeek(interval) : splitSlidesByMonth(interval)


/**
 * Get index of next day
 *
 * @param day - current day
 *
 * @returns - Get index of next day
 */
const getNextDay: any = (day) => (getDay(day) + 1) % 7

/**
 * Serialize time interval into Date range slides split by weeks
 *
 * @param interval - time interval
 *
 * @returns - a list of slides
 */
export const splitSlidesByWeek = (interval: Interval): Slide[] => {
  const weeks = eachWeekOfInterval(interval, { weekStartsOn: getNextDay(interval.end) })
  const slides = []

  weeks.reduce((start, end, index) => {
    const slide = {
      id: index - 1,
      label: formatWeek({ end, start }),
      start,
    }

    slides.push(slide)

    return end
  })

  return slides
}

/**
 * Serialize time interval into Date range slides split by months
 *
 * @param interval - time interval
 *
 * @returns - an array of slides
 */
export const splitSlidesByMonth = (interval: Interval): Slide[] =>
  eachMonthOfInterval(interval).map((month, index) => ({
    id: index,
    label: `${format(month, 'MMM')} '${format(month, 'yy')}`,
    start: month,
  }))
