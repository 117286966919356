/**
 * Returns a string were spaces and hyphens are replaced with
 * non-breaking spaces and non-breaking hyphens.
 *
 * @param str a string
 * @returns
 */
export const replaceBreakingChars = (str: string) => (
  str.replace(/ /g, '\xa0').replace(/-/g, '\u2011')
)

/**
 * converts an camel case string to a dash separated string
 *
 * @param str a string
 * @returns
 */
export const camelToDash = (str) => {
  if (str != str.toLowerCase()) {
    str = str.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
  }
  return str
}
