import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { format } from 'date-fns'
import { useSessionStorageValue } from 'hooks'
import { useSdkLogs } from '../../hooks/use-sdk-logs'
import styles from './SdkLogsDisplay.module.scss'
import { Toggle } from '@juullabs/react-components'
import { Box } from 'components/Box'
import { SlidingTabs } from 'components/SlidingTabs'
import { Text } from 'components/Text'
import { CopyButton } from 'components/CopyButton'
import { getLogLevelKey, getLogLevelTabs } from '../../utils'
import { LogLevel, StoredSdkLogs } from '../../types'
import { SDK_LOGS_KEY, initialSdkLogs } from '../../config'

// todo use react-window for list construction

export type SdkLogsDisplayProps = {
  userClientId: string
}

export interface SdkLogsDisplayRef {
  setLogsForEther: (base64Logs: string) => void
}

export const SdkLogsDisplay = forwardRef<SdkLogsDisplayRef, SdkLogsDisplayProps>((
  { userClientId },
  ref,
) => {
  // context
  const { logCount, logListenerLevel, setLogListenerLevel } = useSdkLogs()

  // refs
  const logListRef = useRef<HTMLOListElement>(null)
  const etherExportRef = useRef<HTMLDivElement>(null)

  // tabs to filter from via the slider
  const logLevelTabs = getLogLevelTabs(logListenerLevel)

  // used to set level to filter logs by
  const [level, setLevel] = useState<LogLevel>(logLevelTabs[0])

  // state for current stored logs
  const [
    { levelIndex, logs },
    setSdkLogs,
  ] = useState<StoredSdkLogs>(initialSdkLogs)

  // state/setter for the level the sdk listener uses
  const isVerbose = logListenerLevel === LogLevel.Verbose
  const toggleIsVerbose = () => isVerbose
    ? setLogListenerLevel(LogLevel.Warn)
    : setLogListenerLevel(LogLevel.Verbose)

  // auto scroll to the bottom of the log list
  useEffect(() => {
    if (logListRef.current) {
      logListRef.current.scrollTop = logListRef.current.scrollHeight
    }
  }, [level, logListRef.current])

  // reset level filter when logListenerLevel changes
  useEffect(() => {
    setLevel(logLevelTabs[0])
  }, [logListenerLevel])

  // when the logCount changes grab logs from session storage and set to local state
  useEffect(() => {
    const sdkLogs = useSessionStorageValue<StoredSdkLogs>(
      SDK_LOGS_KEY,
      initialSdkLogs,
    )
    setSdkLogs(sdkLogs)
  }, [logCount])

  useImperativeHandle(ref, () => ({
    setLogsForEther: (base64Logs) => {
      if (etherExportRef.current) {
        etherExportRef.current.textContent = base64Logs
      }
    },
  }), [etherExportRef])

  return (
    <div className={styles.SdkLogsDisplay}>
      <Text
        align='left'
        color='green|base|100'
        message={{ content: 'SDK Logs' }}
        tagName='h3'
        typography='subtitleSmall'
      />
      <Box padding={{ bottom: 'base', top: 'tight' }}>
        <SlidingTabs
          id='sdkLogLevels'
          onClickValue={(value: LogLevel) => setLevel(value)}
          values={logLevelTabs}
        />
      </Box>
      <ol className={styles.logList} ref={logListRef}>
        {levelIndex?.[getLogLevelKey(level)]?.map((logIndex) => (
          <li className={styles.logListItem} key={'log-' + logIndex}>
            <Text
              color='ink|base|50'
              message={{
                content: format(
                  logs[logIndex].timestamp,
                  'P – HH:mm:ss.SSS',
                ),
              }}
              padding={{ bottom: 'extraTight' }}
              typography='captionSmall'
            />
            <Text
              className={styles.logText}
              color='ink|base|75'
              message={{ content: logs[logIndex].log }}
              tagName='p'
              typography='bodySmall'
            />
          </li>
        ))}
        <div className={styles.anchor} />
      </ol>
      <Box margin={{ top: 'baseLoose' }} className={styles.actionWrap}>
        <CopyButton
          copyLabel='UserId'
          key='copyUserClientId'
          textToCopy={userClientId}
        />
        <Text
          color='ink|base|75'
          message={{ content: `${userClientId}` }}
          typography='bodySmallBold'
          padding={{ left: 'tight' }}
        />
      </Box>
      <Box margin={{ top: 'baseLoose' }} className={styles.actionWrap}>
        <Toggle
          ariaLabel='toggle verbose logs'
          onClick={toggleIsVerbose}
          checked={isVerbose}
          showIcons
        />
        <Text
          color='ink|base|75'
          message={{ content: 'Enable Verbose, Debug, and Info Logs (Logs will be cleared)' }}
          padding={{ left: 'tight' }}
          typography='bodySmallBold'
        />
      </Box>
      <div className={styles.visuallyHidden} ref={etherExportRef} />
    </div>
  )
})

SdkLogsDisplay.displayName = 'SdkLogsDisplay'
