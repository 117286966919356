import React, { FC, HTMLAttributes } from 'react'
import styles from './LoadingPlaceholder.module.scss'
import { classNames } from 'utils/css'

export interface LoadingPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  theme?: 'light' | 'dark'
}

export const LoadingPlaceholder: FC<LoadingPlaceholderProps> = ({
  className,
  theme = 'light',
  ...rest
}) => (
  <div className={classNames(
    styles.LoadingPlaceholder,
    styles[theme],
    className,
  )} {...rest}>
    <div className={classNames(styles.animatedBackground, styles[theme])} />
  </div>
)
